import { useMutation, useQuery } from '@apollo/client';
import { Delete, Edit } from '@mui/icons-material';
import { Box, Divider, IconButton, Typography } from '@mui/joy';
import { useCallback, useMemo, useRef, useState } from 'react';
import {
  SortBy,
  SortByDirection,
  WoVendorStatusNode,
} from '../../__generated__/graphql';
import MainPageTemplate from '../../components/MainPageTemplate';
import MyTable from '../../components/my-table/MyTable';
import { AllFilter } from '../../components/my-table/TableFilters';
import AddWoVendorStatusModal from './AddWoVendorStatusModal';
import DeleteWoVendorStatusModal from './DeleteWoVendorStatusModal';
import EditWoVendorStatusModal, {
  updateWoVendorStatusMutation,
} from './EditWoVendorStatusModal';
import ChangeSomeStatusOrderModal from '../some-status/ChangeSomeStatusOrderModal';
import useWoVendorStatuses, {
  sortWoVendorStatuses,
} from './use-work-order-statuses';

const initialOrder: SortBy = {
  key: 'label',
  order: SortByDirection.Desc,
};

function WoVendorStatusesPage() {
  // const result = useQuery(woVendorStatusesQuery, {
  //   fetchPolicy: 'network-only',
  // });
  const refetch = useCallback(() => {}, []);

  const filters = useRef({});
  const sortBy = useRef<SortBy[]>([initialOrder]);
  const allFilters: AllFilter[] = useMemo(() => [], []);

  const [editing, setEditing] = useState<any>();
  const handleEdit = (woVendorStatus: WoVendorStatusNode) =>
    setEditing({ woVendorStatus });
  const cancelEdit = () => setEditing(undefined);
  const finishEdit = () => {
    setEditing(undefined);
    refetch();
  };

  const [deleting, setDeleting] = useState<any>();
  const handleDelete = (woVendorStatus: WoVendorStatusNode) =>
    setDeleting({ woVendorStatus });
  const cancelDelete = () => setDeleting(undefined);
  const finishDelete = () => {
    setDeleting(undefined);
    refetch();
  };

  const allColumns = useMemo(
    () => [
      {
        key: 'status',
        title: 'Status',
        component: (row: any) => <Typography>{row.label}</Typography>,
        sortable: true,
      },
      {
        key: 'color',
        title: 'Color',
        component: (row: any) => (
          <Box
            sx={{
              backgroundColor: row.color,
              borderRadius: 8,
              padding: '4px 12px',
            }}
          >
            <Typography
              sx={{
                color: row.colorNames?.['solidColor'],
                fontWeight: 600,
              }}
            >
              {row.label}
            </Typography>
          </Box>
        ),
        sortable: true,
      },
      {
        key: 'actions',
        title: '',
        component: (row: any) =>
          !row.builtIn ? (
            <>
              <IconButton sx={{ mr: 1 }} onClick={() => handleEdit(row)}>
                <Edit />
              </IconButton>
              <IconButton onClick={() => handleDelete(row)}>
                <Delete />
              </IconButton>
            </>
          ) : (
            <></>
          ),
        sortable: false,
        width: 100,
      },
    ],
    [],
  );

  const woStatuses = useWoVendorStatuses();
  const orderedStatuses = useMemo(() => {
    return sortWoVendorStatuses(woStatuses);
  }, [woStatuses]);
  const [updateWoVendorStatus] = useMutation(updateWoVendorStatusMutation);

  const [term, setTerm] = useState<string | undefined>();
  const search = (_term: string) => setTerm(_term.toLowerCase());
  const rows = useMemo(() => {
    let initial = orderedStatuses;
    if (term) {
      initial = initial.filter((pm: WoVendorStatusNode) =>
        pm.label.toLowerCase().includes(term),
      );
    }
    return initial;
  }, [orderedStatuses, term]);

  return (
    <MainPageTemplate title="Vendor Statuses">
      <Box sx={{ pt: 2, pb: 2, pl: 2, pr: 2 }}>
        <Box sx={{ pl: 2, pr: 2 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              pt: 2,
              pb: 2,
              justifyContent: 'flex-start',
            }}
          >
            <Box>
              <Typography level="h1">Vendor Statuses</Typography>
            </Box>
            <Box sx={{ flex: 1 }} />
            <AddWoVendorStatusModal />
            <ChangeSomeStatusOrderModal
              parentStatuses={orderedStatuses}
              updateMutation={updateWoVendorStatus}
            />
          </Box>
        </Box>
        <Divider sx={{ mb: 2 }} />
        <Box sx={{ pl: 2, pr: 2 }}>
          <MyTable
            initialOrder={initialOrder}
            rows={rows}
            sortBy={sortBy}
            filters={filters}
            allFilters={allFilters}
            search={search}
            allColumns={allColumns}
            hasNextPage={false}
            next={() => {}}
          />
        </Box>
      </Box>
      <EditWoVendorStatusModal
        visible={editing}
        onCancel={cancelEdit}
        onFinish={finishEdit}
      />
      <DeleteWoVendorStatusModal
        visible={deleting}
        onCancel={cancelDelete}
        onFinish={finishDelete}
      />
    </MainPageTemplate>
  );
}

export default WoVendorStatusesPage;
