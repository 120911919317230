import SelectFieldElement from '../../components/SelectFieldElement';

interface Props {
  control: any;
  name: string;
  label?: string;
  placeholder?: string;
  sx: any;
  disabled?: any;
  rules?: any;
}

const options = [
  {
    key: '#ffffff',
    label: 'White',
  },
  {
    key: '#171A1C',
    label: 'Black',
  },
];

function SelectTextColorFieldElement({
  control,
  name,
  label,
  placeholder,
  sx,
  disabled,
  rules,
}: Props) {
  return (
    <SelectFieldElement
      control={control}
      name={name}
      options={options}
      label={label}
      placeholder={placeholder}
      sx={sx}
      disabled={disabled}
      rules={rules}
    />
  );
}

export default SelectTextColorFieldElement;
