import WarningIcon from '@mui/icons-material/Warning';
import { Box, Card, Grid, Link, Skeleton, Typography } from '@mui/joy';
import { useMemo } from 'react';
import { VendorNode } from '../../__generated__/graphql';
import MyGridItem, {
  DisplayAddress,
  MyDetailItem,
} from '../../components/MyGridItem';
import OpenButton from '../../components/OpenButton';
import EditAddressInner from '../../components/edit-inner/EditAddressInner';
import EditPhoneInner from '../../components/edit-inner/EditPhoneInner';
import EditTextAreaInner from '../../components/edit-inner/EditTextAreaInner';
import EditTextInner from '../../components/edit-inner/EditTextInner';
import { formatPhoneNumberForDisplay } from '../../shared/utils';
import EditServiceAreaInner from './EditServiceAreaInner';
import EditSomeVendorLine from './EditSomeVendorLine';

interface Props {
  vendor: VendorNode | undefined;
  loading: boolean;
}

function VendorDetailsCard({ loading, vendor }: Props) {
  const details = useMemo<MyDetailItem[] | undefined>(() => {
    if (vendor) {
      return [
        {
          key: 'phone',
          label: 'Phone',
          value: vendor.phone && formatPhoneNumberForDisplay(vendor.phone),
          width: 4,
          lgWidth: 3,
          component: (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              {vendor.phone ? (
                <Typography level="body-md" sx={{ mr: 2, pt: 0.5 }}>
                  {vendor.phone && formatPhoneNumberForDisplay(vendor.phone)}
                </Typography>
              ) : (
                <Box sx={{ flex: 1 }} />
              )}
              {vendor._id && (
                <EditSomeVendorLine
                  _id={vendor._id}
                  EditForm={
                    <EditPhoneInner
                      defaultValues={{ phone: vendor.phone || '' }}
                      title="Update Phone Number"
                      name="phone"
                      label="Phone Number"
                      rules={{ required: 'This field is required' }}
                    />
                  }
                  OpenButton={<OpenButton size="sm" />}
                />
              )}
            </Box>
          ),
        },
        {
          key: 'email',
          label: 'Email',
          width: 4,
          lgWidth: 3,
          value: vendor.email,
          component: (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              {vendor.email ? (
                <Typography level="body-md" sx={{ mr: 2, pt: 0.5 }}>
                  {vendor.email}
                </Typography>
              ) : (
                <Box sx={{ flex: 1 }} />
              )}
              {vendor._id && (
                <EditSomeVendorLine
                  _id={vendor._id}
                  EditForm={
                    <EditTextInner
                      defaultValues={{ email: vendor.email || '' }}
                      title="Update Email"
                      name="email"
                      label="Email"
                      rules={{ required: 'This field is required' }}
                    />
                  }
                  OpenButton={<OpenButton size="sm" />}
                />
              )}
            </Box>
          ),
        },
        {
          key: 'address',
          label: 'Address',
          width: 4,
          lgWidth: 3,
          component: (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              {vendor.address ? (
                <DisplayAddress address={vendor.address} sx={{ pt: 0.5 }} />
              ) : (
                <Box sx={{ flex: 1 }} />
              )}
              {vendor._id && (
                <EditSomeVendorLine
                  _id={vendor._id}
                  EditForm={
                    <EditAddressInner
                      defaultValues={{ ...(vendor.address || undefined) }}
                      title="Update Address"
                      name="address"
                    />
                  }
                  OpenButton={<OpenButton size="sm" />}
                />
              )}
            </Box>
          ),
        },
        {
          key: 'locations',
          label: 'Service Location Description',
          width: 4,
          lgWidth: 3,
          value: vendor.locations?.join(', '),
          component: (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              {vendor.locations && vendor.locations.length > 0 ? (
                <Typography level="body-md" sx={{ mr: 2, pt: 0.5 }}>
                  {vendor.locations.join(', ')}
                </Typography>
              ) : (
                <Box sx={{ flex: 1 }} />
              )}
              {vendor._id && (
                <EditSomeVendorLine
                  _id={vendor._id}
                  onSaveCallback={(values: any) => ({
                    locations: values.locations
                      .split(',')
                      .map((loc: any) => loc.trim()),
                  })}
                  EditForm={
                    <EditTextInner
                      defaultValues={{
                        locations:
                          vendor.locations && vendor.locations.length > 0
                            ? vendor.locations.join(', ')
                            : '',
                      }}
                      title="Update Service Locations"
                      name="locations"
                      label="Service Locations"
                      rules={{ required: 'This field is required' }}
                    />
                  }
                  OpenButton={<OpenButton size="sm" />}
                />
              )}
            </Box>
          ),
        },
        {
          key: 'serviceTypeDesc',
          label: 'Service Type Description',
          width: 4,
          lgWidth: 3,
          value: vendor.serviceTypeDesc,
          component: (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              {vendor.serviceTypeDesc ? (
                <Typography level="body-md" sx={{ mr: 2, pt: 0.5 }}>
                  {vendor.serviceTypeDesc}
                </Typography>
              ) : (
                <Box sx={{ flex: 1 }} />
              )}
              {vendor._id && (
                <EditSomeVendorLine
                  _id={vendor._id}
                  EditForm={
                    <EditTextInner
                      defaultValues={{
                        serviceTypeDesc: vendor.serviceTypeDesc || '',
                      }}
                      title="Update Services"
                      name="serviceTypeDesc"
                      label="Services"
                      rules={{ required: 'This field is required' }}
                    />
                  }
                  OpenButton={<OpenButton size="sm" />}
                />
              )}
            </Box>
          ),
        },
        {
          key: 'website',
          label: 'Website',
          width: 4,
          lgWidth: 3,
          component: (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              {vendor.website ? (
                <Typography
                  level="body-md"
                  sx={{ mr: 2, pt: 0.5 }}
                  component={Link}
                  href={vendor.website}
                  target="_blank"
                >
                  {vendor.website}
                </Typography>
              ) : (
                <Box sx={{ flex: 1 }} />
              )}
              {vendor._id && (
                <EditSomeVendorLine
                  _id={vendor._id}
                  EditForm={
                    <EditTextInner
                      defaultValues={{
                        website: vendor.website || '',
                      }}
                      title="Update Website"
                      name="website"
                      label="Website"
                      rules={{ required: 'This field is required' }}
                      caption="Make sure to include the https:// part."
                    />
                  }
                  OpenButton={<OpenButton size="sm" />}
                />
              )}
            </Box>
          ),
        },
        {
          key: 'serviceArea',
          label: 'Service Area',
          width: 12,
          component: (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              {vendor.serviceArea && vendor.serviceAreaName ? (
                <Typography level="body-md" sx={{ mr: 2, pt: 0.5 }}>
                  {vendor.serviceAreaName}
                </Typography>
              ) : (
                <Typography
                  color="warning"
                  level="body-md"
                  sx={{ mr: 2, pt: 0.5 }}
                  startDecorator={<WarningIcon />}
                >
                  Add a service Area ...
                </Typography>
              )}
              {vendor._id && (
                <EditSomeVendorLine
                  _id={vendor._id}
                  EditForm={
                    <EditServiceAreaInner
                      defaultValues={{
                        serviceArea: vendor.serviceArea || null,
                        serviceAreaName: vendor.serviceAreaName || '',
                      }}
                      title="Update Service Area"
                      name="serviceArea"
                      label="Service Area"
                    />
                  }
                  OpenButton={<OpenButton size="sm" />}
                />
              )}
            </Box>
          ),
        },
        {
          key: 'notes',
          label: 'Notes',
          width: 12,
          component: (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <Box sx={{ flex: 1, pt: 0.5 }}>
                {(vendor.notes || 'Add a note or additional description ...')
                  .split('\n')
                  .map((line, index) => (
                    <Typography key={index} level="body-md">
                      {line}
                    </Typography>
                  ))}
              </Box>
              {vendor?._id && (
                <EditSomeVendorLine
                  _id={vendor._id}
                  EditForm={
                    <EditTextAreaInner
                      defaultValues={{ notes: vendor.notes || '' }}
                      title="Edit Notes"
                      name="notes"
                      label="Notes"
                      placeholder="Add a note or additional description ..."
                      rules={{ required: 'This field is required' }}
                    />
                  }
                  OpenButton={<OpenButton size="sm" />}
                />
              )}
            </Box>
          ),
        },
      ];
    }
    return undefined;
  }, [vendor]);
  return (
    <Card
      sx={{
        p: 2,
        pt: 4,
        pb: 4,
        mr: 'auto',
        ml: 'auto',
        mb: 4,
      }}
    >
      {details ? (
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          {details.map((item) => (
            <Grid key={item.key} xs={item.width} lg={item.lgWidth}>
              <MyGridItem item={item} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <>
          {loading ? (
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
              {Array(18)
                .fill(0)
                .map((_, index) => (
                  <Grid key={index} xs={4} lg={3}>
                    <Skeleton
                      variant="rectangular"
                      height={24}
                      sx={{ mb: 1, mt: 1 }}
                    />
                  </Grid>
                ))}
            </Grid>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                p: 6,
              }}
            >
              <Typography sx={{ textAlign: 'center' }}>
                Nothing to see here.
              </Typography>
            </Box>
          )}
        </>
      )}
    </Card>
  );
}

export default VendorDetailsCard;
