import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Typography,
} from '@mui/joy';
import { useSearchParams } from 'react-router-dom';
import { WorkOrderFilters } from '../../__generated__/graphql';
import useWorkOrderStatuses, {
  sortWorkOrderStatuses,
} from '../work-order-statuses/use-work-order-statuses';
import MondayWorkOrdersSingle from './MondayWorkOrdersSingle';
import useWoColumns from './use-wo-columns';
import useWoFilters from './use-wo-filters';
import useWorkOrderNumbers from './use-wo-numbers';

interface Props {
  filters?: WorkOrderFilters;
}

function MondayWorkOrdersAll({ filters: parentFilters }: Props) {
  const allFilters = useWoFilters();
  const allColumns = useWoColumns();

  const [searchParams, setSearchParams] = useSearchParams();
  const woNumbers: any = useWorkOrderNumbers(parentFilters);
  const workOrderStatuses = useWorkOrderStatuses();

  return (
    <AccordionGroup>
      <Accordion
        expanded={searchParams.get('ALL') === 'true'}
        onChange={(event, expanded) => {
          setSearchParams((params: any) => {
            const isTrue = params.get('ALL') === 'true';
            params.set('ALL', isTrue ? false : true);
            return params;
          });
        }}
        sx={{ borderBottom: 0 }}
      >
        <AccordionSummary
          color="ALL"
          variant="solid"
          slotProps={{
            root: {
              sx: {
                borderRadius: 8,
                borderWidth: 0,
              },
            },
            button: {
              sx: { borderRadius: 8, borderWidth: 0 },
            },
          }}
        >
          <Typography
            level="title-lg"
            sx={{
              color: `var(--joy-palette-ALL-solidColor)`,
            }}
          >
            {woNumbers ? `All (${woNumbers.ALL})` : 'All'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ pt: 1, pb: 1 }}>
          <MondayWorkOrdersSingle
            filters={{
              status: undefined,
              ...parentFilters,
            }}
            allColumns={allColumns}
            allFilters={allFilters}
            skip={searchParams.get('ALL') !== 'true'}
          />
        </AccordionDetails>
      </Accordion>
      {sortWorkOrderStatuses(workOrderStatuses).map((status: any) => (
        <Accordion
          key={status._id}
          expanded={searchParams.get(status._id) === 'true'}
          onChange={(event, expanded) => {
            setSearchParams((params: any) => {
              const isTrue = params.get(status._id) === 'true';
              params.set(status._id, isTrue ? false : true);
              return params;
            });
          }}
          sx={{ borderBottom: 0 }}
        >
          <AccordionSummary
            color={status._id}
            variant="solid"
            slotProps={{
              root: {
                sx: {
                  borderRadius: 8,
                  borderWidth: 0,
                },
              },
              button: {
                sx: { borderRadius: 8, borderWidth: 0 },
              },
            }}
          >
            <Typography
              level="title-lg"
              sx={{
                color: `var(--joy-palette-${status._id}-solidColor)`,
              }}
            >
              {woNumbers
                ? `${status.label} (${woNumbers[status._id]})`
                : status.label}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ pt: 1, pb: 1 }}>
            <MondayWorkOrdersSingle
              filters={{
                status: status._id === 'ALL' ? undefined : status._id,
                ...parentFilters,
              }}
              allColumns={allColumns}
              allFilters={allFilters}
              skip={searchParams.get(status._id) !== 'true'}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </AccordionGroup>
  );
}

export default MondayWorkOrdersAll;
