import { Box, Card, Grid, Link, Skeleton, Typography } from '@mui/joy';
import { useMemo } from 'react';
import { LocationNode } from '../../__generated__/graphql';
import MyGridItem, {
  DisplayAddress,
  MyDetailItem,
} from '../../components/MyGridItem';
import OpenButton from '../../components/OpenButton';
import EditTextAreaInner from '../../components/edit-inner/EditTextAreaInner';
import { formatPhoneNumberForDisplay } from '../../shared/utils';
import EditSomeLocationLine from './EditSomeLocationLine';

interface Props {
  location: LocationNode | undefined;
  loading: boolean;
}

function LocationDetailsCard({ loading, location }: Props) {
  const details = useMemo<MyDetailItem[] | undefined>(() => {
    if (location) {
      return [
        {
          key: 'storeId',
          label: 'Store ID',
          width: 4,
          lgWidth: 3,
          value: location.storeId,
        },
        {
          key: 'customerId',
          label: 'Customer',
          width: 4,
          lgWidth: 3,
          value: location.customer?.scName,
          link:
            location.customerId &&
            `/customers/view/${location.customerId}/discussion`,
        },
        {
          key: 'gps',
          label: 'GPS Location',
          width: 4,
          lgWidth: 3,
          value:
            location.scLat && location.scLon
              ? `${location.scLat}, ${location.scLon}`
              : undefined,
          component: (
            <Box>
              {location.scLat && location.scLon ? (
                <>
                  <Typography level="body-sm">{`${location.scLat}, ${location.scLon}`}</Typography>
                  <Link
                    href={`https://www.google.com/maps/place/${location.scLat},${location.scLon}`}
                    target="_blank"
                  >
                    Google Map
                  </Link>
                </>
              ) : null}
            </Box>
          ),
        },
        {
          key: 'scContact',
          label: 'Contact',
          width: 4,
          lgWidth: 3,
          value: location.scContact,
        },
        {
          key: 'scPhone',
          label: 'Phone',
          value:
            location.scPhone && formatPhoneNumberForDisplay(location.scPhone),
          width: 4,
          lgWidth: 3,
          component: (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              {location.scPhone ? (
                <Typography level="body-md" sx={{ mr: 2, pt: 0.5 }}>
                  {location.scPhone &&
                    formatPhoneNumberForDisplay(location.scPhone)}
                </Typography>
              ) : (
                <Box sx={{ flex: 1 }} />
              )}
            </Box>
          ),
        },
        {
          key: 'scFax',
          label: 'Fax',
          value: location.scFax && formatPhoneNumberForDisplay(location.scFax),
          width: 4,
          lgWidth: 3,
          component: (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              {location.scFax ? (
                <Typography level="body-md" sx={{ mr: 2, pt: 0.5 }}>
                  {location.scFax &&
                    formatPhoneNumberForDisplay(location.scFax)}
                </Typography>
              ) : (
                <Box sx={{ flex: 1 }} />
              )}
            </Box>
          ),
        },
        {
          key: 'scEmail',
          label: 'Email',
          width: 4,
          lgWidth: 3,
          value: location.scEmail,
          component: (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              {location.scEmail ? (
                <Typography level="body-md" sx={{ mr: 2, pt: 0.5 }}>
                  {location.scEmail}
                </Typography>
              ) : (
                <Box sx={{ flex: 1 }} />
              )}
            </Box>
          ),
        },
        {
          key: 'address',
          label: 'Address',
          width: 4,
          lgWidth: 3,
          value: location.scAddress
            ? JSON.stringify(location.scAddress)
            : undefined,
          component: (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              {location.scAddress ? (
                <DisplayAddress
                  address={location.scAddress}
                  sx={{ pt: 0.5 }}
                  level="body-md"
                />
              ) : (
                <Box sx={{ flex: 1 }} />
              )}
            </Box>
          ),
        },
        {
          key: 'notes',
          label: 'Notes',
          width: 12,
          value: 'notes',
          component: (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <Box sx={{ flex: 1, pt: 0.5 }}>
                {(location.notes || 'Add a note or additional description ...')
                  .split('\n')
                  .map((line, index) => (
                    <Typography key={index} level="body-md">
                      {line}
                    </Typography>
                  ))}
              </Box>
              {location?._id && (
                <EditSomeLocationLine
                  _id={location._id}
                  EditForm={
                    <EditTextAreaInner
                      defaultValues={{ notes: location.notes || '' }}
                      title="Edit Notes"
                      name="notes"
                      label="Notes"
                      placeholder="Add a note or additional description ..."
                      rules={{ required: 'This field is required' }}
                    />
                  }
                  OpenButton={<OpenButton size="sm" />}
                />
              )}
            </Box>
          ),
        },
      ].filter((x) => x.value);
    }
    return undefined;
  }, [location]);
  return (
    <Card
      sx={{
        p: 2,
        pt: 4,
        pb: 4,
        mr: 'auto',
        ml: 'auto',
        mb: 4,
      }}
    >
      {details ? (
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          {details.map((item) => (
            <Grid key={item.key} xs={item.width} lg={item.lgWidth}>
              <MyGridItem item={item} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <>
          {loading ? (
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
              {Array(18)
                .fill(0)
                .map((_, index) => (
                  <Grid key={index} xs={4} lg={3}>
                    <Skeleton
                      variant="rectangular"
                      height={24}
                      sx={{ mb: 1, mt: 1 }}
                    />
                  </Grid>
                ))}
            </Grid>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                p: 6,
              }}
            >
              <Typography sx={{ textAlign: 'center' }}>
                Nothing to see here.
              </Typography>
            </Box>
          )}
        </>
      )}
    </Card>
  );
}

export default LocationDetailsCard;
