import {
  Box,
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from '@mui/joy';
import { useCallback, useEffect, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch } from 'react-redux';
import useSafeState from '../../shared/use-safe-state';
import { showMessage } from '../../store/snackbar-slice';

interface DropProps {
  index: number;
  onDrop: (index: number, item: any) => void;
  children: any;
}

function DroppableRow({ index, onDrop, children }: DropProps) {
  const [, drop] = useDrop(
    () => ({
      accept: 'ROW',
      drop: (item) => onDrop(index, item),
    }),
    [index],
  );
  return (
    <div ref={drop} style={{}}>
      {children}
    </div>
  );
}

interface RowProps {
  status: any;
  prevIndex: number;
}

function DraggableRow({ status, prevIndex }: RowProps) {
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: 'ROW',
      item: { status, prevIndex },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    }),
    [status._id, prevIndex],
  );
  return (
    <div
      ref={dragRef}
      style={{
        backgroundColor: `var(--joy-palette-${status._id}-solidBg)`,
        borderRadius: 8,
        padding: '4px 12px',
        marginBottom: 8,
        transform: 'translate(0, 0)',
      }}
    >
      <Typography
        sx={{
          color: `var(--joy-palette-${status._id}-solidColor)`,
          fontWeight: 600,
        }}
      >
        {status.label}
      </Typography>
    </div>
  );
}

interface Props {
  parentStatuses: any;
  updateMutation: any;
}

function ChangeSomeStatusOrderModal({ parentStatuses, updateMutation }: Props) {
  const [visible, setVisible] = useState(false);
  const onCancel = () => setVisible(false);
  const onEdit = () => setVisible(true);

  const [statuses, setStatuses, _statuses] =
    useSafeState<any[]>(parentStatuses);

  useEffect(() => {
    setStatuses(parentStatuses);
  }, [parentStatuses, setStatuses]);

  const onDrop = useCallback(
    (index: number, { status, prevIndex }: any) => {
      let ss = _statuses.current.filter((s) => s._id !== status._id);
      if (prevIndex !== index) {
        ss = [...ss.slice(0, index), status, ...ss.slice(index)];
        setStatuses(ss);
      }
    },
    [_statuses, setStatuses],
  );

  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();
  const onSubmit = useCallback(async () => {
    setSaving(true);
    try {
      await Promise.all(
        statuses.map(async (_status, index) => {
          const { __typename, ...status } = _status;
          await updateMutation({
            variables: {
              workOrderStatus: {
                ...status,
                sort: index,
              },
            },
          });
        }),
      );

      dispatch(
        showMessage({
          _id: 'update-payment-method',
          severity: 'success',
          message: 'Success',
        }),
      );
      setVisible(false);
    } catch (err: any) {
      console.error(err);
      dispatch(
        showMessage({
          _id: 'update-payment-method',
          severity: 'danger',
          message: 'There was an error saving your status',
        }),
      );
    }
    setSaving(false);
  }, [updateMutation, statuses, dispatch]);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Modal open={!!visible} onClose={onCancel}>
          <ModalDialog sx={{ p: 4, width: 400, maxWidth: '90uvw' }}>
            <ModalClose />
            <Typography level="h2">Change Order</Typography>
            <Typography level="body-md">
              Drag and drop the statuses below to change their displayed order.
            </Typography>
            {saving ? (
              <Typography level="body-md">Saving ...</Typography>
            ) : (
              <Box>
                {Array(statuses.length)
                  .fill(0)
                  .map((_, index) => (
                    <DroppableRow key={index} index={index} onDrop={onDrop}>
                      <DraggableRow
                        status={statuses[index]}
                        prevIndex={index}
                      />
                    </DroppableRow>
                  ))}
              </Box>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
              <Button
                variant="outlined"
                color="neutral"
                disabled={saving}
                size="lg"
                onClick={onCancel}
                sx={{ mr: 4, width: 100 }}
              >
                Cancel
              </Button>
              <Button
                onClick={onSubmit}
                loading={saving}
                variant="solid"
                size="lg"
                sx={{ width: 100 }}
              >
                Save
              </Button>
            </Box>
          </ModalDialog>
        </Modal>
      </DndProvider>
      <Button onClick={onEdit} variant="outlined">
        Change Order
      </Button>
    </>
  );
}

export default ChangeSomeStatusOrderModal;
