import { Box, Card, Chip, Grid, Skeleton, Typography } from '@mui/joy';
import { useMemo } from 'react';
import { WorkOrderNode } from '../../__generated__/graphql';
import MyGridItem, { MyDetailItem } from '../../components/MyGridItem';
import OpenButton from '../../components/OpenButton';
import EditTextAreaInner from '../../components/edit-inner/EditTextAreaInner';
import { notNullOrUndefined } from '../../shared/utils';
import { DisplayLocationSimple } from './DisplayLocation';
import EditSomeWorkOrderLine from './EditSomeWorkOrderLine';
import useWoVendorStatuses from '../wo-vendor-statuses/use-work-order-statuses';
import EditWoVendorStatusInner from '../wo-vendor-statuses/EditWoVendorStatusInner';

const dateFormat = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'short',
  timeStyle: 'short',
});

const moneyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

interface Props {
  workOrder: WorkOrderNode | undefined;
  loading: boolean;
}

function WorkOrderDetailsCard({ loading, workOrder }: Props) {
  const woVendorStatuses = useWoVendorStatuses();
  const details = useMemo<MyDetailItem[] | undefined>(() => {
    if (workOrder) {
      return [
        {
          key: 'scDesc',
          label: 'Description',
          value: workOrder.scDesc,
          width: 12,
        },
        {
          key: 'notes',
          label: 'Notes',
          width: 12,
          component: (
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ flex: 1 }}>
                {(workOrder.notes || 'Add a note or additional description ...')
                  .split('\n')
                  .map((line, index) => (
                    <Typography key={index} level="body-md">
                      {line}
                    </Typography>
                  ))}
              </Box>
              {workOrder?._id && (
                <EditSomeWorkOrderLine
                  _id={workOrder._id}
                  EditForm={
                    <EditTextAreaInner
                      defaultValues={{ notes: workOrder.notes || '' }}
                      title="Edit Notes"
                      name="notes"
                      label="Notes"
                      placeholder="Add a note or additional description ..."
                      rules={{ required: 'This field is required' }}
                    />
                  }
                  OpenButton={<OpenButton />}
                />
              )}
            </Box>
          ),
        },
        workOrder.scResolution && {
          key: 'scResolution',
          label: 'Resolution',
          value: workOrder.scResolution,
          width: 12,
        },
        {
          key: 'customerId',
          label: 'Customer',
          width: 4,
          lgWidth: 3,
          value: workOrder.customer?.scName,
          link:
            workOrder.customerId &&
            `/customers/view/${workOrder.customerId}/discussion`,
        },
        {
          key: 'locationId',
          label: 'Location',
          width: 4,
          lgWidth: 3,
          value: workOrder.locationId,
          component: workOrder.location && (
            <DisplayLocationSimple location={workOrder.location} />
          ),
          link:
            workOrder.locationId &&
            `/locations/view/${workOrder.locationId}/discussion`,
        },
        {
          key: 'scNumber',
          label: 'Service Request Number',
          width: 4,
          lgWidth: 3,
          value: workOrder.scNumber,
        },
        {
          key: 'scPrimaryStatus',
          label: 'Service Channel Status',
          width: 4,
          lgWidth: 3,
          component: (
            <Box>
              <Typography>{workOrder.scPrimaryStatus}</Typography>
              <Typography level="body-xs">
                {workOrder.scExtendedStatus}
              </Typography>
            </Box>
          ),
        },
        {
          key: 'scCaller',
          label: 'Caller',
          width: 4,
          lgWidth: 3,
          value: workOrder.scCaller,
        },
        {
          key: 'scPriority',
          label: 'Priority',
          width: 4,
          lgWidth: 3,
          value: workOrder.scPriority,
        },
        {
          key: 'scTrade',
          label: 'Trade',
          width: 4,
          lgWidth: 3,
          value: workOrder.scTrade,
        },
        {
          key: 'scCategory',
          label: 'Category',
          width: 4,
          lgWidth: 3,
          value: workOrder.scCategory,
        },
        // {
        //   key: 'scApprovalCode',
        //   label: 'Approval Code',
        //   width: 4,
        //   lgWidth: 3,
        //   value: workOrder.scApprovalCode,
        // },
        {
          key: 'scNotToExceed',
          label: 'Not to Exceed',
          width: 4,
          lgWidth: 3,
          value:
            notNullOrUndefined(workOrder.scNotToExceed) &&
            moneyFormat.format(workOrder.scNotToExceed as any),
        },
        {
          key: 'scIsInvoiced',
          label: 'Is Invoiced',
          width: 4,
          lgWidth: 3,
          value: workOrder.scIsInvoiced ? 'Yes' : 'No',
        },
        {
          key: 'scCallDate',
          label: 'Call Date',
          width: 4,
          lgWidth: 3,
          value: dateFormat.format(new Date(workOrder.scCallDate)),
        },
        {
          key: 'scScheduledDate',
          label: 'Scheduled Date',
          width: 4,
          lgWidth: 3,
          value: dateFormat.format(new Date(workOrder.scScheduledDate)),
        },
        {
          key: 'scExpirationDate',
          label: 'Expiration Date',
          width: 4,
          lgWidth: 3,
          value: dateFormat.format(new Date(workOrder.scExpirationDate)),
        },
        {
          key: 'vendorStatus',
          label: 'Vendor Status',
          width: 4,
          lgWidth: 3,
          value: workOrder.vendorStatus,
          component: (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              {workOrder.vendorStatus && (
                <Chip
                  color={workOrder.vendorStatus as any}
                  variant="solid"
                  size="md"
                  sx={{ mt: 1 }}
                >
                  {woVendorStatuses[workOrder.vendorStatus]?.label}
                </Chip>
              )}
              {/* <Box sx={{ flex: 1 }} />
              {workOrder._id && (
                <EditSomeWorkOrderLine
                  _id={workOrder?._id}
                  EditForm={
                    <EditWoVendorStatusInner
                      defaultValues={{ vendorStatus: workOrder?.vendorStatus }}
                      title="Change Vendor Status"
                      name="vendorStatus"
                      label="Vendor Status"
                      rules={{ required: 'This field is required' }}
                    />
                  }
                  OpenButton={
                    <OpenButton size="sm" title="Change Vendor Status" />
                  }
                />
              )} */}
            </Box>
          ),
        },
      ].filter((i) => i) as MyDetailItem[];
    }
    return undefined;
  }, [workOrder, woVendorStatuses]);
  return (
    <Card
      sx={{
        p: 2,
        pt: 4,
        pb: 4,
        mr: 'auto',
        ml: 'auto',
        mt: 2,
        mb: 2,
      }}
    >
      {details ? (
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          {details.map((item) => (
            <Grid key={item.key} xs={item.width} lg={item.lgWidth}>
              <MyGridItem item={item} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <>
          {loading ? (
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
              {Array(18)
                .fill(0)
                .map((_, index) => (
                  <Grid key={index} xs={4} lg={3}>
                    <Skeleton
                      variant="rectangular"
                      height={24}
                      sx={{ mb: 1, mt: 1 }}
                    />
                  </Grid>
                ))}
            </Grid>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                p: 6,
              }}
            >
              <Typography sx={{ textAlign: 'center' }}>
                Nothing to see here.
              </Typography>
            </Box>
          )}
        </>
      )}
    </Card>
  );
}

export default WorkOrderDetailsCard;
