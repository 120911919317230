import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LocationNode } from '../__generated__/graphql';

interface InitialState {
  [key: string]: {
    [key: string]: LocationNode;
  };
}

interface UpdateLocationPayload {
  queryId: string;
  location: LocationNode;
}

interface DeleteLocationPayload {
  queryId: string;
  _id: string;
}

const initialState: InitialState = {
  default: {},
};

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    updateLocation: {
      reducer(state, action: PayloadAction<UpdateLocationPayload>) {
        state[action.payload.queryId] = {
          ...state[action.payload.queryId],
          [action.payload.location._id]: action.payload.location,
        };
      },
      prepare(location: LocationNode, queryId = 'default') {
        return { payload: { location, queryId } };
      },
    },
    deleteLocation: {
      reducer(state, action: PayloadAction<DeleteLocationPayload>) {
        const { [action.payload._id]: removing, ...remaining } =
          state[action.payload.queryId];
        state[action.payload.queryId] = remaining;
      },
      prepare(_id: string, queryId = 'default') {
        return { payload: { _id, queryId } };
      },
    },
  },
});

export const { updateLocation, deleteLocation } = locationsSlice.actions;
export default locationsSlice.reducer;
