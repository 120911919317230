import { Box, Button, Typography } from '@mui/joy';
import { useForm } from 'react-hook-form';
import TextFieldElement from '../TextFieldElement';
import { useCallback } from 'react';

interface Props {
  defaultValues: any;
  title: string;
  name: string;
  required?: boolean;
  onSubmit?: any;
  saving?: boolean;
  onCancel?: () => void;
}

function EditAddressInner({
  defaultValues,
  onSubmit,
  name,
  required,
  saving,
  onCancel,
  title,
}: Props) {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<any>({
    defaultValues,
  });
  const onFinish = useCallback(
    (values: any) => {
      onSubmit({
        [name]: values,
      });
    },
    [onSubmit, name],
  );
  return (
    <form onSubmit={handleSubmit(onFinish)}>
      <Typography level="h3" sx={{ mb: 2, textAlign: 'center' }}>
        {title}
      </Typography>
      <TextFieldElement
        control={control}
        name="address1"
        label="Address Line 1"
        rules={{ required: required ? 'This field is required' : undefined }}
      />
      <TextFieldElement
        control={control}
        name="address2"
        label="Address Line 2"
      />
      <TextFieldElement
        control={control}
        name="city"
        label="City"
        rules={{ required: required ? 'This field is required' : undefined }}
      />
      <TextFieldElement
        control={control}
        name="state"
        label="State"
        rules={{ required: required ? 'This field is required' : undefined }}
      />
      <TextFieldElement
        control={control}
        name="zipCode"
        label="Zip Code"
        rules={{ required: required ? 'This field is required' : undefined }}
      />
      <TextFieldElement control={control} name="country" label="Country" />
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
        <Button
          variant="outlined"
          color="neutral"
          size="lg"
          onClick={onCancel}
          sx={{ mr: 4, width: 100 }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          loading={saving}
          variant="solid"
          size="lg"
          sx={{ width: 100 }}
        >
          Save
        </Button>
      </Box>
    </form>
  );
}

export default EditAddressInner;
