import AttachFileIcon from '@mui/icons-material/AttachFile';
import { AttachmentNode } from '../../__generated__/graphql';
import {
  Box,
  Button,
  Card,
  CardCover,
  IconButton,
  Link,
  Sheet,
  Typography,
} from '@mui/joy';
import DownloadIcon from '@mui/icons-material/Download';

const byteValueNumberFormatter = Intl.NumberFormat('en-US', {
  notation: 'compact',
  style: 'unit',
  unit: 'byte',
  unitDisplay: 'narrow',
});

interface Props {
  attachment: AttachmentNode;
}

function AttachmentCard({ attachment }: Props) {
  return (
    <Sheet
      variant="outlined"
      sx={{ alignSelf: 'flex-start', p: 1, borderRadius: 4 }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {attachment.thumbnailUrl ? (
          <Card component="li" sx={{ height: 60, width: 60, mr: 2 }}>
            <CardCover>
              <img src={attachment.thumbnailUrl} loading="lazy" alt="" />
            </CardCover>
          </Card>
        ) : (
          <AttachFileIcon sx={{ mr: 1 }} />
        )}

        <Box sx={{ mr: 1 }}>
          {attachment.desc && <Typography>{attachment.desc}</Typography>}
          <Typography>{attachment.filename}</Typography>
          <Typography>
            {byteValueNumberFormatter.format(attachment.size)}
          </Typography>
        </Box>
        <Box sx={{ flex: 1 }} />
        {attachment.downloadUrl && (
          <IconButton
            component={Link}
            href={attachment.downloadUrl}
            // download={attachment.filename}
            download
            target="_blank"
          >
            <DownloadIcon />
          </IconButton>
        )}
      </Box>
    </Sheet>
  );
}

export default AttachmentCard;
