import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../store/root';
import MainRouter from './MainRouter';
import PublicRouter from './PublicRouter';

function RootRouter() {
  const signedIn = useSelector(
    (store: RootState) => !!store.auth.token,
    shallowEqual,
  );
  return signedIn ? <MainRouter /> : <PublicRouter />;
}

export default RootRouter;
