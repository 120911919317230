import { Box, Button, Typography } from '@mui/joy';
import { useForm } from 'react-hook-form';
import PhoneNumberFieldElement from '../PhoneNumberFieldElement';
import { useCallback } from 'react';
import { formatPhoneNumberForStorage } from '../../shared/utils';

interface Props {
  defaultValues: any;
  title: string;
  name: string;
  label: string;
  caption?: string;
  rules?: any;
  onSubmit?: any;
  saving?: boolean;
  onCancel?: () => void;
}

function EditPhoneInner({
  defaultValues,
  onSubmit,
  name,
  label,
  caption,
  rules,
  saving,
  onCancel,
  title,
}: Props) {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<any>({
    defaultValues,
  });

  const onFinish = useCallback(
    (values: any) => {
      const unformatted = values[name];
      onSubmit({
        [name]: formatPhoneNumberForStorage(unformatted),
      });
    },
    [onSubmit, name],
  );
  return (
    <form onSubmit={handleSubmit(onFinish)}>
      <Typography level="h3" sx={{ mb: 2, textAlign: 'center' }}>
        {title}
      </Typography>
      <PhoneNumberFieldElement
        control={control}
        name={name}
        label={label}
        rules={rules}
        caption={caption}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
        <Button
          variant="outlined"
          color="neutral"
          size="lg"
          onClick={onCancel}
          sx={{ mr: 4, width: 100 }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          loading={saving}
          variant="solid"
          size="lg"
          sx={{ width: 100 }}
        >
          Save
        </Button>
      </Box>
    </form>
  );
}

export default EditPhoneInner;
