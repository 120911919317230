import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { WorkOrderNode } from '../__generated__/graphql';

interface InitialState {
  [key: string]: {
    [key: string]: WorkOrderNode;
  };
}

interface UpdateWorkOrderPayload {
  queryId: string;
  workOrder: WorkOrderNode;
}

interface DeleteWorkOrderPayload {
  queryId: string;
  _id: string;
}

const initialState: InitialState = {
  default: {},
};

const workOrdersSlice = createSlice({
  name: 'workOrders',
  initialState,
  reducers: {
    updateWorkOrder: {
      reducer(state, action: PayloadAction<UpdateWorkOrderPayload>) {
        state[action.payload.queryId] = {
          ...state[action.payload.queryId],
          [action.payload.workOrder._id]: action.payload.workOrder,
        };
      },
      prepare(workOrder: WorkOrderNode, queryId = 'default') {
        return { payload: { workOrder, queryId } };
      },
    },
    deleteWorkOrder: {
      reducer(state, action: PayloadAction<DeleteWorkOrderPayload>) {
        const { [action.payload._id]: removing, ...remaining } =
          state[action.payload.queryId];
        state[action.payload.queryId] = remaining;
      },
      prepare(_id: string, queryId = 'default') {
        return { payload: { _id, queryId } };
      },
    },
  },
});

export const { updateWorkOrder, deleteWorkOrder } = workOrdersSlice.actions;
export default workOrdersSlice.reducer;
