import { FormControl, FormHelperText, FormLabel, Input } from '@mui/joy';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

export function finishDateField(v: any) {
  return v.toISOString();
}

export function initializeDateField(v: string | Date) {
  return dayjs(v);
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const DateFieldAdapter = React.forwardRef<HTMLElement, CustomProps>(
  function DateFieldAdapter(props: any, ref: any) {
    const { onChange, value, ...other }: any = props;
    return (
      <DatePicker
        {...other}
        slotProps={{ field: { clearable: true } }}
        value={value ? dayjs(value) : null}
        getInputRef={ref}
        onChange={(value: any) => {
          let newValue = null;
          try {
            newValue = value ? value.toISOString() : null;
          } catch (err) {
            console.warn(err);
          }
          onChange({
            target: {
              name: props.name,
              value: newValue,
            },
          });
        }}
        sx={{
          '&.MuiFormControl-root': { borderWidth: 0 },
          '& .MuiInputBase-input': {
            paddingTop: 0,
            paddingBottom: 0,
            minHeight: '2.25rem',
          },
          '&. MuiOutlinedInput-notchedOutline': {
            display: 'none',
          },
        }}
      />
    );
  },
);

interface Props {
  control: Control<any>;
  name: string;
  label?: string;
  caption?: string;
  placeholder?: string;
  rules?: any;
  sx?: any;
  loading?: boolean;
}

function DateFieldElement({
  control,
  name,
  label,
  caption,
  rules,
  placeholder,
  sx,
  loading,
}: Props) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <FormControl error={!!error} sx={{ mb: 4, ...sx }}>
          {label && <FormLabel>{label}</FormLabel>}
          <Input
            placeholder={placeholder}
            {...field}
            disabled={loading}
            slotProps={{
              input: {
                component: DateFieldAdapter,
              },
            }}
            sx={{ '&.MuiInput-root': { padding: 0 } }}
          />
          {(error || caption) && (
            <FormHelperText>{error?.message || caption}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}

export default DateFieldElement;
