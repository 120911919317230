import { useMemo } from 'react';

function useDiscussionObjectId(filters: any) {
  const objectId = useMemo(() => {
    if (filters.workOrderId) {
      return filters.workOrderId;
    }
    if (filters.locationId) {
      return filters.locationId;
    }
    if (filters.customerId) {
      return filters.customerId;
    }
    if (filters.vendorId) {
      return filters.vendorId;
    }
    if (filters.userId) {
      return filters.userId;
    }
  }, [filters]);
  return objectId;
}

export default useDiscussionObjectId;
