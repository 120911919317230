import { Box, CircularProgress, Typography } from '@mui/joy';

function CircularProgressWithLabel({ value }: any) {
  return (
    <Box
      sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}
    >
      <CircularProgress
        determinate
        value={value}
        sx={{ '--CircularProgress-size': '200px' }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography level="body-lg">{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default CircularProgressWithLabel;
