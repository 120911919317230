import { gql, useMutation } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from '@mui/joy';
import { passwordStrength } from 'check-password-strength';
import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import PasswordFieldElement from '../../components/PasswordFieldElement';
import { showMessage } from '../../store/snackbar-slice';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const changeMyPasswordMutation = gql`
  mutation ChangeMyPassword($password: String!) {
    changeMyPassword(password: $password)
  }
`;

interface Props {}

function ChangeMyPasswordBtn() {
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [changeMyPassword] = useMutation(changeMyPasswordMutation);

  const dispatch = useDispatch();
  const onSubmit: SubmitHandler<any> = useCallback(
    async (_values) => {
      setSaving(true);
      const { password } = _values;
      try {
        await changeMyPassword({
          variables: {
            password,
          },
        });
        setOpen(false);
        dispatch(
          showMessage({
            _id: 'change-password',
            severity: 'success',
            message: 'Success',
          }),
        );
      } catch (err: any) {
        console.error(err);
        dispatch(
          showMessage({
            _id: 'change-password',
            severity: 'danger',
            message: 'There was an error saving your password',
          }),
        );
      }
      setSaving(false);
    },
    [changeMyPassword, dispatch],
  );

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    reset,
  } = useForm<any>({
    defaultValues: {
      name: '',
      email: '',
      roleIds: [],
    },
  });

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [reset, open]);

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog sx={{ p: 4, width: 500, maxWidth: '90uvw' }}>
          <ModalClose />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography level="h3" sx={{ mb: 2, textAlign: 'center' }}>
              Change Password
            </Typography>
            <Box
              sx={{
                overflow: 'scroll',
                maxHeight: '80vh',
              }}
            >
              <PasswordFieldElement
                control={control}
                name="password"
                label="Password"
                caption="Must be reasonably long and/or complex"
                rules={{
                  required: 'This field is required',
                  validate: (value: string) => {
                    const result = passwordStrength(value);
                    if (result.id < 3) {
                      return 'Weak password, try making it longer, including numbers and symbols, or mixing upper and lower case.';
                    }
                    return undefined;
                  },
                }}
                sx={{ mb: 2 }}
              />
              <PasswordFieldElement
                control={control}
                name="confirm"
                label="Confirm Password"
                caption="Enter your password again."
                rules={{
                  required: 'This field is required',
                  validate: (value: string, values: any) => {
                    if (value !== values.password) {
                      return 'Your passwords do not match.';
                    }
                    return undefined;
                  },
                }}
              />
              <Box
                sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}
              >
                <Button
                  variant="outlined"
                  color="neutral"
                  size="lg"
                  onClick={() => setOpen(false)}
                  sx={{ mr: 4, width: 100 }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  loading={saving}
                  variant="solid"
                  size="lg"
                  sx={{ width: 100 }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        </ModalDialog>
      </Modal>
      <Button
        onClick={() => setOpen(true)}
        startDecorator={<LockOutlinedIcon />}
        variant="outlined"
      >
        Change Password
      </Button>
    </>
  );
}

export default ChangeMyPasswordBtn;
