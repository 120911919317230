import { useMemo, useRef } from 'react';
import SelectFieldElement from '../../components/SelectFieldElement';
import {
  AllCustomersQueryQuery,
  CustomerNode,
  SortBy,
  SortByDirection,
} from '../../__generated__/graphql';
import { useManyRemote } from '../../shared/use-many-remote';
import { allCustomersQuery, extractAllCustomers } from './constants';
import { AllFilter } from '../../components/my-table/TableFilters';

const initialOrder: SortBy = {
  key: 'updatedAt',
  order: SortByDirection.Desc,
};

function useCustomerFilter(): AllFilter {
  const filters = useRef({});
  const sortBy = useRef<SortBy[]>([initialOrder]);

  const {
    loading,
    data: rows,
    search,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemote<AllCustomersQueryQuery, CustomerNode>({
    query: allCustomersQuery,
    extract: extractAllCustomers,
    first: 10000,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'cache-first',
    queryId: 'customer-filter',
  });

  const options = useMemo(() => {
    return rows.map((row: CustomerNode) => ({
      key: row._id,
      label: row.scName || '',
    }));
  }, [rows]);

  return {
    key: 'customerId',
    label: 'Customer',
    Component: ({ control }: any) => (
      <SelectFieldElement
        control={control}
        name="customerId"
        options={options}
        label="Filter by customer"
        placeholder="Select a customer"
      />
    ),
    finish: (v: any) => (v === '__undefined__' ? null : v),
    display: (v: any) => {
      const name = rows.find((r) => r._id === v)?.scName;
      return `Customer (${name || v})`;
    },
    defaultValue: null,
  };
}

export default useCustomerFilter;
