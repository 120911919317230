import { gql } from '@apollo/client';
import {
  AllRolesQueryQuery,
  AllUserInvitesQueryQuery,
  AllUsersQueryQuery,
  UserQueryQuery,
} from '../../__generated__/graphql';

export const allUsersQuery = gql`
  query AllUsersQuery(
    $first: Int
    $after: String
    $filters: UserFilters
    $sortBy: [SortBy]
  ) {
    allUsers(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      edges {
        cursor
        node {
          _id
          username
          name
          roleIds
          email
          emailVerified
          phone
          phoneVerified
          address {
            address1
            address2
            city
            state
            county
            country
            zipCode
          }
          profilePhotoId
          profilePhotoThumbUrl
          createdAt
          updatedAt
          deletedAt
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const extractAllUsers = (data: AllUsersQueryQuery) => data.allUsers;

export const allRolesQuery = gql`
  query AllRolesQuery(
    $first: Int
    $after: String
    $filters: RoleFilters
    $sortBy: [SortBy]
  ) {
    allRoles(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      edges {
        cursor
        node {
          _id
          name
          desc
          createdAt
          updatedAt
          deletedAt
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const extractAllRoles = (data: AllRolesQueryQuery) => data.allRoles;

export const userQuery = gql`
  query UserQuery($_id: ID!) {
    user(_id: $_id) {
      _id
      username
      name
      roleIds
      email
      emailVerified
      phone
      phoneVerified
      address {
        address1
        address2
        city
        state
        county
        country
        zipCode
      }
      profilePhotoId
      profilePhotoThumbUrl
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const extractUser = (data: UserQueryQuery) => data.user;
