import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { WorkOrderFilterOptionsQuery } from '../../__generated__/graphql';

export const workOrderFilterOptionsQuery = gql`
  query WorkOrderFilterOptions {
    workOrderFilterOptions {
      categories
      primaryStatuses
      extendedStatuses
      priorities
      minCallDate
      maxCallDate
      minScheduleDate
      maxScheduleDate
      minExpireDate
      maxExpireDate
      tags
    }
  }
`;

function useOptions(woFilterOptions: any, name: string) {
  const options = useMemo(() => {
    if (woFilterOptions?.[name]) {
      return [
        { key: '__undefined__', label: 'Any' },
        ...woFilterOptions[name].map((item: any) => ({
          key: item,
          label: item,
        })),
      ];
    }
    return [];
  }, [woFilterOptions, name]);
  return options;
}

function useWorkOrderFilterOptions() {
  const result = useQuery<WorkOrderFilterOptionsQuery>(
    workOrderFilterOptionsQuery,
    {
      fetchPolicy: 'cache-first',
    },
  );

  const woFilterOptions = result?.data?.workOrderFilterOptions;
  const categoryOptions = useOptions(woFilterOptions, 'categories');
  const primaryStatusOptions = useOptions(woFilterOptions, 'primaryStatuses');
  const extendedStatusOptions = useOptions(woFilterOptions, 'extendedStatuses');
  const priorityOptions = useOptions(woFilterOptions, 'priorities');
  const tagOptions = useOptions(woFilterOptions, 'tags');

  return {
    categoryOptions,
    primaryStatusOptions,
    extendedStatusOptions,
    priorityOptions,
    tagOptions,
  };
}

export default useWorkOrderFilterOptions;
