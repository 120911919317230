import { gql } from '@apollo/client';
import {
  AllWorkOrdersQueryQuery,
  WorkOrderQueryQuery,
} from '../../__generated__/graphql';

export const allWorkOrdersQuery = gql`
  query AllWorkOrdersQuery(
    $first: Int
    $after: String
    $filters: WorkOrderFilters
    $sortBy: [SortBy]
  ) {
    allWorkOrders(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      edges {
        cursor
        node {
          _id
          scId
          scNumber
          customerId
          customer {
            _id
            scName
          }
          locationId
          location {
            _id
            storeId
            scName
            scAddress {
              address1
              address2
              city
              state
              county
              country
              zipCode
            }
            scContact
            scPhone
          }
          vendorId
          vendor {
            _id
            name
            serviceAreaName
            serviceTypeDesc
            locations
            trades
          }
          scPrimaryStatus
          scExtendedStatus
          scCaller
          scCreatedBy
          scPriority
          scTrade
          scApprovalCode
          scCallDate
          scScheduledDate
          scExpirationDate
          scDesc
          scResolution
          scIsInvoiced
          scCategory
          scNotToExceed
          scCreatedAt
          scUpdatedAt
          notes
          status
          tags
          vendorStatus
          createdAt
          updatedAt
          deletedAt
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const extractAllWorkOrders = (data: AllWorkOrdersQueryQuery) =>
  data.allWorkOrders;

export const workOrderQuery = gql`
  query WorkOrderQuery($_id: ID!) {
    workOrder(_id: $_id) {
      _id
      scId
      scNumber
      customerId
      customer {
        _id
        scName
      }
      locationId
      location {
        _id
        storeId
        scName
        scAddress {
          address1
          address2
          city
          state
          county
          country
          zipCode
        }
        scContact
        scPhone
      }
      vendorId
      vendor {
        _id
        name
        phone
        email
        serviceAreaName
        serviceTypeDesc
        locations
        trades
      }
      vendorRequestEmail {
        sentAt
        sentTo
      }
      vendorConfirmation {
        status
        confirmedAt
        confirmedBy
      }
      statusUpdateEmail {
        sentAt
        sentTo
      }
      lastStatusUpdate {
        statusAt
        statusBy
      }
      scPrimaryStatus
      scExtendedStatus
      scCaller
      scCreatedBy
      scPriority
      scTrade
      scApprovalCode
      scCallDate
      scScheduledDate
      scExpirationDate
      scDesc
      scResolution
      scIsInvoiced
      scCategory
      scNotToExceed
      scCreatedAt
      scUpdatedAt
      notes
      status
      tags
      vendorStatus
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const extractWorkOrder = (data: WorkOrderQueryQuery) => data.workOrder;

export const sendVendorRequestEmailMutation = gql`
  mutation SendVendorRequestEmail($workOrderId: ID!) {
    sendVendorRequestEmail(workOrderId: $workOrderId)
  }
`;

export const sendVendorStatusRequestEmailMutation = gql`
  mutation SendVendorStatusRequestEmail($workOrderId: ID!) {
    sendVendorStatusRequestEmail(workOrderId: $workOrderId)
  }
`;

export const WorkOrderVendorStatuses: any = {
  NO_VENDOR: {
    key: 'NO_VENDOR',
    label: 'No Vendor',
    color: 'neutral',
  },
  AWAITING: {
    key: 'AWAITING',
    label: 'Awaiting Response',
    color: 'progress',
  },
  ACCEPTED: {
    key: 'ACCEPTED',
    label: 'Accepted',
    color: 'awaitingApproval',
  },
  DECLINED: {
    key: 'DECLINED',
    label: 'Declined',
    color: 'updateRequired',
  },
  INVOICE: {
    key: 'INVOICE',
    label: 'Vendor Invoice Received',
    color: 'createInvoice',
  },
  PAID: {
    key: 'PAID',
    label: 'Vendor Invoice Paid',
    color: 'invoicePaid',
  },
  UNKNOWN: {
    key: 'UNKNOWN',
    label: 'Unknown',
    color: 'neutral',
  },
};
