import { Box, IconButton, Tooltip } from '@mui/joy';
import { AttachmentNode } from '../../__generated__/graphql';
import AttachmentCard from './AttachmentCard';
import DeleteIcon from '@mui/icons-material/Delete';
import { Maybe } from 'graphql/jsutils/Maybe';

interface Props {
  attachments: Maybe<AttachmentNode>[];
  onRemoveAttachment?: any;
  isRow?: boolean;
}

function DisplayAttachments({ attachments, onRemoveAttachment, isRow }: Props) {
  let sx: any = { flexDirection: 'column', mb: 2 };
  let attachmentSx: any = { mb: 1 };
  if (isRow) {
    sx = { flexDirection: 'row', flexWrap: 'wrap', mb: 0 };
    attachmentSx = { mb: 1, mr: 1 };
  }
  return (
    <Box sx={{ display: 'flex', ...sx }}>
      {attachments.map((attachment: any) => (
        <Box
          key={attachment._id}
          sx={{ display: 'flex', alignItems: 'center', ...attachmentSx }}
        >
          <Box sx={{ flex: 1 }}>
            <AttachmentCard key={attachment._id} attachment={attachment} />
          </Box>
          {onRemoveAttachment && (
            <Tooltip title="Delete">
              <IconButton
                sx={{ ml: 2 }}
                onClick={() => onRemoveAttachment(attachment._id)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ))}
    </Box>
  );
}

export default DisplayAttachments;
