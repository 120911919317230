import { gql, useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  HistoryNode,
  HistoryUpdatesSubscription,
} from '../../__generated__/graphql';
import { deleteHistory, updateHistory } from '../../store/history-slice';
import useHistoryObjectId from './use-history-obj-id';

const historyUpdatesSubscription = gql`
  subscription HistoryUpdates($filters: HistoryFilters!) {
    historyUpdates(filters: $filters) {
      _id
      crud
      new {
        _id
        workOrderId
        locationId
        customerId
        vendorId
        userId
        userInviteId
        discussionId
        changedAt
        changedBy
        changedByName
        changes
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

function useHistoryUpdates(filters: any | undefined, queryId = 'default') {
  const objectId = useHistoryObjectId(filters);
  const dispatch = useDispatch();
  useSubscription<HistoryUpdatesSubscription>(historyUpdatesSubscription, {
    skip: !filters,
    variables: {
      filters,
    },
    fetchPolicy: 'no-cache',
    onSubscriptionData: ({ client, subscriptionData: result }) => {
      try {
        const update = result?.data?.historyUpdates;
        if (update?.new) {
          switch (update.crud) {
            case 'insert':
              dispatch(
                updateHistory(update.new as HistoryNode, objectId, queryId),
              );
              break;
            case 'update':
              dispatch(
                updateHistory(update.new as HistoryNode, objectId, queryId),
              );
              break;
            case 'delete':
              dispatch(deleteHistory(update._id, objectId, queryId));
              break;
            default:
              break;
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
}

export default useHistoryUpdates;
