import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { WoVendorStatusNode } from '../__generated__/graphql';

interface InitialState {
  [key: string]: {
    [key: string]: WoVendorStatusNode;
  };
}

interface ReadWoVendorStatusPayload {
  queryId: string;
  woVendorStatuses: WoVendorStatusNode[];
}

interface UpdateWoVendorStatusPayload {
  queryId: string;
  woVendorStatus: WoVendorStatusNode;
}

interface DeleteWoVendorStatusPayload {
  queryId: string;
  _id: string;
}

const initialState: InitialState = {
  default: {},
};

const woVendorStatusesSlice = createSlice({
  name: 'woVendorStatuses',
  initialState,
  reducers: {
    readWoVendorStatuses: {
      reducer(state, action: PayloadAction<ReadWoVendorStatusPayload>) {
        state[action.payload.queryId] = {};
        action.payload.woVendorStatuses.forEach((status) => {
          state[action.payload.queryId][status._id] = status;
        });
      },
      prepare(woVendorStatuses: WoVendorStatusNode[], queryId = 'default') {
        return { payload: { woVendorStatuses, queryId } };
      },
    },
    updateWoVendorStatus: {
      reducer(state, action: PayloadAction<UpdateWoVendorStatusPayload>) {
        state[action.payload.queryId] = {
          ...state[action.payload.queryId],
          [action.payload.woVendorStatus._id]: action.payload.woVendorStatus,
        };
      },
      prepare(woVendorStatus: WoVendorStatusNode, queryId = 'default') {
        return { payload: { woVendorStatus, queryId } };
      },
    },
    deleteWoVendorStatus: {
      reducer(state, action: PayloadAction<DeleteWoVendorStatusPayload>) {
        const { [action.payload._id]: removing, ...remaining } =
          state[action.payload.queryId];
        state[action.payload.queryId] = remaining;
      },
      prepare(_id: string, queryId = 'default') {
        return { payload: { _id, queryId } };
      },
    },
  },
});

export const {
  updateWoVendorStatus,
  deleteWoVendorStatus,
  readWoVendorStatuses,
} = woVendorStatusesSlice.actions;
export default woVendorStatusesSlice.reducer;
