import { gql, useMutation } from '@apollo/client';
import { Box, Button, Card, Typography } from '@mui/joy';
import { useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../assets/square-logo.svg';
import PasswordFieldElement from '../../components/PasswordFieldElement';
import PublicPageTemplate from '../../components/PublicPageTemplate';
import TextFieldElement from '../../components/TextFieldElement';
import { saveToken } from '../../store/auth-slice';
import { showMessage } from '../../store/snackbar-slice';

const signInMutation = gql`
  mutation SignIn($signIn: SignInInput!) {
    webSignIn(signIn: $signIn) {
      token
    }
  }
`;

type Inputs = {
  username: string;
  password: string;
};

function SignInPage() {
  const [loading, setLoading] = useState(false);
  const [signIn] = useMutation(signInMutation);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      username: '',
      password: '',
    },
  });
  // const onSubmit: SubmitHandler<Inputs> = (data) => console.log(data);
  const dispatch = useDispatch();
  const onSubmit: SubmitHandler<Inputs> = useCallback(
    async (values) => {
      setLoading(true);
      try {
        const { username, password } = values;
        const response = await signIn({
          variables: {
            signIn: {
              username: username.trim().toLowerCase(),
              password,
            },
          },
        });
        dispatch(saveToken({ token: response.data?.webSignIn?.token }));
        dispatch(
          showMessage({
            _id: 'sign-in',
            severity: 'success',
            message: 'Success',
          }),
        );
      } catch (err: any) {
        console.error(err);
        if (err.message.includes('Incorrect')) {
          dispatch(
            showMessage({
              _id: 'sign-in',
              severity: 'danger',
              message: err.message,
            }),
          );
        } else {
          dispatch(
            showMessage({
              _id: 'sign-in',
              severity: 'danger',
              message: 'There was an error signing in.',
            }),
          );
        }
      }
      setLoading(false);
    },
    [signIn, dispatch],
  );
  return (
    <PublicPageTemplate title="Sign In">
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          minHeight: '100%',
          flexDirection: 'column',
        }}
      >
        <img
          src={logo}
          alt="Mahathey Contracting"
          style={{
            marginTop: 120,
            width: 160,
            maxWidth: '90vw',
            marginBottom: 24,
          }}
        />
        <Card style={{ width: 500, maxWidth: '90vw', padding: 32 }}>
          <Typography level="h1" style={{ textAlign: 'center' }}>
            Sign In
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextFieldElement
              name="username"
              label="Username"
              control={control}
              sx={{ mb: 2 }}
              rules={{ required: 'This field is required' }}
            />
            <PasswordFieldElement
              name="password"
              label="Password"
              control={control}
              rules={{ required: 'This field is required' }}
              caption={
                <Typography component={Link} to="/password-help">
                  Password Help
                </Typography>
              }
            />
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                type="submit"
                loading={loading}
                variant="outlined"
                size="lg"
              >
                Submit
              </Button>
            </Box>
          </form>
        </Card>
      </Box>
    </PublicPageTemplate>
  );
}

export default SignInPage;
