import { gql, useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  WorkOrderNode,
  WorkOrderUpdatesSubscription,
} from '../../__generated__/graphql';
import { updateWorkOrder } from '../../store/work-order-slice';

const workOrderUpdatesSubscription = gql`
  subscription WorkOrderUpdates($_id: ID!) {
    workOrderUpdates(_id: $_id) {
      _id
      crud
      new {
        _id
        scId
        scNumber
        customerId
        customer {
          _id
          scName
        }
        locationId
        location {
          _id
          storeId
          scName
          scAddress {
            address1
            address2
            city
            state
            county
            country
            zipCode
          }
          scContact
          scPhone
        }
        vendorId
        vendor {
          _id
          name
          phone
          email
          serviceAreaName
          serviceTypeDesc
          locations
          trades
        }
        vendorRequestEmail {
          sentAt
          sentTo
        }
        vendorConfirmation {
          status
          confirmedAt
          confirmedBy
        }
        statusUpdateEmail {
          sentAt
          sentTo
        }
        lastStatusUpdate {
          statusAt
          statusBy
        }
        scPrimaryStatus
        scExtendedStatus
        scCaller
        scCreatedBy
        scPriority
        scTrade
        scApprovalCode
        scCallDate
        scScheduledDate
        scExpirationDate
        scDesc
        scResolution
        scIsInvoiced
        scCategory
        scNotToExceed
        scCreatedAt
        scUpdatedAt
        notes
        status
        tags
        vendorStatus
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

function useWorkOrderUpdates(_id: string | undefined, queryId = 'default') {
  const dispatch = useDispatch();
  useSubscription<WorkOrderUpdatesSubscription>(workOrderUpdatesSubscription, {
    skip: !_id,
    variables: {
      _id,
    },
    fetchPolicy: 'no-cache',
    onSubscriptionData: ({ client, subscriptionData: result }) => {
      try {
        const update = result?.data?.workOrderUpdates;
        if (update?.new) {
          switch (update.crud) {
            case 'update':
              dispatch(updateWorkOrder(update.new as WorkOrderNode, queryId));
              break;
            default:
              break;
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
}

export default useWorkOrderUpdates;
