import { gql, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  ModalClose,
  ModalDialog,
  Tooltip,
  Typography,
} from '@mui/joy';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showMessage } from '../../store/snackbar-slice';
import { DiscussionNode } from '../../__generated__/graphql';
import TextAreaFieldElement from '../../components/TextAreaFieldElement';
import AttachmentCard from './AttachmentCard';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadDiscussionAttachmentButton from './UploadDiscussionAttachmentButton';
import useDiscussionObjectId from './use-discussion-obj-id';
import useComment from './use-comment';
import DisplayAttachments from './DisplayAttachments';

const updateDiscussionMutation = gql`
  mutation UpdateDiscussion($discussion: UpdateDiscussionInput!) {
    updateDiscussion(discussion: $discussion)
  }
`;

const removeDiscussionAttachmentMutation = gql`
  mutation removeDiscussionAttachment($_id: ID!) {
    removeDiscussionAttachment(_id: $_id)
  }
`;

export type OpenDiscussionModelType =
  | {
      _id: string;
      objectId: string;
      filters?: any;
    }
  | undefined;

interface Props {
  open: OpenDiscussionModelType;
  setOpen: (d: OpenDiscussionModelType) => void;
  refetch: any;
}

function EditDiscussionModal({ open, setOpen, refetch }: Props) {
  const { _id, objectId, filters } = open || {};
  const discussion = useComment(objectId, _id);
  const [saving, setSaving] = useState(false);
  const [updateDiscussion] = useMutation(updateDiscussionMutation);
  const [removeAttachment] = useMutation(removeDiscussionAttachmentMutation);

  const dispatch = useDispatch();
  const onSubmit: SubmitHandler<any> = useCallback(
    async (values) => {
      setSaving(true);
      try {
        await updateDiscussion({
          variables: {
            discussion: {
              _id: discussion?._id,
              ...values,
            },
          },
        });
        // refetch();
        setOpen(undefined);
        dispatch(
          showMessage({
            _id: 'edit-discussion',
            severity: 'success',
            message: 'Success',
          }),
        );
      } catch (err: any) {
        console.error(err);
        dispatch(
          showMessage({
            _id: 'edit-discussion',
            severity: 'danger',
            message: 'There was an error saving your comment',
          }),
        );
      }
      setSaving(false);
    },
    [updateDiscussion, dispatch, discussion, setOpen],
  );

  const onRemoveAttachment = useCallback(
    async (attachmentId: string) => {
      setSaving(true);
      try {
        await removeAttachment({
          variables: {
            _id: attachmentId,
          },
        });
        // refetch();
        dispatch(
          showMessage({
            _id: 'remove-discussion-attachment',
            severity: 'success',
            message: 'Success',
          }),
        );
      } catch (err: any) {
        console.error(err);
        dispatch(
          showMessage({
            _id: 'remove-discussion-attachment',
            severity: 'danger',
            message: 'There was an error removing your attachment',
          }),
        );
      }
      setSaving(false);
    },
    [removeAttachment, dispatch],
  );

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {},
  });

  useEffect(() => {
    setValue('text', discussion?.text || '');
  }, [discussion, setValue]);

  return (
    <>
      <Modal
        open={!!open}
        onClose={() => setOpen(undefined)}
        // sx={{ zIndex: 10001 }}
      >
        <ModalDialog sx={{ p: 4, width: 600, maxWidth: '90uvw' }}>
          <ModalClose />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography level="h3" sx={{ mb: 2, textAlign: 'center' }}>
              Update Comment
            </Typography>
            <TextAreaFieldElement
              control={control}
              name="text"
              label="Comment"
              rules={{ required: 'This field is required' }}
              placeholder="Add a comment, record activity, add an attachment ..."
              minRows={3}
              sx={{
                backgroundColor: 'var(--joy-palette-background-body)',
                mb: 2,
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mb: 2,
              }}
            >
              <Button
                variant="outlined"
                color="neutral"
                size="lg"
                onClick={() => setOpen(undefined)}
                sx={{ mr: 4, width: 100 }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                loading={saving}
                variant="solid"
                size="lg"
                sx={{ width: 100 }}
              >
                Save
              </Button>
            </Box>
          </form>
          <Divider sx={{ mb: 2 }} />
          {discussion?.attachments && (
            <DisplayAttachments
              attachments={discussion.attachments}
              onRemoveAttachment={onRemoveAttachment}
            />
          )}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <UploadDiscussionAttachmentButton
              discussionId={_id}
              filters={filters}
              refetch={refetch}
              myId="edit"
            />
          </Box>
        </ModalDialog>
      </Modal>
    </>
  );
}

export default EditDiscussionModal;
