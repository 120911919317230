import { gql } from '@apollo/client';
import { AllCustomersQueryQuery } from '../../__generated__/graphql';

export const allCustomersQuery = gql`
  query AllCustomersQuery(
    $first: Int
    $after: String
    $filters: CustomerFilters
    $sortBy: [SortBy]
  ) {
    allCustomers(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      edges {
        cursor
        node {
          _id
          scId
          scName
          notes
          logoId
          notes
          createdAt
          updatedAt
          deletedAt
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const extractAllCustomers = (data: AllCustomersQueryQuery) =>
  data.allCustomers;
