import { Button } from '@mui/joy';
import SendIcon from '@mui/icons-material/Send';
import { gql, useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showMessage } from '../../store/snackbar-slice';
import { sendVendorStatusRequestEmailMutation } from './constants';

interface Props {
  workOrderId: string;
  title: string;
}

function SendStatusRequestButton({ workOrderId, title }: Props) {
  const [sending, setSending] = useState(false);
  const [sendVendorStatusRequestEmail] = useMutation(
    sendVendorStatusRequestEmailMutation,
  );

  const dispatch = useDispatch();
  const onSubmit = useCallback(async () => {
    setSending(true);
    try {
      await sendVendorStatusRequestEmail({
        variables: {
          workOrderId,
        },
      });
      dispatch(
        showMessage({
          _id: 'send-status-request',
          severity: 'success',
          message: 'Success',
        }),
      );
    } catch (err: any) {
      console.error(err);
      dispatch(
        showMessage({
          _id: 'send-status-request',
          severity: 'danger',
          message: 'There was an error sending your status request',
        }),
      );
    }
    setSending(false);
  }, [sendVendorStatusRequestEmail, dispatch, workOrderId]);

  return (
    <Button
      startDecorator={<SendIcon />}
      onClick={onSubmit}
      loading={sending}
      variant="outlined"
    >
      {title}
    </Button>
  );
}

export default SendStatusRequestButton;
