import { gql, useApolloClient, useMutation } from '@apollo/client';
import { Box, Button, Card, Typography } from '@mui/joy';
import { useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../assets/long-logo.svg';
import PublicPageTemplate from '../../components/PublicPageTemplate';
import TextFieldElement from '../../components/TextFieldElement';
import { showMessage } from '../../store/snackbar-slice';
import useUserInviteDetails from './use-user-invite-details';
import { passwordStrength } from 'check-password-strength';
import PasswordFieldElement from '../../components/PasswordFieldElement';

const checkUsernameExistsQuery = gql`
  query CheckUsernameExists($username: String!) {
    checkUsernameExists(username: $username)
  }
`;

const acceptUserInviteMutation = gql`
  mutation AcceptUserInvite($token: String!, $creds: AcceptInviteInput!) {
    acceptUserInvite(token: $token, creds: $creds)
  }
`;

function AcceptUserInvite() {
  const { token } = useParams();
  const [saving, setSaving] = useState(false);
  const [acceptUserInvite] = useMutation(acceptUserInviteMutation);
  const { userInvite, loading } = useUserInviteDetails(token);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  // const onSubmit: SubmitHandler<Inputs> = (data) => console.log(data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<any> = useCallback(
    async (_values) => {
      setSaving(true);
      try {
        const { username, password } = _values;
        await acceptUserInvite({
          variables: {
            token,
            creds: {
              username: username.toLowerCase().trim(),
              password,
            },
          },
        });
        dispatch(
          showMessage({
            _id: 'accept-user-invite',
            severity: 'success',
            message: 'Success',
          }),
        );
        navigate(`/invite/accepted/${token}`);
      } catch (err: any) {
        console.error(err);
        if (err.message.includes('Not Authorized')) {
          dispatch(
            showMessage({
              _id: 'accept-user-invite',
              severity: 'danger',
              message: err.message,
            }),
          );
        } else {
          dispatch(
            showMessage({
              _id: 'accept-user-invite',
              severity: 'danger',
              message: 'There was an error creating your account.',
            }),
          );
        }
      }
      setSaving(false);
    },
    [acceptUserInvite, dispatch, navigate, token],
  );

  const client = useApolloClient();

  return (
    <PublicPageTemplate title="Sign In">
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          minHeight: '100%',
          flexDirection: 'column',
        }}
      >
        <img
          src={logo}
          alt="Mahathey Contracting"
          style={{
            marginTop: 80,
            width: 400,
            maxWidth: '90vw',
            marginBottom: 40,
          }}
        />
        <Card sx={{ width: 600, maxWidth: '90vw', p: 4, mb: 8 }}>
          <Typography level="h1" style={{ textAlign: 'center' }}>
            Welcome!
          </Typography>
          <Typography level="h3" style={{ textAlign: 'center' }}>
            {userInvite?.name}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography level="body-lg" sx={{ textAlign: 'center', mb: 4 }}>
              Please create a new username and password.
            </Typography>
            <TextFieldElement
              control={control}
              name="username"
              label="Username"
              placeholder="someusername"
              caption="Could be your first name, could be your email addresss..."
              rules={{
                required: 'This field is required',
                minLength: 3,
                validate: async function (value: string) {
                  try {
                    const result = await client.query({
                      query: checkUsernameExistsQuery,
                      variables: { username: value },
                      fetchPolicy: 'cache-first',
                    });
                    if (result.data?.checkUsernameExists) {
                      return 'Another user with that username already exists.';
                    }
                    return undefined;
                  } catch (err) {
                    console.error(err);
                  }
                  return undefined;
                },
              }}
              sx={{ mb: 2 }}
            />
            <PasswordFieldElement
              control={control}
              name="password"
              label="Password"
              caption="Must be reasonably long and/or complex"
              rules={{
                required: 'This field is required',
                validate: (value: string) => {
                  const result = passwordStrength(value);
                  if (result.id < 3) {
                    return 'Weak password, try making it longer, including numbers and symbols, or mixing upper and lower case.';
                  }
                  return undefined;
                },
              }}
              sx={{ mb: 2 }}
            />
            <PasswordFieldElement
              control={control}
              name="confirm"
              label="Confirm Password"
              caption="Enter your password again."
              rules={{
                required: 'This field is required',
                validate: (value: string, values: any) => {
                  if (value !== values.password) {
                    return 'Your passwords do not match.';
                  }
                  return undefined;
                },
              }}
            />
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                type="submit"
                loading={loading || saving}
                color="primary"
                size="lg"
                sx={{ width: 200 }}
              >
                Create Account
              </Button>
            </Box>
          </form>
        </Card>
      </Box>
    </PublicPageTemplate>
  );
}

export default AcceptUserInvite;
