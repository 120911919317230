import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import {
  Box,
  Button,
  Chip,
  IconButton,
  Modal,
  ModalClose,
  ModalDialog,
  Tooltip,
  Typography,
} from '@mui/joy';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

export interface AllFilter {
  key: string;
  label: string;
  Component: any;
  finish: (v: any) => any;
  display: (v: any) => any;
  defaultValue: any;
}

interface Props {
  _filters: any;
  onFilterChange: (filters: any) => void;
  allFilters: AllFilter[];
}

function useTableFilters({ _filters, onFilterChange, allFilters }: Props): any {
  const [showFilters, setShowFilters] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm<any>({
    defaultValues: allFilters.reduce((prev: any, curr: any) => {
      prev[curr.key] = curr.defaultValue;
      return prev;
    }, {}),
  });
  const changes = watch(allFilters.map((f) => f.key));
  const _changes = useRef<any>();
  const [needsReset, setNeedsReset] = useState(false);
  const [display, setDisplay] = useState<string[]>([]);
  useEffect(() => {
    if (JSON.stringify(changes) !== JSON.stringify(_changes.current)) {
      let needsReset = false;
      const activeFilters: string[] = [];
      const newFilters: any = {};
      allFilters.forEach((f, i) => {
        const v = changes[i];
        if (v !== 0 && v && v !== '__undefined__') {
          needsReset = true;
          activeFilters.push(f.display(v));
        }
        newFilters[f.key] = f.finish(v);
      });
      setNeedsReset(needsReset);
      setDisplay(activeFilters);
      _changes.current = changes;
      if (JSON.stringify(newFilters) !== JSON.stringify(_filters.current)) {
        onFilterChange(newFilters);
      }
    }
  }, [changes, allFilters, onFilterChange, _filters]);
  return {
    header: needsReset && (
      <Box sx={{ pt: 1, pb: 1, pl: 2, pr: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography level="body-xs" sx={{ mr: 1 }}>
            Filters:
          </Typography>
          {display.map((d) => (
            <Chip key={d} sx={{ mr: 1 }} variant="solid">
              {d}
            </Chip>
          ))}
        </Box>
      </Box>
    ),
    button: (
      <Box sx={{ ml: 2, display: 'flex' }}>
        <Tooltip title="Filter Items">
          <IconButton
            onClick={() => setShowFilters(true)}
            variant="outlined"
            sx={{ mr: 2 }}
          >
            <FilterListIcon />
          </IconButton>
        </Tooltip>
        {needsReset && (
          <Button
            onClick={() => {
              reset();
            }}
            variant="outlined"
            startDecorator={<FilterListOffIcon />}
          >
            Clear Filters
          </Button>
        )}
      </Box>
    ),
    modal: (
      <Modal open={showFilters} onClose={() => setShowFilters(false)}>
        <ModalDialog sx={{ p: 4, width: 400, maxWidth: '90uvw' }}>
          <ModalClose />
          <Typography sx={{ textAlign: 'center' }} level="h3">
            Filters
          </Typography>
          {allFilters.map(({ Component, key }) => (
            <Component key={key} control={control} />
          ))}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={() => setShowFilters(false)}>Done</Button>
          </Box>
        </ModalDialog>
      </Modal>
    ),
  };
}

export default useTableFilters;
