import {
  Box,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
  tabClasses,
} from '@mui/joy';
import { Link, useParams } from 'react-router-dom';
import MainPageTemplate from '../../components/MainPageTemplate';
import InviteUserBtn from '../user-invites/InviteUserBtn';
import UserInvitesTab from '../user-invites/UserInvitesTab';
import UsersTab from './UsersTab';

function AllUsersPage() {
  let { tab } = useParams();

  return (
    <MainPageTemplate title="Users">
      <Box sx={{ pt: 2, pb: 2, pl: 2, pr: 2 }}>
        <Box sx={{ pl: 2, pr: 2 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              pt: 2,
              pb: 2,
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography level="h1">Users</Typography>
            </Box>
            <InviteUserBtn />
          </Box>
        </Box>

        <Tabs
          value={tab}
          sx={{
            bgcolor: 'transparent',
          }}
        >
          <TabList
            tabFlex={1}
            size="sm"
            sx={{
              pl: 0,
              justifyContent: 'left',
              [`&& .${tabClasses.root}`]: {
                fontWeight: '600',
                flex: 'initial',
                color: 'text.tertiary',
                [`&.${tabClasses.selected}`]: {
                  bgcolor: 'transparent',
                  color: 'text.primary',
                  '&::after': {
                    height: '2px',
                    bgcolor: 'primary.500',
                  },
                },
              },
            }}
          >
            <Tab
              sx={{ borderRadius: '6px 6px 0 0' }}
              indicatorInset
              value="users"
              component={Link}
              to="/users/users"
            >
              Users
            </Tab>
            <Tab
              sx={{ borderRadius: '6px 6px 0 0' }}
              indicatorInset
              value="invites"
              component={Link}
              to="/users/invites"
            >
              Invites
            </Tab>
          </TabList>
          <TabPanel value="users">
            <UsersTab />
          </TabPanel>
          <TabPanel value="invites">
            <UserInvitesTab />
          </TabPanel>
        </Tabs>
      </Box>
    </MainPageTemplate>
  );
}

export default AllUsersPage;
