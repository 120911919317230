import { Avatar, Box, Button, Typography } from '@mui/joy';
import useProfile from '../shared/use-profile';
import { Link } from 'react-router-dom';

function ProfileButton() {
  const profile = useProfile();

  return (
    <Button
      variant="plain"
      color="neutral"
      component={Link}
      to="/profile/profile"
    >
      {profile && (
        <>
          <Avatar
            variant="outlined"
            size="sm"
            src={profile.profilePhotoThumbUrl || undefined}
          />
          <Box sx={{ minWidth: 0, flex: 1, pl: 1 }}>
            <Typography style={{ textAlign: 'left' }} level="title-sm">
              {profile.name}
            </Typography>
            <Typography style={{ textAlign: 'left' }} level="body-xs">
              {profile.email}
            </Typography>
          </Box>
        </>
      )}
    </Button>
  );
}

export default ProfileButton;
