import { Box, Link, Typography, TypographySystem } from '@mui/joy';
import { Link as RouterLink } from 'react-router-dom';
import { formatAddress } from '../shared/utils';
import { StreetAddressType } from '../__generated__/graphql';

interface DisplayAddressProps {
  address: StreetAddressType;
  sx?: any;
  level?: keyof TypographySystem | 'inherit' | undefined;
}

export function DisplayAddress({
  address,
  sx,
  level = 'body-xs',
}: DisplayAddressProps) {
  return (
    <Box sx={sx}>
      {formatAddress(address)
        .split('\n')
        .map((line, index) => (
          <div key={index}>
            <Typography level={level}>{line}</Typography>
          </div>
        ))}
    </Box>
  );
}

export interface MyDetailItem {
  key: string;
  label: string;
  value?: string | undefined | null;
  width: number;
  lgWidth?: number | undefined;
  component?: any | undefined | null;
  link?: string | undefined | null;
}

interface GridItemProps {
  item: MyDetailItem;
}

function MyGridItem({ item }: GridItemProps) {
  return (
    <Box>
      <Typography level="title-sm" color="primary" sx={{ fontWeight: 600 }}>
        {item.label}
      </Typography>
      {item.component ? (
        item.component
      ) : (
        <Typography level="body-md">{item.value}</Typography>
      )}
      {item.link && (
        <Link component={RouterLink} to={item.link} sx={{ fontSize: 13 }}>
          View
        </Link>
      )}
    </Box>
  );
}

export default MyGridItem;
