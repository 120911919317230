import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton } from '@mui/joy';
import { useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  AllCustomersQueryQuery,
  CustomerNode,
  SortBy,
  SortByDirection,
} from '../../__generated__/graphql';
import MyTable from '../../components/my-table/MyTable';
import { AllFilter } from '../../components/my-table/TableFilters';
import settings from '../../settings';
import { useManyRemote } from '../../shared/use-many-remote';
import { allCustomersQuery, extractAllCustomers } from './constants';

const dateFormat = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'short',
  timeStyle: 'short',
});

const initialOrder: SortBy = {
  key: 'updatedAt',
  order: SortByDirection.Desc,
};

function CustomersTab() {
  const filters = useRef({});
  const sortBy = useRef<SortBy[]>([initialOrder]);

  const {
    loading,
    data: rows,
    search,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemote<AllCustomersQueryQuery, CustomerNode>({
    query: allCustomersQuery,
    extract: extractAllCustomers,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: 'customers',
  });

  const allFilters: AllFilter[] = useMemo(() => [], []);

  const allColumns = useMemo(
    () => [
      {
        key: 'go',
        title: '',
        component: (row: any) => (
          <IconButton component={Link} to={`/customers/${row._id}/view`}>
            <VisibilityIcon />
          </IconButton>
        ),
        sortable: false,
        width: 60,
      },
      {
        key: 'scName',
        title: 'Name',
        component: (row: any) => row.scName,
        sortable: false,
        width: 100,
      },
      {
        key: 'updatedAt',
        title: 'Update At',
        component: (row: any) => dateFormat.format(new Date(row.updatedAt)),
        sortable: true,
        width: 140,
      },
    ],
    [],
  );

  return (
    <MyTable
      initialOrder={initialOrder}
      rows={rows}
      sortBy={sortBy}
      refetch={refetch}
      filters={filters}
      allFilters={allFilters}
      search={search}
      allColumns={allColumns}
      hasNextPage={!!hasNextPage}
      next={next}
    />
  );
}

export default CustomersTab;
