import { gql, useMutation } from '@apollo/client';
import { Box, Button, Card, Skeleton, Table, Typography } from '@mui/joy';
import { useCallback, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../assets/long-logo.svg';
import PublicPageTemplate from '../../components/PublicPageTemplate';
import { showMessage } from '../../store/snackbar-slice';
import { DisplayLocationSimple } from '../work-orders/DisplayLocation';
import useVendorRequestDetails from './use-vendor-request-details';
import WorkOrderDetailsTable from './WorkOrderDetailsTable';

const acceptVendorRequestMutation = gql`
  mutation AcceptVendorRequest($token: String!) {
    acceptVendorRequest(token: $token)
  }
`;

const dateFormat = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'short',
  timeStyle: 'short',
});

function AcceptVendorRequest() {
  const { token } = useParams();
  const [saving, setSaving] = useState(false);
  const [acceptVendorRequest] = useMutation(acceptVendorRequestMutation);
  const { workOrder, loading } = useVendorRequestDetails(token);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  // const onSubmit: SubmitHandler<Inputs> = (data) => console.log(data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<any> = useCallback(
    async (values) => {
      setSaving(true);
      try {
        await acceptVendorRequest({
          variables: {
            token,
          },
        });
        dispatch(
          showMessage({
            _id: 'accept-vendor-request',
            severity: 'success',
            message: 'Success',
          }),
        );
        navigate(`/service-request/accepted/${token}`);
      } catch (err: any) {
        console.error(err);
        if (err.message.includes('Not Authorized')) {
          dispatch(
            showMessage({
              _id: 'accept-vendor-request',
              severity: 'danger',
              message: err.message,
            }),
          );
        } else {
          dispatch(
            showMessage({
              _id: 'accept-vendor-request',
              severity: 'danger',
              message: 'There was an error accepting your service request.',
            }),
          );
        }
      }
      setSaving(false);
    },
    [acceptVendorRequest, dispatch, navigate, token],
  );

  return (
    <PublicPageTemplate title="Accept Service Request">
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          minHeight: '100%',
          flexDirection: 'column',
        }}
      >
        <img
          src={logo}
          alt="Mahathey Contracting"
          style={{
            marginTop: 80,
            width: 400,
            maxWidth: '90vw',
            marginBottom: 40,
          }}
        />
        <Card sx={{ width: 600, maxWidth: '90vw', p: 4, mb: 8 }}>
          <Typography level="h1" style={{ textAlign: 'center' }}>
            Accept Service Request
          </Typography>
          <Typography level="h3" style={{ textAlign: 'center' }}>
            {workOrder?.vendor?.name}
          </Typography>
          <Typography style={{ textAlign: 'center' }}>
            Click "Accept" below to accept this service request.
          </Typography>
          <WorkOrderDetailsTable workOrder={workOrder} loading={loading} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                type="submit"
                loading={loading || saving}
                color="success"
                size="lg"
                sx={{ width: 200 }}
              >
                Accept
              </Button>
            </Box>
          </form>
        </Card>
      </Box>
    </PublicPageTemplate>
  );
}

export default AcceptVendorRequest;
