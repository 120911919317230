import { useMemo } from 'react';

function useVendorInvoiceObjectId(filters: any) {
  const objectId = useMemo(() => {
    if (filters.workOrderId) {
      return filters.workOrderId;
    }
    if (filters.vendorId) {
      return filters.vendorId;
    }
  }, [filters]);
  return objectId;
}

export default useVendorInvoiceObjectId;
