import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import TextFieldElement from '../TextFieldElement';

interface Inputs {
  search: string;
}

interface Props {
  onSearch: (text: string) => void;
}

function SearchBox({ onSearch }: Props) {
  const last = useRef('');
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      search: last.current,
    },
  });
  const text = watch('search');
  useEffect(() => {
    if (text !== last.current) {
      onSearch(text);
      last.current = text;
    }
  }, [text, onSearch]);
  return (
    <TextFieldElement
      control={control}
      name="search"
      // label={label}
      // caption={caption}
      // rules={rules}
      placeholder="Search ..."
      sx={{ mb: 0 }}
    />
  );
}

export default SearchBox;
