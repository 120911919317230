import { gql, useMutation } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Button, Modal, ModalClose, ModalDialog } from '@mui/joy';
import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { generateUuid } from '../../shared/utils';
import { showMessage } from '../../store/snackbar-slice';
import SomeStatusForm from '../some-status/SomeStatusForm';
import { makeColorNames } from '../some-status/utils';

const createWorkOrderStatusMutation = gql`
  mutation CreateWorkOrderStatus(
    $workOrderStatus: CreateWorkOrderStatusInput!
  ) {
    createWorkOrderStatus(workOrderStatus: $workOrderStatus)
  }
`;

function AddWorkOrderStatusModal() {
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [createWorkOrderStatus] = useMutation(createWorkOrderStatusMutation);
  const [colorPalette, setColorPalette] = useState<string[]>();

  const dispatch = useDispatch();
  const onSubmit: SubmitHandler<any> = useCallback(
    async (values) => {
      setSaving(true);
      try {
        if (!colorPalette) {
          throw new Error('Color palette is undefined');
        }
        await createWorkOrderStatus({
          variables: {
            workOrderStatus: {
              _id: generateUuid(),
              ...values,
              colorPalette,
              colorNames: makeColorNames(
                colorPalette,
                values.color,
                values.textColor,
              ),
              builtIn: false,
              sort: -1,
            },
          },
        });
        dispatch(
          showMessage({
            _id: 'create-work-order-status',
            severity: 'success',
            message: 'Success',
          }),
        );
        setOpen(false);
      } catch (err: any) {
        console.error(err);
        dispatch(
          showMessage({
            _id: 'create-work-order-status',
            severity: 'danger',
            message: 'There was an error saving your status.',
          }),
        );
      }
      setSaving(false);
    },
    [createWorkOrderStatus, dispatch, colorPalette],
  );

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      label: '',
    },
  });

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog sx={{ p: 4, width: 400, maxWidth: '90uvw' }}>
          <ModalClose />
          <SomeStatusForm
            title="Add Service Request Status"
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            control={control}
            saving={saving}
            watch={watch}
            colorPalette={colorPalette}
            setColorPalette={setColorPalette}
            onCancel={() => setOpen(false)}
          />
        </ModalDialog>
      </Modal>
      <Button
        onClick={() => setOpen(true)}
        startDecorator={<AddIcon />}
        sx={{ mr: 1 }}
      >
        Add Status
      </Button>
    </>
  );
}

export default AddWorkOrderStatusModal;
