export function makeColorNames(
  colorPalette: string[],
  color: string,
  textColor: string,
): any {
  return {
    solidBg: color,
    solidActiveBg: colorPalette[5],
    outlinedBorder: colorPalette[4],
    outlinedColor: colorPalette[7],
    outlinedActiveBg: colorPalette[1],
    softColor: colorPalette[8],
    softBg: colorPalette[2],
    softActiveBg: colorPalette[3],
    plainColor: colorPalette[7],
    plainActiveBg: colorPalette[1],
    solidColor: textColor,
  };
}
