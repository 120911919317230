import { gql, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  Tooltip,
  Typography,
} from '@mui/joy';
import React, { ReactElement, useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showMessage } from '../../store/snackbar-slice';
import AddIcon from '@mui/icons-material/Add';
import TextFieldElement from '../../components/TextFieldElement';
import { formatPhoneNumberForStorage, generateUuid } from '../../shared/utils';
import PhoneNumberFieldElement from '../../components/PhoneNumberFieldElement';
import { Maybe } from 'graphql/jsutils/Maybe';

const createVendorMutation = gql`
  mutation CreateVendor($vendor: CreateVendorInput!) {
    createVendor(vendor: $vendor)
  }
`;

interface Props {}

function CreateVendorButton() {
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [createVendor] = useMutation(createVendorMutation);

  const dispatch = useDispatch();
  const onSubmit: SubmitHandler<any> = useCallback(
    async (_values) => {
      setSaving(true);
      const { phone: unformattedPhone, location, ...values } = _values;
      let phone: Maybe<string> = null;
      if (unformattedPhone) {
        phone = formatPhoneNumberForStorage(unformattedPhone);
      }
      let locations: string[] = [];
      if (location) {
        locations = location.split(', ');
      }
      try {
        await createVendor({
          variables: {
            vendor: {
              _id: generateUuid(),
              phone,
              locations,
              ...values,
            },
          },
        });
        setOpen(false);
        dispatch(
          showMessage({
            _id: 'create-vendor',
            severity: 'success',
            message: 'Success',
          }),
        );
      } catch (err: any) {
        console.error(err);
        dispatch(
          showMessage({
            _id: 'create-vendor',
            severity: 'danger',
            message: 'There was an error creating vendor',
          }),
        );
      }
      setSaving(false);
    },
    [createVendor, dispatch],
  );

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      name: '',
    },
  });

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog sx={{ p: 4, width: 500, maxWidth: '90uvw' }}>
          <ModalClose />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography level="h3" sx={{ mb: 2, textAlign: 'center' }}>
              New Vendor
            </Typography>
            <Box
              sx={{
                overflow: 'scroll',
                maxHeight: '80vh',
              }}
            >
              <TextFieldElement
                control={control}
                name="name"
                label="Name"
                rules={{ required: 'This field is required' }}
                placeholder="Vendor Name"
                sx={{ mb: 2 }}
              />
              <PhoneNumberFieldElement
                control={control}
                name="phone"
                label="Phone Number"
                rules={{ required: 'This field is required' }}
                sx={{ mb: 2 }}
              />
              <TextFieldElement
                control={control}
                name="email"
                label="Email"
                placeholder="Email"
                sx={{ mb: 2 }}
                rules={{
                  required: 'This field is required',
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Please enter a valid email',
                  },
                }}
              />
              <TextFieldElement
                control={control}
                name="serviceTypeDesc"
                label="Services"
                placeholder="Handyman, HVAC ..."
                sx={{ mb: 2 }}
                caption={`This is a simple description of the services rendered.  Use "Trades" for filtering.`}
              />
              <TextFieldElement
                control={control}
                name="website"
                label="Website"
                placeholder="Ex: https://www.thebestplumberever.com"
                caption={`Make sure to include the https:// part.`}
                sx={{ mb: 2 }}
              />
              <TextFieldElement
                control={control}
                name="location"
                label="Service Location Description"
                placeholder="Dallas, Waco, I-35 Cooridor..."
                sx={{ mb: 2 }}
                caption={`This is a searchable list of service locations.  Use "Service Area" for filtering.  Separate different locations with a comma.`}
              />
              <Box
                sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}
              >
                <Button
                  variant="outlined"
                  color="neutral"
                  size="lg"
                  onClick={() => setOpen(false)}
                  sx={{ mr: 4, width: 100 }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  loading={saving}
                  variant="solid"
                  size="lg"
                  sx={{ width: 100 }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        </ModalDialog>
      </Modal>
      <Button onClick={() => setOpen(true)} startDecorator={<AddIcon />}>
        New Vendor
      </Button>
    </>
  );
}

export default CreateVendorButton;
