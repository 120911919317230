import {
  Avatar,
  Box,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
  tabClasses,
} from '@mui/joy';
import { Link, useParams } from 'react-router-dom';
import MainPageTemplate from '../../components/MainPageTemplate';
import useProfile from '../../shared/use-profile';
import EditProfileForm from './EditProfileForm';
import SignOutButton from './SignOutButton';
import DisplayProfileCard from './DisplayProfileCard';

function ProfilePage() {
  let { tab } = useParams();
  const profile = useProfile();

  return (
    <MainPageTemplate title="Mahathey Contracting">
      <Box sx={{ pt: 2, pb: 2, pl: 2, pr: 2 }}>
        <Box sx={{ pl: 2, pr: 2 }}>
          <Typography color="primary" fontWeight={500} level="body-md">
            My profile
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              pt: 2,
              pb: 2,
            }}
          >
            <Avatar
              src={profile?.profilePhotoThumbUrl || undefined}
              sx={{ mr: 2, height: 80, width: 80 }}
            />
            <Box>
              <Typography level="h1">
                {profile ? profile.name : 'My Profile'}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Tabs
          value={tab}
          sx={{
            bgcolor: 'transparent',
          }}
        >
          <TabList
            tabFlex={1}
            size="sm"
            sx={{
              pl: 0,
              justifyContent: 'left',
              [`&& .${tabClasses.root}`]: {
                fontWeight: '600',
                flex: 'initial',
                color: 'text.tertiary',
                [`&.${tabClasses.selected}`]: {
                  bgcolor: 'transparent',
                  color: 'text.primary',
                  '&::after': {
                    height: '2px',
                    bgcolor: 'primary.500',
                  },
                },
              },
            }}
          >
            <Tab
              sx={{ borderRadius: '6px 6px 0 0' }}
              indicatorInset
              value="profile"
              component={Link}
              to="/profile/profile"
            >
              Profile
            </Tab>
            <Tab
              sx={{ borderRadius: '6px 6px 0 0' }}
              indicatorInset
              value="sign-out"
              component={Link}
              to="/profile/sign-out"
            >
              Sign Out
            </Tab>
          </TabList>
          <TabPanel value="profile">
            {profile && <DisplayProfileCard profile={profile} />}
          </TabPanel>
          <TabPanel value="sign-out">
            <Box
              sx={{
                display: 'flex',
                p: 4,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <SignOutButton />
            </Box>
          </TabPanel>
        </Tabs>
      </Box>
    </MainPageTemplate>
  );
}

export default ProfilePage;
