import {
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
} from '@mui/joy';
import { Control, Controller } from 'react-hook-form';

interface Props {
  control: Control<any>;
  name: string;
  label?: string;
  caption?: string;
  rules?: any;
  sx?: any;
  loading?: boolean;
}

function CheckboxFieldElement({
  control,
  name,
  label,
  caption,
  rules,
  sx,
  loading,
}: Props) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        const { value, ...fields } = field;
        return (
          <FormControl error={!!error} sx={{ mb: 4, ...sx }}>
            <Checkbox
              {...fields}
              disabled={loading}
              label={label && <FormLabel>{label}</FormLabel>}
              checked={value}
            />
            {(error || caption) && (
              <FormHelperText>{error?.message || caption}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
}

export default CheckboxFieldElement;
