import {
  Box,
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from '@mui/joy';
import SendIcon from '@mui/icons-material/Send';
import { gql, useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showMessage } from '../../store/snackbar-slice';
import { sendVendorRequestEmailMutation } from './constants';
import CheckboxFieldElement from '../../components/CheckboxFieldElement';
import { useForm } from 'react-hook-form';
import TextFieldElement from '../../components/TextFieldElement';

const markVendorRequestRejectedMutation = gql`
  mutation markVendorRequestRejected(
    $workOrderId: ID!
    $sendEmail: Boolean!
    $reason: String
  ) {
    markVendorRequestRejected(
      workOrderId: $workOrderId
      sendEmail: $sendEmail
      reason: $reason
    )
  }
`;

interface Props {
  workOrderId: string;
}

function MarkVendorRequestRejectedBtn({ workOrderId }: Props) {
  const [visible, setVisible] = useState(false);
  const onCancel = () => setVisible(false);
  const [saving, setSaving] = useState(false);
  const [markVendorRequestRejected] = useMutation(
    markVendorRequestRejectedMutation,
  );

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (values: any) => {
      setSaving(true);
      try {
        await markVendorRequestRejected({
          variables: {
            workOrderId,
            ...values,
          },
        });
        dispatch(
          showMessage({
            _id: 'send-vendor-request',
            severity: 'success',
            message: 'Success',
          }),
        );
        setVisible(false);
      } catch (err: any) {
        console.error(err);
        dispatch(
          showMessage({
            _id: 'send-vendor-request',
            severity: 'danger',
            message: 'There was an error saving your vendor request',
          }),
        );
      }
      setSaving(false);
    },
    [markVendorRequestRejected, dispatch, workOrderId],
  );

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<any>({
    defaultValues: { sendEmail: true, reason: undefined },
  });

  return (
    <>
      <Modal open={!!visible} onClose={onCancel}>
        <ModalDialog sx={{ p: 4, width: 500, maxWidth: '90uvw' }}>
          <ModalClose />
          <Typography level="h3" sx={{ mb: 2, textAlign: 'center' }}>
            Mark Declined
          </Typography>
          <Typography sx={{ textAlign: 'center', mb: 1 }}>
            Normally vendors should accept or decline requests themselves by
            following the link in their request email.
            <br />
            <br />
            Are you sure you want to mark this request as declined?
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextFieldElement
              control={control}
              name="reason"
              label="Why did the vendor decline?"
              sx={{ mb: 2 }}
            />
            <CheckboxFieldElement
              control={control}
              name="sendEmail"
              label='Send "Request Declined" confirmation email to vendor'
              loading={saving}
            />
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
              <Button
                type="button"
                variant="outlined"
                color="neutral"
                disabled={saving}
                size="lg"
                onClick={onCancel}
                sx={{ mr: 4, width: 100 }}
              >
                Cancel
              </Button>
              <Button type="submit" loading={saving} variant="solid" size="lg">
                Mark Declined
              </Button>
            </Box>
          </form>
        </ModalDialog>
      </Modal>
      <Button
        onClick={() => setVisible(true)}
        loading={saving}
        variant="solid"
        color="danger"
        sx={{}}
      >
        Mark Declined
      </Button>
    </>
  );
}

export default MarkVendorRequestRejectedBtn;
