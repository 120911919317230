import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, IconButton, Typography } from '@mui/joy';
import { useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  AllLocationsQueryQuery,
  SortBy,
  SortByDirection,
  LocationNode,
} from '../../__generated__/graphql';
import MyTable from '../../components/my-table/MyTable';
import { AllFilter } from '../../components/my-table/TableFilters';
import settings from '../../settings';
import { useManyRemote } from '../../shared/use-many-remote';
import { allLocationsQuery, extractAllLocations } from './constants';
import { formatAddress, formatPhoneNumberForDisplay, notNullOrUndefined } from '../../shared/utils';

const dateFormat = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'short',
  timeStyle: 'short',
});

const moneyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const initialOrder: SortBy = {
  key: 'updatedAt',
  order: SortByDirection.Desc,
};

function LocationsTab() {
  const filters = useRef({});
  const sortBy = useRef<SortBy[]>([initialOrder]);

  const {
    loading,
    data: rows,
    search,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemote<AllLocationsQueryQuery, LocationNode>({
    query: allLocationsQuery,
    extract: extractAllLocations,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: 'locations',
  });

  const allFilters: AllFilter[] = useMemo(() => [], []);

  const allColumns = useMemo(
    () => [
      {
        key: 'go',
        title: '',
        component: (row: any) => (
          <IconButton
            component={Link}
            to={`/locations/view/${row._id}/discussion`}
          >
            <VisibilityIcon />
          </IconButton>
        ),
        sortable: false,
        width: 60,
      },
      {
        key: 'storeId',
        title: 'Store ID',
        component: (row: any) => row.storeId,
        sortable: true,
        width: 100,
      },
      {
        key: 'scName',
        title: 'Name',
        component: (row: any) => row.scName,
        sortable: false,
        width: 200,
      },
      {
        key: 'scContact',
        title: 'Contact',
        component: (row: any) => row.scContact,
        sortable: false,
        width: 200,
      },
      {
        key: 'scPhone',
        title: 'Phone',
        component: (row: any) => row.scPhone && formatPhoneNumberForDisplay(row.scPhone),
        sortable: false,
        width: 200,
      },
      {
        key: 'scAddress',
        title: 'Address',
        component: (row: any) =>
          row.scAddress && (
            <Box>
              {formatAddress(row.scAddress)
                .split('\n')
                .map((line, index) => (
                  <div key={index}>
                    <Typography level="body-xs">{line}</Typography>
                  </div>
                ))}
            </Box>
          ),
        sortable: false,
        width: 240,
      },
      {
        key: 'customerId',
        title: 'Customer',
        component: (row: any) => row.customer?.scName,
        sortable: false,
        width: 100,
      },
      {
        key: 'updatedAt',
        title: 'Updated At',
        component: (row: any) => dateFormat.format(new Date(row.updatedAt)),
        sortable: true,
        width: 140,
      },
    ],
    [],
  );

  return (
    <MyTable
      initialOrder={initialOrder}
      rows={rows}
      sortBy={sortBy}
      refetch={refetch}
      filters={filters}
      allFilters={allFilters}
      search={search}
      allColumns={allColumns}
      hasNextPage={!!hasNextPage}
      next={next}
      loading={loading}
    />
  );
}

export default LocationsTab;
