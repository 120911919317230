import {
  Box,
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from '@mui/joy';
import { useCallback, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { generateUuid } from '../../shared/utils';
import UploadProgressModal from './UploadProgressModal';
import { createDiscussionMutation } from './constants';

const uploadDiscussionAttachmentMutation = gql`
  mutation UploadDiscussionAttachment($attachment: DiscussionAttachmentInput!) {
    uploadDiscussionAttachment(attachment: $attachment)
  }
`;

interface Props {
  discussionId?: string | undefined;
  filters: any;
  refetch: any;
  myId: any;
}

function UploadDiscussionAttachmentButton({
  discussionId,
  filters,
  refetch,
  myId,
}: Props) {
  const [uploaded, setUploaded] = useState<any>();
  const [uploadAttachment] = useMutation(
    uploadDiscussionAttachmentMutation,
  ) as any;
  const [createDiscussion] = useMutation(createDiscussionMutation);

  const handleUploadClick = useCallback((event: any) => {
    const file = event.target.files[0];
    setUploaded(file);
  }, []);

  const onGo = useCallback(
    async ({ file, onProgress, desc }: any) => {
      let _id = discussionId;
      if (!discussionId) {
        _id = generateUuid();
        await createDiscussion({
          variables: {
            discussion: {
              _id,
              ...filters,
            },
          },
        });
      }
      const fileId = generateUuid();
      await uploadAttachment({
        variables: {
          attachment: {
            discussionId: _id,
            fileId: fileId,
            desc,
            filename: `${file.name}`,
            contentType: file.type,
            size: file.size,
            tags: ['Discussion Attachment'],
            file: file,
          },
        },
        context: {
          fetchOptions: {
            onProgress: (percent: number) => onProgress(percent),
          },
        },
      });
      // refetch();
    },
    [uploadAttachment, createDiscussion, filters, discussionId],
  );
  const onDone = useCallback(() => {
    setUploaded(undefined);
  }, []);

  return (
    <>
      <Button component="label" htmlFor={myId} variant="outlined">
        Upload Attachment
        <input
          id={myId}
          accept="*/*"
          type="file"
          onChange={handleUploadClick}
          style={{ display: 'none' }}
        />
      </Button>
      <UploadProgressModal
        formId={discussionId}
        uploaded={uploaded}
        setUploaded={setUploaded}
        onGo={onGo}
        onDone={onDone}
      />
    </>
  );
}

export default UploadDiscussionAttachmentButton;
