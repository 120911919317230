import { Box, Button, Typography } from '@mui/joy';
import { useCallback, useState } from 'react';
import useWorkOrderNumbers from './use-wo-numbers';
import useWorkOrderStatuses, {
  sortWorkOrderStatuses,
} from '../work-order-statuses/use-work-order-statuses';

export const WorkOrderMcStatuses: any = {
  ALL: {
    key: 'ALL',
    label: 'Any',
    color: 'all',
  },
  NEW: {
    key: 'NEW',
    label: 'New',
    color: 'new',
    desc: "Service request has just been added or hasn't yet been assigned to/accepted by a vendor.",
  },
  IN_PROGRESS: {
    key: 'IN_PROGRESS',
    label: 'In Progress',
    color: 'progress',
    desc: 'Service request has been accepted by a vendor and is in progress.',
  },
  UPDATE_REQUIRED: {
    key: 'UPDATE_REQUIRED',
    label: 'Update Required',
    color: 'updateRequired',
    desc: "Service request is in progress, but hasn't received a recent progress update.",
  },
  LUCY_MINERVA: {
    key: 'LUCY_MINERVA',
    label: 'With Lucy/Minerva',
    color: 'lucy',
    desc: 'Waiting for feedback from Lucy or Minerva.',
  },
  PROP_OR_INVOICE: {
    key: 'PROP_OR_INVOICE',
    label: 'Create Proposal/Invoice',
    color: 'createInvoice',
    desc: 'Vendor has fulfilled the service request and sent us an invoice.',
  },
  AWAIT_APPROVAL: {
    key: 'AWAIT_APPROVAL',
    label: 'Awaiting Approval',
    color: 'awaitingApproval',
    desc: 'We have submitted a proposal and are waiting for its approval.',
  },
  INVOICE_SUBMITTED: {
    key: 'INVOICE_SUBMITTED',
    label: 'Invoice Submitted',
    color: 'invoiceSubmitted',
    desc: 'We have submitted an invoice and are waiting for for payment.',
  },
  INVOICE_PAID: {
    key: 'INVOICE_PAID',
    label: 'Invoice Paid',
    color: 'invoicePaid',
    desc: 'Service request is complete and we have received payment.',
  },
  COMPLETE_OTHER: {
    key: 'COMPLETE_OTHER',
    label: 'Complete Other',
    color: 'neutral',
    desc: 'Service request was a duplicate, cancelled or other.',
  },
};

interface Props {
  filters: any;
  refetch: any;
  isChild: boolean;
}

function StatusFilters({ filters, refetch, isChild }: Props) {
  const [selected, setSelected] = useState('ALL');
  const onFilterChange = useCallback(
    (status: any) => {
      setSelected(status);
      if (status === 'ALL') {
        const { status, ...otherFilters } = filters.current;
        filters.current = otherFilters;
      } else {
        filters.current = { ...filters.current, status };
      }
      refetch();
    },
    [refetch, filters],
  );
  const woNumbers: any = useWorkOrderNumbers();
  const woStatuses = useWorkOrderStatuses();
  return (
    <Box sx={{ mb: 1 }}>
      <Typography sx={{ mb: 1 }}>Filter by Status</Typography>
      <Box sx={{ overflow: 'auto', display: 'flex', pb: 1 }}>
        {sortWorkOrderStatuses(woStatuses).map((status: any) => {
          let label;
          if (!isChild && woNumbers?.[status._id]) {
            label = `${status.label} (${woNumbers[status._id]})`;
          } else {
            label = `${status.label}`;
          }
          return (
            <Button
              key={status._id}
              size={isChild ? 'sm' : 'lg'}
              onClick={() => onFilterChange(status._id)}
              color={status.color}
              sx={{ mr: 1, mb: 1, whiteSpace: 'nowrap' }}
              variant={status._id === selected ? 'solid' : 'outlined'}
            >
              {label}
            </Button>
          );
        })}
      </Box>
    </Box>
  );
}

export default StatusFilters;
