import { gql, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from '@mui/joy';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DiscussionNode } from '../../__generated__/graphql';
import { showMessage } from '../../store/snackbar-slice';

const deleteDiscussionMutation = gql`
  mutation DeleteDiscussion($_id: ID!) {
    deleteDiscussion(_id: $_id)
  }
`;

export type DeleteDiscussionModelType =
  | {
      discussion: DiscussionNode;
    }
  | undefined;

interface Props {
  open: DeleteDiscussionModelType;
  setOpen: (d: DeleteDiscussionModelType) => void;
  refetch: any;
}

function DeleteDiscussionModal({ open, setOpen, refetch }: Props) {
  const { discussion } = open || {};
  const [saving, setSaving] = useState(false);
  const [deleteDiscussion] = useMutation(deleteDiscussionMutation);

  const dispatch = useDispatch();
  const onSubmit = useCallback(async () => {
    setSaving(true);
    try {
      await deleteDiscussion({
        variables: {
          _id: discussion?._id,
        },
      });
      // refetch();
      setOpen(undefined);
      dispatch(
        showMessage({
          _id: 'edit-discussion',
          severity: 'success',
          message: 'Success',
        }),
      );
    } catch (err: any) {
      console.error(err);
      dispatch(
        showMessage({
          _id: 'edit-discussion',
          severity: 'danger',
          message: 'There was an error saving your service request',
        }),
      );
    }
    setSaving(false);
  }, [deleteDiscussion, dispatch, discussion, setOpen]);

  return (
    <>
      <Modal
        open={!!open}
        onClose={() => setOpen(undefined)}
        sx={{ zIndex: 10001 }}
      >
        <ModalDialog sx={{ p: 4, pt: 6, width: 400, maxWidth: '90uvw' }}>
          <ModalClose />
          <Typography level="h3" sx={{ mb: 2, textAlign: 'center' }}>
            Delete Comment
          </Typography>
          <Box>
            <Typography level="body-md" sx={{ mb: 1, textAlign: 'center' }}>
              Would you like to delete this comment?
            </Typography>
            {discussion?.text && (
              <Typography
                level="body-md"
                sx={{ mb: 0, textAlign: 'center' }}
                noWrap
              >
                {discussion?.text}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
            <Button
              variant="outlined"
              color="neutral"
              size="lg"
              onClick={() => setOpen(undefined)}
              sx={{ mr: 4, width: 100 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => onSubmit()}
              loading={saving}
              variant="solid"
              size="lg"
              sx={{ width: 100 }}
            >
              Delete
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    </>
  );
}

export default DeleteDiscussionModal;
