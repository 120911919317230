import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Box,
  Button,
  Card,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/joy';
import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { WorkOrderNode } from '../../__generated__/graphql';
import { formatPhoneNumberForDisplay } from '../../shared/utils';
import DeselectVendorInner from './DeselectVendorInner';
import EditSomeWorkOrderLine from './EditSomeWorkOrderLine';
import SelectVendorInner from './SelectVendorInner';
import SendVendorRequestButton from './SendVendorRequestButton';
import SendStatusRequestButton from './SendStatusRequestButton';
import { WorkOrderMcStatuses } from './StatusFilters';
import MarkVendorRequestAcceptedBtn from './MarkVendorRequestAcceptedBtn';
import MarkVendorRequestRejectedBtn from './MarkVendorRequestRejectedBtn';

interface DeleteButtonProps {
  onOpen?: () => void;
}

function DeleteButton({ onOpen }: DeleteButtonProps) {
  return (
    <Tooltip title="Deselect Vendor">
      <IconButton onClick={onOpen}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
}

interface SelectVendorButtonProps {
  onOpen?: any;
}

function SelectVendorButton({ onOpen }: SelectVendorButtonProps) {
  return <Button onClick={onOpen}>Select Vendor</Button>;
}

const dateFormat = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'short',
  timeStyle: 'short',
});

interface Props {
  workOrder: WorkOrderNode | undefined;
  loading: boolean;
}

function VendorBox({ workOrder, loading }: Props) {
  const vendorStatus = useMemo(() => {
    if (
      workOrder?.vendorConfirmation &&
      workOrder.vendorConfirmation.confirmedBy === workOrder.vendorId
    ) {
      if (workOrder.vendorConfirmation.status === 'ACCEPTED') {
        return 'accepted';
      }
      return 'rejected';
    }
    return 'waiting';
  }, [workOrder]);

  return (
    <>
      <Typography sx={{ mb: 1 }} level="h3">
        Vendor
      </Typography>
      {workOrder?.vendor && (
        <Card
          sx={{
            p: 2,
            mr: 'auto',
            ml: 'auto',
            mb: 2,
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ flex: 1 }}>
              <Box sx={{ mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Typography level="h4" sx={{ mr: 2 }}>
                    {workOrder.vendor.name}
                  </Typography>
                  {workOrder.vendor.trades &&
                    workOrder.vendor.trades.length > 0 &&
                    workOrder.vendor.trades.map((trade) => (
                      <Chip key={trade} sx={{ mr: 1 }} variant="soft" size="md">
                        {trade}
                      </Chip>
                    ))}
                  <Typography sx={{ ml: 1 }}>
                    {workOrder.vendor.serviceAreaName}
                  </Typography>
                </Box>
                {workOrder.vendor.phone && (
                  <Typography>
                    {formatPhoneNumberForDisplay(workOrder.vendor.phone)}
                  </Typography>
                )}
                <Typography>{workOrder.vendor.email}</Typography>
              </Box>
              {vendorStatus === 'accepted' && (
                <>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Chip
                      color="success"
                      size="lg"
                      variant="solid"
                      sx={{ fontWeight: 600 }}
                    >
                      Accepted
                    </Chip>
                    <Typography
                      sx={{ ml: 1 }}
                      level="body-sm"
                    >{`Accepted at ${dateFormat.format(new Date(workOrder.vendorConfirmation?.confirmedAt))}`}</Typography>
                  </Box>
                  {[
                    WorkOrderMcStatuses.IN_PROGRESS.key,
                    WorkOrderMcStatuses.UPDATE_REQUIRED.key,
                  ].includes(workOrder.status) && (
                    <>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <SendStatusRequestButton
                          workOrderId={workOrder?._id}
                          title="Request Status Update"
                        />
                        {workOrder.statusUpdateEmail?.sentAt && (
                          <Typography
                            sx={{ ml: 1 }}
                            level="body-sm"
                          >{`Status Update Request sent at ${dateFormat.format(new Date(workOrder.statusUpdateEmail.sentAt))}`}</Typography>
                        )}
                      </Box>
                      {workOrder.lastStatusUpdate?.statusAt && (
                        <Typography
                          sx={{ mt: 1 }}
                          level="body-sm"
                        >{`Last update received at ${dateFormat.format(new Date(workOrder.lastStatusUpdate.statusAt))}`}</Typography>
                      )}
                    </>
                  )}
                </>
              )}
              {vendorStatus === 'rejected' && (
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Chip
                    color="danger"
                    size="lg"
                    variant="solid"
                    sx={{ fontWeight: 600 }}
                  >
                    Rejected
                  </Chip>
                  <Typography
                    sx={{ ml: 1 }}
                    level="body-sm"
                  >{`Rejected at ${dateFormat.format(new Date(workOrder.vendorConfirmation?.confirmedAt))}`}</Typography>
                </Box>
              )}
              {vendorStatus === 'waiting' && (
                <>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Chip
                      color="warning"
                      size="lg"
                      variant="solid"
                      sx={{
                        fontWeight: 600,
                        '--joy-palette-warning-solidBg':
                          'var(--joy-palette-warning-600)',
                      }}
                    >
                      Awaiting Confirmation
                    </Chip>
                  </Box>
                </>
              )}

              {vendorStatus !== 'accepted' && (
                <>
                  {workOrder?.vendorRequestEmail &&
                  workOrder.vendorRequestEmail.sentTo === workOrder.vendorId ? (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <SendVendorRequestButton
                        workOrderId={workOrder?._id}
                        title="Resend Service Request Email"
                      />
                      <Typography
                        sx={{ ml: 1 }}
                        level="body-sm"
                      >{`Service Request Email sent ${dateFormat.format(new Date(workOrder.vendorRequestEmail.sentAt))}`}</Typography>
                    </Box>
                  ) : (
                    <SendVendorRequestButton
                      workOrderId={workOrder?._id}
                      title="Send Service Request Email"
                    />
                  )}
                </>
              )}
              <Box sx={{ mt: 2 }}>
                {vendorStatus !== 'accepted' && (
                  <MarkVendorRequestAcceptedBtn workOrderId={workOrder?._id} />
                )}
                {vendorStatus !== 'rejected' && (
                  <MarkVendorRequestRejectedBtn workOrderId={workOrder?._id} />
                )}
              </Box>
            </Box>

            <Box sx={{ alignSelf: 'flex-start', display: 'flex' }}>
              <Tooltip title="View Vendor">
                <IconButton
                  sx={{ mr: 1 }}
                  component={RouterLink}
                  to={`/vendors/view/${workOrder.vendorId}/discussion`}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
              {workOrder?._id && (
                <EditSomeWorkOrderLine
                  _id={workOrder._id}
                  EditForm={<DeselectVendorInner vendor={workOrder.vendor} />}
                  OpenButton={<DeleteButton />}
                />
              )}
            </Box>
          </Box>
        </Card>
      )}

      <Box>
        <EditSomeWorkOrderLine
          _id={workOrder?._id}
          EditForm={
            <SelectVendorInner
              defaultValues={{
                vendorId: workOrder?.vendorId || '',
                sendVendorRequestEmail: true,
              }}
              workOrder={workOrder}
              title="Select Vendor"
              name="vendorId"
              label="Vendor"
              placeholder="Select a vendor ..."
              rules={{ required: 'This field is required' }}
            />
          }
          OpenButton={
            workOrder && !workOrder.vendorId ? <SelectVendorButton /> : null
          }
        />
      </Box>
      {workOrder && !workOrder.vendorId && <Box sx={{ mb: 4 }} />}
    </>
  );
}

export default VendorBox;
