import { FormControl, FormHelperText, FormLabel, Input } from '@mui/joy';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatAdapter = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatAdapter(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        prefix="$"
      />
    );
  },
);

interface Props {
  control: Control<any>;
  name: string;
  label?: string;
  caption?: string;
  placeholder?: string;
  rules?: any;
  sx?: any;
  loading?: boolean;
}

function NumberFieldElement({
  control,
  name,
  label,
  caption,
  rules,
  placeholder,
  sx,
  loading,
}: Props) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <FormControl error={!!error} sx={{ mb: 4, ...sx }}>
          {label && <FormLabel>{label}</FormLabel>}
          <Input
            placeholder={placeholder}
            {...field}
            disabled={loading}
            slotProps={{
              input: {
                component: NumericFormatAdapter,
              },
            }}
          />
          {(error || caption) && (
            <FormHelperText>{error?.message || caption}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}

export default NumberFieldElement;
