import { gql, useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  DiscussionNode,
  DiscussionUpdatesSubscription,
} from '../../__generated__/graphql';
import {
  deleteDiscussion,
  updateDiscussion,
} from '../../store/discussion-slice';
import useDiscussionObjectId from './use-discussion-obj-id';

const discussionUpdatesSubscription = gql`
  subscription DiscussionUpdates($filters: DiscussionFilters!) {
    discussionUpdates(filters: $filters) {
      _id
      crud
      new {
        _id
        text
        userId
        user {
          _id
          username
          name
          profilePhotoThumbUrl
        }
        attachmentIds
        attachments {
          _id
          filename
          uploaded
          processed
          size
          contentType
          parentId
          thumbnailUrl
          downloadUrl
        }
        workOrderId
        locationId
        customerId
        vendorId
        fromVendorId
        fromVendor {
          _id
          name
          phone
          email
        }
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

function useDiscussionUpdates(filters: any | undefined, queryId = 'default') {
  const objectId = useDiscussionObjectId(filters);
  const dispatch = useDispatch();
  useSubscription<DiscussionUpdatesSubscription>(
    discussionUpdatesSubscription,
    {
      skip: !filters,
      variables: {
        filters,
      },
      fetchPolicy: 'no-cache',
      onSubscriptionData: ({ client, subscriptionData: result }) => {
        try {
          const update = result?.data?.discussionUpdates;
          if (update?.new) {
            switch (update.crud) {
              case 'insert':
                dispatch(
                  updateDiscussion(
                    update.new as DiscussionNode,
                    objectId,
                    queryId,
                  ),
                );
                break;
              case 'update':
                dispatch(
                  updateDiscussion(
                    update.new as DiscussionNode,
                    objectId,
                    queryId,
                  ),
                );
                break;
              case 'delete':
                dispatch(deleteDiscussion(update._id, objectId, queryId));
                break;
              default:
                break;
            }
          }
        } catch (err) {
          console.error(err);
        }
      },
    },
  );
}

export default useDiscussionUpdates;
