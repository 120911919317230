import { gql } from '@apollo/client';
import { AllVendorInvoicesQueryQuery } from '../../__generated__/graphql';

export const VendorInvoiceStatuses: any = {
  NOT_PAID: {
    key: 'NOT_PAID',
    label: 'Not Paid',
    color: 'warning',
  },
  PAID: {
    key: 'PAID',
    label: 'Paid',
    color: 'success',
  },
};

export const allVendorInvoicesQuery = gql`
  query AllVendorInvoicesQuery(
    $first: Int
    $after: String
    $filters: VendorInvoiceFilters
    $sortBy: [SortBy]
  ) {
    allVendorInvoices(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      edges {
        cursor
        node {
          _id
          vendorId
          workOrderId
          status
          paymentLink
          paymentMethod
          usdCents
          notes
          attachmentIds
          attachments {
            _id
            filename
            desc
            uploaded
            processed
            size
            contentType
            parentId
            thumbnailUrl
            downloadUrl
          }
          submittedAt
          dueAt
          paidAt
          createdAt
          updatedAt
          deletedAt
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const extractAllVendorInvoices = (data: AllVendorInvoicesQueryQuery) =>
  data.allVendorInvoices;
