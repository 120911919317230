import {
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
} from '@mui/joy';
import { Control, Controller } from 'react-hook-form';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';

interface Props {
  control: Control<any>;
  name: string;
  label?: string;
  caption?: any;
  placeholder?: string;
  rules?: any;
  sx?: any;
  loading?: boolean;
}

function PasswordFieldElement({
  control,
  name,
  label,
  caption,
  rules,
  sx,
  loading,
}: Props) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <FormControl error={!!error} sx={{ mb: 4, ...sx }}>
          {label && <FormLabel>{label}</FormLabel>}
          <Input
            type={showPassword ? 'text' : 'password'}
            {...field}
            disabled={loading}
            endDecorator={
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            }
          />
          {(error || caption) && (
            <FormHelperText>{error?.message || caption}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}

export default PasswordFieldElement;
