import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Link } from '@mui/joy';
import { SortByDirection } from '../../__generated__/graphql';

interface Props {
  active: boolean;
  _id: string;
  label: string;
  onRequestSort: (_id: string) => void;
  order: SortByDirection;
}

function TableSortableHeadCell({
  active,
  _id,
  label,
  onRequestSort,
  order,
}: Props) {
  return (
    <Link
      underline="none"
      color="neutral"
      textColor={active ? 'primary.plainColor' : undefined}
      component="button"
      onClick={() => onRequestSort(_id)}
      fontWeight="lg"
      startDecorator={
        active ? <ArrowDownwardIcon sx={{ opacity: active ? 1 : 0 }} /> : null
      }
      sx={{
        '& svg': {
          transition: '0.2s',
          transform:
            active && order === SortByDirection.Desc
              ? 'rotate(0deg)'
              : 'rotate(180deg)',
        },
        '&:hover': { '& svg': { opacity: 1 } },
      }}
    >
      {label}
    </Link>
  );
}

export default TableSortableHeadCell;
