import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DiscussionNode } from '../__generated__/graphql';

interface InitialState {
  [key: string]: {
    [key: string]: DiscussionNode[];
  };
}

interface ReadDiscussionsPayload {
  queryId: string;
  objectId: string;
  discussions: DiscussionNode[];
}

interface UpdateDiscussionPayload {
  queryId: string;
  objectId: string;
  discussion: DiscussionNode;
}

interface DeleteDiscussionPayload {
  queryId: string;
  objectId: string;
  _id: string;
}

const initialState: InitialState = {
  default: {},
};

const discussionsSlice = createSlice({
  name: 'discussions',
  initialState,
  reducers: {
    readDiscussions: {
      reducer(state, action: PayloadAction<ReadDiscussionsPayload>) {
        state[action.payload.queryId][action.payload.objectId] = [
          ...action.payload.discussions,
        ].sort((a, b) => a.createdAt.localeCompare(b.createdAt, 'en-US'));
      },
      prepare(
        discussions: DiscussionNode[],
        objectId: string,
        queryId = 'default',
      ) {
        return { payload: { discussions, objectId, queryId } };
      },
    },
    updateDiscussion: {
      reducer(state, action: PayloadAction<UpdateDiscussionPayload>) {
        state[action.payload.queryId] = {
          ...state[action.payload.queryId],
          [action.payload.objectId]: [
            ...state[action.payload.queryId][action.payload.objectId].filter(
              (o: any) => o._id !== action.payload.discussion._id,
            ),
            action.payload.discussion,
          ].sort((a, b) => a.createdAt.localeCompare(b.createdAt, 'en-US')),
        };
      },
      prepare(
        discussion: DiscussionNode,
        objectId: string,
        queryId = 'default',
      ) {
        return { payload: { discussion, objectId, queryId } };
      },
    },
    deleteDiscussion: {
      reducer(state, action: PayloadAction<DeleteDiscussionPayload>) {
        state[action.payload.queryId] = {
          ...state[action.payload.queryId],
          [action.payload.objectId]: [
            ...state[action.payload.queryId][action.payload.objectId].filter(
              (o: any) => o._id !== action.payload._id,
            ),
          ].sort((a, b) => a.createdAt.localeCompare(b.createdAt, 'en-US')),
        };
      },
      prepare(_id: string, objectId: string, queryId = 'default') {
        return { payload: { _id, objectId, queryId } };
      },
    },
  },
});

export const { readDiscussions, updateDiscussion, deleteDiscussion } =
  discussionsSlice.actions;
export default discussionsSlice.reducer;
