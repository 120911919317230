import { Box, Button, Card, Link, Typography } from '@mui/joy';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AllHistoryQueryQuery,
  HistoryNode,
  SortBy,
  SortByDirection,
} from '../../__generated__/graphql';
import { useManyRemote } from '../../shared/use-many-remote';
import { readHistory } from '../../store/history-slice';
import { allHistoryQuery, extractAllHistory } from './constants';
import useHistory from './use-history';
import useHistoryObjectId from './use-history-obj-id';
import useHistoryUpdates from './use-history-updates';
import { Maybe } from 'graphql/jsutils/Maybe';

const initialOrder: SortBy = {
  key: 'changedAt',
  order: SortByDirection.Desc,
};

const dateFormat = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'short',
  timeStyle: 'short',
});

interface Props {
  filters: any;
}

const queryId = 'default';

interface ChangeProps {
  changes: Maybe<string>[];
}

interface ExpandedChangeProps {
  change: string;
}

function ExpandedChange({ change }: ExpandedChangeProps) {
  return (
    <Typography noWrap={false}>
      {change?.split('\n').map((line: any, index2: number) => (
        <React.Fragment key={index2}>
          <Typography>{line}</Typography>
          <br />
        </React.Fragment>
      ))}
    </Typography>
  );
}

interface ChangeProps {
  changes: Maybe<string>[];
}

function HistoryChanges({ changes }: ChangeProps) {
  const [showAll, setShowAll] = useState(false);
  const [needsEllipsis, setNeedsEllipsis] = useState(false);
  const ref = useRef<any>();
  useLayoutEffect(() => {
    if (ref.current) {
      if (ref.current.offsetWidth < ref.current.scrollWidth) {
        setNeedsEllipsis(true);
      }
    }
  }, []);
  return (
    <>
      {changes.map((change: any, index: number) => (
        <React.Fragment key={index}>
          {showAll ? (
            <>{change && <ExpandedChange change={change} />}</>
          ) : (
            <>
              <Typography ref={ref} noWrap={true}>
                {change}
              </Typography>
            </>
          )}
        </React.Fragment>
      ))}
      {showAll ? (
        <Button
          sx={{ alignSelf: 'center' }}
          variant="plain"
          size="sm"
          onClick={() => setShowAll(false)}
        >
          Show Less
        </Button>
      ) : (
        <>
          {needsEllipsis && (
            <Button
              sx={{ alignSelf: 'center' }}
              variant="plain"
              size="sm"
              onClick={() => setShowAll(true)}
            >
              Show All
            </Button>
          )}
        </>
      )}
    </>
  );
}

function ViewHistory({ filters }: Props) {
  const objectId = useHistoryObjectId(filters);
  const sortBy = useRef<SortBy[]>([initialOrder]);

  const {
    loading,
    data: rows,
    search,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemote<AllHistoryQueryQuery, HistoryNode>({
    query: allHistoryQuery,
    extract: extractAllHistory,
    first: 10000,
    filters: filters,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: 'dicussions',
  });
  useHistoryUpdates(filters);

  const dispatch = useDispatch();
  useEffect(() => {
    if (rows) {
      dispatch(readHistory(rows, objectId, queryId));
    }
  }, [rows, objectId, dispatch]);
  const history = useHistory(objectId, queryId);

  return (
    <>
      {history?.map((row: HistoryNode) => (
        <Card
          sx={{
            p: 2,
            mb: 2,
          }}
          key={row._id}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {row.changedByName && <Typography>{row.changedByName}</Typography>}
            <Box sx={{ flex: 1 }} />
            <Typography level="body-sm" sx={{ mr: 1 }}>
              {dateFormat.format(new Date(row.changedAt))}
            </Typography>
          </Box>
          <HistoryChanges changes={row.changes} />
        </Card>
      ))}
      {history?.length === 0 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: 6,
          }}
        >
          <Typography sx={{ textAlign: 'center' }}>No history yet.</Typography>
        </Box>
      )}
    </>
  );
}

export default ViewHistory;
