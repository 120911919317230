import { gql, useQuery, useSubscription } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  WorkOrderStatusNode,
  WorkOrderStatusUpdatesSubscription,
} from '../../__generated__/graphql';
import { updateWoStatusPalette } from '../../store/auth-slice';
import { RootState } from '../../store/root';
import {
  deleteWorkOrderStatus,
  readWorkOrderStatuses,
} from '../../store/work-order-statuses-slice';
import { workOrderStatusesQuery } from './constants';

const workOrderStatusUpdatesSubscription = gql`
  subscription WorkOrderStatusUpdates {
    workOrderStatusUpdates {
      _id
      crud
      new {
        _id
        label
        desc
        color
        textColor
        colorPalette
        colorNames
        builtIn
        sort
      }
    }
  }
`;

const colorPaletteNames = [
  '50',
  '100',
  '200',
  '300',
  '400',
  '500',
  '600',
  '700',
  '800',
  '900',
];

function useWorkOrderStatusUpdates(queryId: string = 'default') {
  const result = useQuery(workOrderStatusesQuery, {
    fetchPolicy: 'network-only',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (result?.data?.workOrderStatuses) {
      dispatch(readWorkOrderStatuses(result.data.workOrderStatuses), queryId);
    }
  }, [dispatch, queryId, result]);

  useSubscription<WorkOrderStatusUpdatesSubscription>(
    workOrderStatusUpdatesSubscription,
    {
      fetchPolicy: 'no-cache',
      onSubscriptionData: ({ client, subscriptionData: result }) => {
        try {
          const update = result?.data?.workOrderStatusUpdates;
          if (update?.new) {
            switch (update.crud) {
              case 'insert':
                dispatch(readWorkOrderStatuses(update.new, queryId));
                break;
              case 'update':
                dispatch(readWorkOrderStatuses(update.new, queryId));
                break;
              case 'delete':
                dispatch(deleteWorkOrderStatus(update._id, queryId));
                break;
              default:
                break;
            }
          }
        } catch (err) {
          console.error(err);
        }
      },
    },
  );

  const workOrderStatuses = useSelector(
    (store: RootState) => store.workOrderStatuses?.[queryId],
  );

  useEffect(() => {
    if (workOrderStatuses) {
      const statuses = Object.values(workOrderStatuses);
      dispatch(
        updateWoStatusPalette({
          woStatusPalette: {
            ...statuses.reduce((prev: any, curr: WorkOrderStatusNode) => {
              prev[curr._id] = {};
              curr.colorPalette.forEach((color, index) => {
                prev[curr._id][colorPaletteNames[index]] = color;
              });
              Object.entries(curr.colorNames).forEach(([name, color]) => {
                prev[curr._id][name] = color;
              });
              return prev;
            }, {}),
          },
        }),
      );
    }
  }, [workOrderStatuses, dispatch]);
}

export default useWorkOrderStatusUpdates;
