import { IconButton, Tooltip } from '@mui/joy';
import EditIcon from '@mui/icons-material/Edit';

export interface OpenButtonProps {
  onOpen?: () => void;
  size?: any;
  title?: string;
  sx?: any;
}

function OpenButton({
  onOpen,
  size = 'md',
  title = 'Edit',
  sx,
}: OpenButtonProps) {
  return (
    <Tooltip title={title}>
      <IconButton onClick={onOpen} size={size} sx={sx}>
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
}

export default OpenButton;
