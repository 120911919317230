import { Control } from 'react-hook-form';
import SelectFieldElement, {
  SelectFieldOption,
} from '../../components/SelectFieldElement';
import useAllRoles from './use-all-roles';
import { useMemo } from 'react';
import { RoleNode } from '../../__generated__/graphql';
import AutocompleteFieldElement from '../../components/AutocompleteFieldElement';

interface Props {
  control: Control<any>;
  name: string;
  label?: string;
  caption?: string;
  placeholder?: string;
  rules?: any;
  sx?: any;
  disabled?: boolean;
}

function SelectRolesFieldElement({
  control,
  name,
  label,
  caption,
  rules,
  placeholder,
  sx,
  disabled,
}: Props) {
  const roles = useAllRoles();

  const roleOptions: SelectFieldOption[] = useMemo(() => {
    if (roles) {
      return [
        ...Object.values(roles).map((role: RoleNode) => ({
          key: role._id,
          label: role.name,
        })),
      ];
    }
    return [];
  }, [roles]);
  return (
    <AutocompleteFieldElement
      control={control}
      name={name}
      options={roleOptions}
      label={label}
      placeholder={placeholder}
      caption={caption}
      rules={rules}
      sx={sx}
      disabled={disabled}
      multiple
    />
  );
}

export default SelectRolesFieldElement;
