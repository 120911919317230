import { gql, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from '@mui/joy';
import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import TextFieldElement from '../../components/TextFieldElement';
import { showMessage } from '../../store/snackbar-slice';

const deletePaymentMethodMutation = gql`
  mutation DeletePaymentMethod($_id: ID!) {
    deletePaymentMethod(_id: $_id)
  }
`;

interface Props {
  visible: any;
  onFinish: () => void;
  onCancel: () => void;
}

function DeletePaymentMethodModal({ visible, onFinish, onCancel }: Props) {
  const { paymentMethod } = visible || {};
  const [saving, setSaving] = useState(false);
  const [deletePaymentMethod] = useMutation(deletePaymentMethodMutation);

  const dispatch = useDispatch();
  const onSubmit: SubmitHandler<any> = useCallback(
    async (values) => {
      setSaving(true);
      try {
        await deletePaymentMethod({
          variables: {
            _id: paymentMethod._id,
          },
        });
        dispatch(
          showMessage({
            _id: 'delete-payment-method',
            severity: 'success',
            message: 'Success',
          }),
        );
        onFinish();
      } catch (err: any) {
        console.error(err);
        dispatch(
          showMessage({
            _id: 'delete-payment-method',
            severity: 'danger',
            message: 'There was an error deleting your payment method',
          }),
        );
      }
      setSaving(false);
    },
    [deletePaymentMethod, paymentMethod, onFinish, dispatch],
  );

  return (
    <>
      <Modal open={!!visible} onClose={onCancel}>
        <ModalDialog sx={{ p: 4, width: 400, maxWidth: '90uvw' }}>
          <ModalClose />
          <Typography level="h3" sx={{ mb: 2, textAlign: 'center' }}>
            Delete Payment Method
          </Typography>
          <Typography sx={{ textAlign: 'center' }}>
            Are you sure you want to delete this payment method?
          </Typography>
          {paymentMethod && (
            <Typography sx={{ textAlign: 'center' }}>
              {paymentMethod.label}
            </Typography>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Button
              variant="outlined"
              color="neutral"
              disabled={saving}
              size="lg"
              onClick={onCancel}
              sx={{ mr: 4, width: 100 }}
            >
              Cancel
            </Button>
            <Button
              onClick={onSubmit}
              loading={saving}
              variant="solid"
              size="lg"
              sx={{ width: 100 }}
            >
              Delete
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    </>
  );
}

export default DeletePaymentMethodModal;
