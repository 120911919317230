import {
  Box,
  Chip,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
  tabClasses,
} from '@mui/joy';
import { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  WorkOrderNode,
  WorkOrderQueryQuery,
} from '../../__generated__/graphql';
import MainPageTemplate from '../../components/MainPageTemplate';
import OpenButton from '../../components/OpenButton';
import useOne from '../../shared/use-one';
import { updateWorkOrder } from '../../store/work-order-slice';
import ViewDiscussion from '../discussion/ViewDiscussion';
import ViewHistory from '../history/ViewHistory';
import EditWorkOrderStatusInner from '../work-order-statuses/EditWoStatusInner';
import useWorkOrderStatuses from '../work-order-statuses/use-work-order-statuses';
import EditWorkOrderTagInner from '../work-order-tags/EditWoTagsInner';
import { extractWorkOrder, workOrderQuery } from './constants';
import EditSomeWorkOrderLine from './EditSomeWorkOrderLine';
import RefreshFromSCButton from './RefreshFromSCButton';
import useWorkOrderUpdates from './use-wo-updates';
import useWorkOrder from './use-work-order';
import VendorBox from './VendorBox';
import VendorInvoiceBox from './VendorInvoiceBox';
import WorkOrderDetailsCard from './WorkOrderDetailsCard';

const queryId = 'default';

function ViewWorkOrder() {
  const { _id, tab } = useParams();
  const { loading } = useOne<WorkOrderQueryQuery>({
    extract: extractWorkOrder,
    fetchPolicy: 'network-only',
    variables: {
      _id,
    },
    query: workOrderQuery,
    queryId,
    updateAction: updateWorkOrder,
  });
  useWorkOrderUpdates(_id, queryId);
  const workOrder = useWorkOrder(_id, queryId);
  const title = useMemo(() => {
    if (workOrder) {
      return (
        <>
          <Typography>{`${workOrder.scId}`}</Typography>
          <Typography level="h1">
            {`${workOrder.location?.scName} (${workOrder.location?.storeId}) | ${workOrder.location?.scAddress?.city}, ${workOrder.location?.scAddress?.state}`}
          </Typography>
        </>
      );
    }
    return <Typography level="h1">Service Request</Typography>;
  }, [workOrder]);
  const woStatuses = useWorkOrderStatuses();

  return (
    <MainPageTemplate title="Service Requests">
      <Box sx={{ pt: 2, pb: 2, pl: 2, pr: 2 }}>
        <Box sx={{ pl: 2, pr: 2 }}>
          <Box sx={{ display: 'flex' }}>
            <Typography
              color="primary"
              fontWeight={500}
              level="body-md"
              component={Link}
              to="/work-orders"
              sx={{ mr: 1, textDecoration: 'none' }}
            >
              Service Requests
            </Typography>
            <Typography
              color="primary"
              fontWeight={500}
              level="body-md"
              sx={{ mr: 1 }}
            >
              /
            </Typography>
            <Typography color="primary" fontWeight={500} level="body-md">
              View Service Request
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              pt: 2,
              pb: 1,
              justifyContent: 'space-between',
            }}
          >
            <Box>{title}</Box>
            {_id && <RefreshFromSCButton workOrderId={_id} />}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Typography sx={{ fontWeight: 600, mr: 2 }}>Status</Typography>
            {workOrder?.status && (
              <Chip
                color={workOrder.status as any}
                variant="solid"
                size="lg"
                sx={{ fontWeight: 600, mr: 2 }}
              >
                {woStatuses[workOrder.status]?.label}
              </Chip>
            )}
            <EditSomeWorkOrderLine
              _id={workOrder?._id}
              EditForm={
                <EditWorkOrderStatusInner
                  defaultValues={{ status: workOrder?.status }}
                  title="Change Status"
                  name="status"
                  label="Status"
                  rules={{ required: 'This field is required' }}
                />
              }
              OpenButton={<OpenButton size="sm" title="Change Status" />}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
            <Typography sx={{ fontWeight: 600, mr: 2 }}>Tags</Typography>
            <Box sx={{ mr: 1 }}>
              {workOrder?.tags && workOrder?.tags.length > 0 ? (
                workOrder.tags.map((tag) => (
                  <Chip
                    key={tag}
                    sx={{ mr: 1, fontWeight: 600 }}
                    variant="solid"
                    size="lg"
                    color="primary"
                  >
                    {tag}
                  </Chip>
                ))
              ) : (
                <Typography level="body-sm">Add a Tag ...</Typography>
              )}
            </Box>
            <EditSomeWorkOrderLine
              _id={workOrder?._id}
              EditForm={
                <EditWorkOrderTagInner
                  defaultValues={{ tags: workOrder?.tags || [] }}
                  title="Edit Tags"
                  name="tags"
                  label="Tags"
                  rules={{ required: 'This field is required' }}
                />
              }
              OpenButton={<OpenButton size="sm" title="Edit Tags" />}
            />
          </Box>
          <WorkOrderDetailsCard workOrder={workOrder} loading={loading} />
          <VendorBox workOrder={workOrder} loading={loading} />
          {workOrder?.vendorId && (
            <VendorInvoiceBox
              workOrder={workOrder as WorkOrderNode & { vendorId: string }}
              loading={loading}
            />
          )}
        </Box>
        <Tabs
          value={tab}
          sx={{
            bgcolor: 'transparent',
          }}
        >
          <TabList
            tabFlex={1}
            size="sm"
            sx={{
              pl: 0,
              justifyContent: 'left',
              [`&& .${tabClasses.root}`]: {
                fontWeight: '600',
                flex: 'initial',
                color: 'text.tertiary',
                [`&.${tabClasses.selected}`]: {
                  bgcolor: 'transparent',
                  color: 'text.primary',
                  '&::after': {
                    height: '2px',
                    bgcolor: 'primary.500',
                  },
                },
              },
            }}
          >
            <Tab
              sx={{ borderRadius: '6px 6px 0 0' }}
              indicatorInset
              value="discussion"
              component={Link}
              to={`/work-orders/view/${_id}/discussion`}
            >
              Discussion
            </Tab>
            <Tab
              sx={{ borderRadius: '6px 6px 0 0' }}
              indicatorInset
              value="history"
              component={Link}
              to={`/work-orders/view/${_id}/history`}
            >
              History
            </Tab>
          </TabList>
          <TabPanel value="discussion">
            <ViewDiscussion filters={{ workOrderId: _id }} />
          </TabPanel>
          <TabPanel value="history">
            <ViewHistory filters={{ workOrderId: _id }} />
          </TabPanel>
        </Tabs>
      </Box>
    </MainPageTemplate>
  );
}

export default ViewWorkOrder;
