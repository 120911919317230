import { gql } from '@apollo/client';

export const workOrderTagsQuery = gql`
  query workOrderTags {
    workOrderTags {
      _id
      label
    }
  }
`;
