import { gql, useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  VendorNode,
  VendorUpdatesSubscription,
} from '../../__generated__/graphql';
import { updateVendor } from '../../store/vendor-slice';

const vendorUpdatesSubscription = gql`
  subscription VendorUpdates($_id: ID!) {
    vendorUpdates(_id: $_id) {
      _id
      crud
      new {
        _id
        name
        phone
        email
        profilePhotoId
        profilePhotoThumbUrl
        serviceTypeDesc
        serviceLocation {
          type
          coordinates
        }
        serviceRadius
        serviceArea {
          type
          coordinates
        }
        serviceAreaName
        address {
          address1
          address2
          city
          state
          county
          country
          zipCode
        }
        locations
        states
        cities
        trades
        website
        notes
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

function useVendorUpdates(_id: string | undefined, queryId = 'default') {
  const dispatch = useDispatch();
  useSubscription<VendorUpdatesSubscription>(vendorUpdatesSubscription, {
    skip: !_id,
    variables: {
      _id,
    },
    fetchPolicy: 'no-cache',
    onSubscriptionData: ({ client, subscriptionData: result }) => {
      try {
        const update = result?.data?.vendorUpdates;
        if (update?.new) {
          switch (update.crud) {
            case 'update':
              dispatch(updateVendor(update.new as VendorNode, queryId));
              break;
            default:
              break;
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
}

export default useVendorUpdates;
