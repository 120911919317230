import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { VendorNode } from '../__generated__/graphql';

interface InitialState {
  [key: string]: {
    [key: string]: VendorNode;
  };
}

interface UpdateVendorPayload {
  queryId: string;
  vendor: VendorNode;
}

interface DeleteVendorPayload {
  queryId: string;
  _id: string;
}

const initialState: InitialState = {
  default: {},
};

const vendorsSlice = createSlice({
  name: 'vendors',
  initialState,
  reducers: {
    updateVendor: {
      reducer(state, action: PayloadAction<UpdateVendorPayload>) {
        state[action.payload.queryId] = {
          ...state[action.payload.queryId],
          [action.payload.vendor._id]: action.payload.vendor,
        };
      },
      prepare(vendor: VendorNode, queryId = 'default') {
        return { payload: { vendor, queryId } };
      },
    },
    deleteVendor: {
      reducer(state, action: PayloadAction<DeleteVendorPayload>) {
        const { [action.payload._id]: removing, ...remaining } =
          state[action.payload.queryId];
        state[action.payload.queryId] = remaining;
      },
      prepare(_id: string, queryId = 'default') {
        return { payload: { _id, queryId } };
      },
    },
  },
});

export const { updateVendor, deleteVendor } = vendorsSlice.actions;
export default vendorsSlice.reducer;
