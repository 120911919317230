import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/root';
import { FetchPolicy, useApolloClient } from '@apollo/client';
import { useEffect, useMemo, useRef, useState } from 'react';
import { incrementRemoteQuery, updateRemoteQuery } from '../store/query-slice';
import { hashCode } from './utils';
import { UnknownAction } from '@reduxjs/toolkit';
import { showMessage } from '../store/snackbar-slice';

interface Params {
  variables: any;
  query: any;
  queryId: string;
  extract: (data: any) => any;
  fetchPolicy: FetchPolicy;
  updateAction: (data: any, queryId?: string) => UnknownAction;
}

function useOne<T>({
  variables,
  query,
  queryId,
  extract,
  fetchPolicy = 'network-only',
  updateAction,
}: Params) {
  const [loading, setLoading] = useState(false);
  const queryCode = useMemo(() => {
    const code = hashCode(JSON.stringify(variables));
    return code;
  }, [variables]);

  const { refetches, after } = useSelector((store: RootState) => {
    return (
      store.queries[queryId]?.[queryCode] || {
        refetches: 0,
        after: undefined,
      }
    );
  }, shallowEqual);

  const apolloClient = useApolloClient();
  const abort = useRef<any>({});
  const last = useRef<string | undefined>();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateRemoteQuery(
        {
          _id: queryCode,
          refetches,
          after: '',
        },
        queryId,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, queryId, queryCode]);
  useEffect(() => {
    async function doAsyncStuff() {
      setLoading(true);
      try {
        const result = await apolloClient.query<T>({
          query,
          variables,
          fetchPolicy,
          context: {
            fetchOptions: {
              signal: abort.current.signal,
            },
          },
        });
        const results = result && extract(result.data);
        if (results) {
          dispatch(updateAction(results, queryId));
        }
      } catch (err: any) {
        console.error(err);
        dispatch(
          showMessage({
            _id: `${hashCode}`,
            severity: 'danger',
            message: err.message,
          }),
        );
      } finally {
        setLoading(false);
      }
    }

    const inputs = JSON.stringify({
      refetches,
      queryCode,
    });
    if (inputs !== last.current) {
      last.current = inputs;
      doAsyncStuff();
    }
  }, [
    apolloClient,
    dispatch,
    extract,
    fetchPolicy,
    query,
    queryCode,
    queryId,
    refetches,
    updateAction,
    variables,
  ]);
  return {
    queryCode,
    loading,
    refetch: () => dispatch(incrementRemoteQuery(queryCode, queryId)),
  };
}

export default useOne;
