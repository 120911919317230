import { gql, useQuery } from '@apollo/client';
import {
  WorkOrderFilters,
  WorkOrderNumbersQuery,
} from '../../__generated__/graphql';

export const workOrderNumbersQuery = gql`
  query WorkOrderNumbers($filters: WorkOrderFilters) {
    workOrderNumbers(filters: $filters)
  }
`;

function useWorkOrderNumbers(filters?: WorkOrderFilters) {
  const result = useQuery<WorkOrderNumbersQuery>(workOrderNumbersQuery, {
    variables: {
      filters,
    },
    fetchPolicy: 'cache-first',
  });

  const { data } = result;

  return data?.workOrderNumbers;
}

export default useWorkOrderNumbers;
