import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../store/root';

function useUser(_id: string | undefined, queryId: string) {
  const user = useSelector((store: RootState) => {
    if (_id) {
      return store.users[queryId]?.[_id];
    }
    return undefined;
  }, shallowEqual);
  return user;
}

export default useUser;
