import { gql, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from '@mui/joy';
import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import TextFieldElement from '../../components/TextFieldElement';
import { showMessage } from '../../store/snackbar-slice';

const updatePaymentMethodMutation = gql`
  mutation EditPaymentMethod($paymentMethod: UpdatePaymentMethodInput!) {
    updatePaymentMethod(paymentMethod: $paymentMethod)
  }
`;

interface Props {
  visible: any;
  onFinish: () => void;
  onCancel: () => void;
}

function EditPaymentMethodModal({ visible, onFinish, onCancel }: Props) {
  const { paymentMethod } = visible || {};
  const [saving, setSaving] = useState(false);
  const [updatePaymentMethod] = useMutation(updatePaymentMethodMutation);

  const dispatch = useDispatch();
  const onSubmit: SubmitHandler<any> = useCallback(
    async (values) => {
      setSaving(true);
      try {
        await updatePaymentMethod({
          variables: {
            paymentMethod: {
              _id: paymentMethod._id,
              ...values,
            },
          },
        });
        dispatch(
          showMessage({
            _id: 'update-payment-method',
            severity: 'success',
            message: 'Success',
          }),
        );
        onFinish();
      } catch (err: any) {
        console.error(err);
        dispatch(
          showMessage({
            _id: 'update-payment-method',
            severity: 'danger',
            message: 'There was an error saving your payment method',
          }),
        );
      }
      setSaving(false);
    },
    [updatePaymentMethod, paymentMethod, onFinish, dispatch],
  );

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
  } = useForm<any>({
    defaultValues: {
      label: '',
    },
  });

  useEffect(() => {
    if (paymentMethod) {
      setValue('label', paymentMethod.label);
    }
  }, [paymentMethod, setValue]);

  return (
    <>
      <Modal open={!!visible} onClose={onCancel}>
        <ModalDialog sx={{ p: 4, width: 400, maxWidth: '90uvw' }}>
          <ModalClose />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography level="h3" sx={{ mb: 2, textAlign: 'center' }}>
              Edit Payment Method
            </Typography>
            <TextFieldElement
              control={control}
              name="label"
              label="Payment Method"
              placeholder="Credit Card... Cash..."
              sx={{ mb: 2 }}
              loading={saving}
            />
            <Box
              sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}
            >
              <Button
                variant="outlined"
                color="neutral"
                disabled={saving}
                size="lg"
                onClick={onCancel}
                sx={{ mr: 4, width: 100 }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                loading={saving}
                variant="solid"
                size="lg"
                sx={{ width: 100 }}
              >
                Save
              </Button>
            </Box>
          </form>
        </ModalDialog>
      </Modal>
    </>
  );
}

export default EditPaymentMethodModal;
