import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../store/root';

function useComment(
  objectId: string | undefined | null,
  commentId: string | undefined | null,
  queryId: string = 'default',
) {
  const discussions = useSelector((store: RootState) => {
    if (objectId && commentId) {
      return store.discussions[queryId]?.[objectId]?.find(
        (c) => c._id === commentId,
      );
    }
    return undefined;
  }, shallowEqual);
  return discussions;
}

export default useComment;
