import { gql, useApolloClient } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import DashboardPage from '../pages/DashboardPage';
import SettingsPage from '../pages/SettingsPage';
import AllCustomersPage from '../pages/customers/AllCustomersPage';
import AllLocationsPage from '../pages/locations/AllLocationsPage';
import PaymentMethodsPage from '../pages/payment-methods/PaymentMethodsPage';
import ProfilePage from '../pages/profile/ProfilePage';
import AllUsersPage from '../pages/users/AllUsersPage';
import AllVendorsPage from '../pages/vendors/AllVendorsPage';
import ViewVendor from '../pages/vendors/ViewVendor';
import WorkOrderTagsPage from '../pages/work-order-tags/WorkOrderTagsPage';
import AllWorkOrdersPage from '../pages/work-orders/AllWorkOrdersPage';
import ViewWorkOrder from '../pages/work-orders/ViewWorkOrder';
import useProfileUpdates from '../shared/use-profile-updates';
import { updateProfile } from '../store/auth-slice';
import { showMessage } from '../store/snackbar-slice';
import ViewLocation from '../pages/locations/ViewLocation';
import ViewUser from '../pages/users/ViewUser';
import WorkOrderStatusesPage from '../pages/work-order-statuses/WorkOrderStatusesPage';
import useWorkOrderStatusUpdates from '../pages/work-order-statuses/use-work-order-status-updates';
import WoVendorStatusesPage from '../pages/wo-vendor-statuses/WoVendorStatusesPage';
import useWoVendorStatusUpdates from '../pages/wo-vendor-statuses/use-wo-vendor-status-updates';

const profileQuery = gql`
  query MyProfile {
    myProfile {
      _id
      username
      name
      roleIds
      email
      emailVerified
      phone
      phoneVerified
      address {
        address1
        address2
        city
        state
        county
        country
        zipCode
      }
      profilePhotoId
      profilePhotoThumbUrl
      createdAt
      updatedAt
    }
  }
`;

function MainRouter() {
  const client = useApolloClient();
  const dispatch = useDispatch();
  useEffect(() => {
    async function doAsyncStuff() {
      try {
        const response = await client.query({
          query: profileQuery,
          fetchPolicy: 'no-cache',
        });
        dispatch(updateProfile({ profile: response?.data?.myProfile }));
      } catch (err) {
        console.error(err);
        dispatch(
          showMessage({
            _id: 'fetch-profile',
            severity: 'danger',
            message: 'There was an error fetching your profile.',
          }),
        );
      }
    }
    doAsyncStuff();
  }, [client, dispatch]);
  useProfileUpdates();
  useWorkOrderStatusUpdates();
  useWoVendorStatusUpdates();
  return (
    <Routes>
      <Route path="/users/:tab" element={<AllUsersPage />} />
      <Route path="/users/view/:_id/:tab" element={<ViewUser />} />
      <Route path="/work-orders" element={<AllWorkOrdersPage />} />
      <Route path="/work-orders/view/:_id/:tab" element={<ViewWorkOrder />} />
      <Route path="/vendors" element={<AllVendorsPage />} />
      <Route path="/vendors/view/:_id/:tab" element={<ViewVendor />} />
      <Route path="/customers" element={<AllCustomersPage />} />
      <Route path="/locations" element={<AllLocationsPage />} />
      <Route path="/locations/view/:_id/:tab" element={<ViewLocation />} />
      <Route path="/settings" element={<SettingsPage />} />
      <Route path="/dashboard" element={<DashboardPage />} />
      <Route path="/profile/:tab" element={<ProfilePage />} />
      <Route path="/payment-methods" element={<PaymentMethodsPage />} />
      <Route path="/work-order-tags" element={<WorkOrderTagsPage />} />
      <Route path="/work-order-statuses" element={<WorkOrderStatusesPage />} />
      <Route path="/wo-vendor-statuses" element={<WoVendorStatusesPage />} />
      <Route path="/sign-in" element={<Navigate to="/work-orders" replace />} />
      <Route path="/" element={<Navigate to="/work-orders" replace />} />
      <Route path="*" element={<DashboardPage />} />
    </Routes>
  );
}

export default MainRouter;
