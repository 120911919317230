import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { HistoryNode } from '../__generated__/graphql';

interface InitialState {
  [key: string]: {
    [key: string]: HistoryNode[];
  };
}

interface ReadHistoryPayload {
  queryId: string;
  objectId: string;
  history: HistoryNode[];
}

interface UpdateHistoryPayload {
  queryId: string;
  objectId: string;
  history: HistoryNode;
}

interface DeleteHistoryPayload {
  queryId: string;
  objectId: string;
  _id: string;
}

const initialState: InitialState = {
  default: {},
};

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    readHistory: {
      reducer(state, action: PayloadAction<ReadHistoryPayload>) {
        state[action.payload.queryId][action.payload.objectId] = [
          ...action.payload.history,
        ].sort((a, b) => b.changedAt.localeCompare(a.changedAt, 'en-US'));
      },
      prepare(history: HistoryNode[], objectId: string, queryId = 'default') {
        return { payload: { history, objectId, queryId } };
      },
    },
    updateHistory: {
      reducer(state, action: PayloadAction<UpdateHistoryPayload>) {
        state[action.payload.queryId] = {
          ...state[action.payload.queryId],
          [action.payload.objectId]: [
            ...state[action.payload.queryId][action.payload.objectId].filter(
              (o: any) => o._id !== action.payload.history._id,
            ),
            action.payload.history,
          ].sort((a, b) => b.changedAt.localeCompare(a.changedAt, 'en-US')),
        };
      },
      prepare(history: HistoryNode, objectId: string, queryId = 'default') {
        return { payload: { history, objectId, queryId } };
      },
    },
    deleteHistory: {
      reducer(state, action: PayloadAction<DeleteHistoryPayload>) {
        state[action.payload.queryId] = {
          ...state[action.payload.queryId],
          [action.payload.objectId]: [
            ...state[action.payload.queryId][action.payload.objectId].filter(
              (o: any) => o._id !== action.payload._id,
            ),
          ].sort((a, b) => b.changedAt.localeCompare(a.changedAt, 'en-US')),
        };
      },
      prepare(_id: string, objectId: string, queryId = 'default') {
        return { payload: { _id, objectId, queryId } };
      },
    },
  },
});

export const { readHistory, updateHistory, deleteHistory } =
  historySlice.actions;
export default historySlice.reducer;
