import { gql, useMutation } from '@apollo/client';
import { Box, Button, Card, Typography } from '@mui/joy';
import { useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../assets/long-logo.svg';
import PublicPageTemplate from '../../components/PublicPageTemplate';
import { showMessage } from '../../store/snackbar-slice';
import WorkOrderDetailsTable from './WorkOrderDetailsTable';
import useVendorRequestDetails from './use-vendor-request-details';
import TextAreaFieldElement from '../../components/TextAreaFieldElement';
import { generateUuid } from '../../shared/utils';

const statusUpdateFromVendorMutation = gql`
  mutation StatusUpdateFromVendor(
    $token: String!
    $update: VendorStatusUpdateInput
  ) {
    statusUpdateFromVendor(token: $token, update: $update)
  }
`;

function VendorStatusUpdate() {
  const { token } = useParams();
  const [saving, setSaving] = useState(false);
  const [statusUpdateFromVendor] = useMutation(statusUpdateFromVendorMutation);
  const { workOrder, loading } = useVendorRequestDetails(token);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  // const onSubmit: SubmitHandler<Inputs> = (data) => console.log(data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<any> = useCallback(
    async (values) => {
      setSaving(true);
      try {
        await statusUpdateFromVendor({
          variables: {
            token,
            update: {
              _id: generateUuid(),
              text: values.text,
            },
          },
        });
        dispatch(
          showMessage({
            _id: 'vendor-status-update',
            severity: 'success',
            message: 'Success',
          }),
        );
        navigate(`/service-request/updated/${token}`);
      } catch (err: any) {
        console.error(err);
        if (err.message.includes('Not Authorized')) {
          dispatch(
            showMessage({
              _id: 'vendor-status-update',
              severity: 'danger',
              message: err.message,
            }),
          );
        } else {
          dispatch(
            showMessage({
              _id: 'vendor-status-update',
              severity: 'danger',
              message: 'There was an error sending your update.',
            }),
          );
        }
      }
      setSaving(false);
    },
    [statusUpdateFromVendor, dispatch, navigate, token],
  );

  return (
    <PublicPageTemplate title="Sign In">
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          minHeight: '100%',
          flexDirection: 'column',
        }}
      >
        <img
          src={logo}
          alt="Mahathey Contracting"
          style={{
            marginTop: 80,
            width: 400,
            maxWidth: '90vw',
            marginBottom: 40,
          }}
        />
        <Card sx={{ width: 600, maxWidth: '90vw', p: 4, mb: 8 }}>
          <Typography level="h1" style={{ textAlign: 'center' }}>
            Service Request Status Update
          </Typography>
          <Typography level="h3" style={{ textAlign: 'center' }}>
            {workOrder?.vendor?.name}
          </Typography>
          <Typography style={{ textAlign: 'center' }}>
            Please provide us a status update on the following request.
          </Typography>
          <WorkOrderDetailsTable workOrder={workOrder} loading={loading} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography sx={{ textAlign: 'center', mb: 1 }}>
              What is the current status of this service request?
            </Typography>
            <TextAreaFieldElement
              control={control}
              name="text"
              placeholder="Should be finished by...  Waiting on...  Taking longer than expected because..."
              rules={{ required: 'This field is required' }}
              minRows={3}
            />
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                type="submit"
                loading={loading || saving}
                color="primary"
                size="lg"
                sx={{ width: 200 }}
              >
                Send Update
              </Button>
            </Box>
          </form>
        </Card>
      </Box>
    </PublicPageTemplate>
  );
}

export default VendorStatusUpdate;
