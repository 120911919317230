import { Box, Button, Typography } from '@mui/joy';

interface Props {
  numRows: number;
  hasMore: boolean;
  loadMore: () => void;
}

function TableLoadMore({ numRows, hasMore, loadMore }: Props) {
  if (!hasMore) {
    return null;
  }
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pt: 2,
        pb: 2,
      }}
    >
      <Typography level="body-xs">{`Displayed items are limited to ${numRows}.`}</Typography>
      <Button
        size="sm"
        onClick={loadMore}
        variant="outlined"
        color="neutral"
        sx={{ ml: 2 }}
      >
        Load More ...
      </Button>
    </Box>
  );
}

export default TableLoadMore;
