import { gql, useMutation } from '@apollo/client';
import { Modal, ModalClose, ModalDialog } from '@mui/joy';
import React, { ReactElement, useCallback, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showMessage } from '../../store/snackbar-slice';

const updateUserMutation = gql`
  mutation UpdateUser($user: UpdateUserInput!) {
    updateUser(user: $user)
  }
`;

interface Props {
  _id: string;
  EditForm: ReactElement;
  OpenButton: ReactElement;
  onSaveCallback?: any;
}

function EditSomeUserLine({
  _id,
  EditForm,
  OpenButton,
  onSaveCallback,
}: Props) {
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [updateUser] = useMutation(updateUserMutation);

  const dispatch = useDispatch();
  const onSubmit: SubmitHandler<any> = useCallback(
    async (_values) => {
      setSaving(true);
      try {
        let values = _values;
        if (onSaveCallback) {
          values = onSaveCallback(_values);
        }
        await updateUser({
          variables: {
            user: {
              _id,
              ...values,
            },
          },
        });
        setOpen(false);
        dispatch(
          showMessage({
            _id: 'edit-user',
            severity: 'success',
            message: 'Success',
          }),
        );
      } catch (err: any) {
        console.error(err);
        dispatch(
          showMessage({
            _id: 'edit-user',
            severity: 'danger',
            message: 'There was an error saving your user details',
          }),
        );
      }
      setSaving(false);
    },
    [updateUser, dispatch, _id, onSaveCallback],
  );

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog sx={{ p: 4, width: 600, maxWidth: '90uvw' }}>
          <ModalClose />
          {React.cloneElement(EditForm, {
            saving,
            onCancel: () => setOpen(false),
            onSubmit,
          })}
        </ModalDialog>
      </Modal>
      {React.cloneElement(OpenButton, {
        onOpen: () => setOpen(true),
      })}
    </>
  );
}

export default EditSomeUserLine;
