import { gql, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from '@mui/joy';
import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import TextFieldElement from '../../components/TextFieldElement';
import { generateUuid } from '../../shared/utils';
import { showMessage } from '../../store/snackbar-slice';

const createWorkOrderTagMutation = gql`
  mutation CreateWorkOrderTag($workOrderTag: CreateWorkOrderTagInput!) {
    createWorkOrderTag(workOrderTag: $workOrderTag)
  }
`;

interface Props {
  refetch: () => void;
}

function AddWorkOrderTagModal({ refetch }: Props) {
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [createWorkOrderTag] = useMutation(createWorkOrderTagMutation);

  const dispatch = useDispatch();
  const onSubmit: SubmitHandler<any> = useCallback(
    async (values) => {
      setSaving(true);
      try {
        await createWorkOrderTag({
          variables: {
            workOrderTag: {
              _id: generateUuid(),
              ...values,
            },
          },
        });
        dispatch(
          showMessage({
            _id: 'create-work-order-tag',
            severity: 'success',
            message: 'Success',
          }),
        );
        refetch();
        setOpen(false);
      } catch (err: any) {
        console.error(err);
        dispatch(
          showMessage({
            _id: 'create-work-order-tag',
            severity: 'danger',
            message: 'There was an error saving your tag.',
          }),
        );
      }
      setSaving(false);
    },
    [createWorkOrderTag, dispatch, refetch],
  );

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      label: '',
    },
  });

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog sx={{ p: 4, width: 400, maxWidth: '90uvw' }}>
          <ModalClose />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography level="h3" sx={{ mb: 2, textAlign: 'center' }}>
              Add Tag
            </Typography>
            <TextFieldElement
              control={control}
              name="label"
              label="Tag"
              placeholder="With Tony... Special..."
              sx={{ mb: 2 }}
              loading={saving}
            />
            <Box
              sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}
            >
              <Button
                variant="outlined"
                color="neutral"
                disabled={saving}
                size="lg"
                onClick={() => setOpen(false)}
                sx={{ mr: 4, width: 100 }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                loading={saving}
                variant="solid"
                size="lg"
                sx={{ width: 100 }}
              >
                Save
              </Button>
            </Box>
          </form>
        </ModalDialog>
      </Modal>
      <Button onClick={() => setOpen(true)}>Add Tag</Button>
    </>
  );
}

export default AddWorkOrderTagModal;
