import { Box, Typography } from '@mui/joy';
import {
  AllVendorInvoicesQueryQuery,
  SortBy,
  SortByDirection,
  VendorInvoiceNode,
  WorkOrderNode,
} from '../../__generated__/graphql';
import AddVendorInvoiceModal from '../vendor-invoices/AddVendorInvoiceModal';
import useVendorInvoiceObjectId from '../vendor-invoices/use-vendor-invoice-obj-id';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useManyRemote } from '../../shared/use-many-remote';
import {
  allVendorInvoicesQuery,
  extractAllVendorInvoices,
} from '../vendor-invoices/constants';
import useVendorInvoiceUpdates from '../vendor-invoices/use-vendor-invoice-updates';
import { useDispatch } from 'react-redux';
import { readVendorInvoices } from '../../store/vendor-invoice-slice';
import useVendorInvoices from '../vendor-invoices/use-vendor-invoices';
import VendorInvoiceCard from '../vendor-invoices/VendorInvoiceCard';

const initialOrder: SortBy = {
  key: 'createdAt',
  order: SortByDirection.Asc,
};

const dateFormat = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'short',
  timeStyle: 'short',
});

const queryId = 'default';

interface Props {
  workOrder: WorkOrderNode & { vendorId: string };
  loading: boolean;
}

function VendorInvoiceBox({ workOrder }: Props) {
  const filters = useMemo(
    () => ({
      workOrderId: workOrder._id,
    }),
    [workOrder],
  );
  const objectId = useVendorInvoiceObjectId(filters);
  const sortBy = useRef<SortBy[]>([initialOrder]);
  const [editing, setEditing] = useState<any>(undefined);
  const [deleting, setDeleting] = useState<any>(undefined);

  const {
    loading,
    data: rows,
    search,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemote<AllVendorInvoicesQueryQuery, VendorInvoiceNode>({
    query: allVendorInvoicesQuery,
    extract: extractAllVendorInvoices,
    first: 10000,
    filters: filters,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });
  useVendorInvoiceUpdates(filters);

  const dispatch = useDispatch();
  useEffect(() => {
    if (rows) {
      dispatch(readVendorInvoices(rows, objectId, queryId));
    }
  }, [rows, objectId, dispatch]);
  const vendorInvoices = useVendorInvoices(objectId, queryId);

  return (
    <>
      <Typography sx={{ mb: 1 }} level="h3">
        Vendor Invoice
      </Typography>
      {vendorInvoices?.map((vendorInvoice: VendorInvoiceNode) => (
        <VendorInvoiceCard vendorInvoice={vendorInvoice} />
      ))}
      <Box sx={{ mb: 4 }}>
        <AddVendorInvoiceModal
          vendorId={workOrder?.vendorId}
          workOrderId={workOrder?._id}
          btnText={
            vendorInvoices?.length > 0
              ? 'Add Another Vendor Invoice'
              : 'Add Vendor Invoice'
          }
          btnColor={vendorInvoices?.length > 0 ? 'neutral' : 'primary'}
          btnVariant={vendorInvoices?.length > 0 ? 'outlined' : 'solid'}
        />
      </Box>
    </>
  );
}

export default VendorInvoiceBox;
