import {
  Box,
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from '@mui/joy';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CircularProgressWithLabel from '../../components/CircularProgressWithLabel';
import { showMessage } from '../../store/snackbar-slice';
import TextAreaFieldElement from '../../components/TextAreaFieldElement';
import { SubmitHandler, useForm } from 'react-hook-form';

const byteValueNumberFormatter = Intl.NumberFormat('en-US', {
  notation: 'compact',
  style: 'unit',
  unit: 'byte',
  unitDisplay: 'narrow',
});

interface Props {
  uploaded: File;
  setUploaded: (x: string | undefined) => void;
  onGo: any;
  onDone: any;
  formId: string | undefined;
}

function UploadProgressModal({
  uploaded,
  setUploaded,
  onGo,
  onDone,
  formId,
}: Props) {
  const [saving, setSaving] = useState<any>();
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    setSaving(false);
  }, [uploaded]);

  const dispatch = useDispatch();
  const onSubmit: SubmitHandler<any> = useCallback(
    async (values) => {
      setSaving(true);
      setProgress(0);
      try {
        await onGo({
          desc: values.desc,
          file: uploaded,
          onProgress: (p: number) => setProgress(p),
        });

        dispatch(
          showMessage({
            _id: 'crop-image',
            severity: 'success',
            message: 'Successfully uploaded your image',
          }),
        );
        onDone();
      } catch (err) {
        console.error(err);
        dispatch(
          showMessage({
            _id: 'crop-image',
            severity: 'danger',
            message: 'There was an error saving your image',
          }),
        );
        setSaving(false);
      }
    },
    [onDone, dispatch, onGo, uploaded],
  );

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<any>({
    defaultValues: { desc: '' },
  });

  return (
    <Modal
      open={!!uploaded}
      onClose={() => setUploaded(undefined)}
      sx={{ zIndex: 10001 }}
    >
      <ModalDialog sx={{ p: 3, pt: 5, width: 500, maxWidth: '90uvw' }}>
        <ModalClose />
        <Typography level="h3" sx={{ mb: 1, textAlign: 'center' }}>
          Upload Attachment
        </Typography>
        <Box>
          {uploaded?.name && (
            <Box sx={{ mb: 2 }}>
              <Typography
                textAlign="center"
                color="primary"
                level="body-md"
                sx={{ fontWeight: 600 }}
              >
                Filename
              </Typography>
              <Typography textAlign="center">{uploaded?.name}</Typography>
            </Box>
          )}

          {uploaded?.size && (
            <Box>
              <Typography
                textAlign="center"
                color="primary"
                level="body-md"
                sx={{ fontWeight: 600 }}
              >
                Size
              </Typography>
              <Typography textAlign="center">
                {byteValueNumberFormatter.format(uploaded.size)}
              </Typography>
            </Box>
          )}
        </Box>
        {saving ? (
          <div>
            <CircularProgressWithLabel value={progress} size={80} />
          </div>
        ) : (
          <form id={formId} onSubmit={handleSubmit(onSubmit)}>
            <TextAreaFieldElement
              control={control}
              label="Add a file description"
              name="desc"
              placeholder="Red, metalic, weighs roughly 400 grams ..."
              minRows={3}
            />
            <Box
              sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}
            >
              <Button
                variant="outlined"
                color="neutral"
                size="lg"
                onClick={() => setUploaded(undefined)}
                sx={{ mr: 4, width: 100 }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                loading={saving}
                variant="solid"
                size="lg"
                sx={{ width: 100 }}
              >
                Upload
              </Button>
            </Box>
          </form>
        )}
      </ModalDialog>
    </Modal>
  );
}

export default UploadProgressModal;
