import { Box, Typography } from '@mui/joy';
import { VendorNode } from '../../__generated__/graphql';
import { formatPhoneNumberForDisplay } from '../../shared/utils';

interface Props {
  vendor: VendorNode;
}

export function DisplayVendorSimple({ vendor }: Props) {
  return (
    <Box sx={{ flex: 1 }}>
      <Typography>{vendor.name}</Typography>
      {vendor.phone && (
        <Typography>{formatPhoneNumberForDisplay(vendor.phone)}</Typography>
      )}
      <Typography level="body-xs">{vendor.serviceTypeDesc}</Typography>
      <Typography level="body-xs">{vendor.serviceAreaName}</Typography>
    </Box>
  );
}
