import { Box, Typography } from '@mui/joy';
import { formatAddress, formatPhoneNumberForDisplay } from '../../shared/utils';
import { LocationNode } from '../../__generated__/graphql';

interface Props {
  location: LocationNode;
  isTooltip?: boolean;
}

export function DisplayLocationSimple({ location, isTooltip = false }: Props) {
  return (
    <Box>
      {location.storeId ? (
        <Typography>{`${location.scName} (${location.storeId})`}</Typography>
      ) : (
        <Typography>{`${location.scName}`}</Typography>
      )}
      {formatAddress(location.scAddress)
        .split('\n')
        .map((line, index) => (
          <Box key={index}>
            <Typography
              level="body-xs"
              sx={{ color: isTooltip ? 'white' : undefined }}
            >
              {line}
            </Typography>
          </Box>
        ))}
        {location.scContact && <Box>
          <Typography level="body-xs">{location.scContact}</Typography>
        </Box>}
        {location.scPhone && <Box>
          <Typography level="body-xs">{formatPhoneNumberForDisplay(location.scPhone)}</Typography>
        </Box>}
    </Box>
  );
}
