import VisibilityIcon from '@mui/icons-material/Visibility';
import { Avatar, Box, IconButton, Typography } from '@mui/joy';
import { useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  AllVendorsQueryQuery,
  SortBy,
  SortByDirection,
  VendorNode,
} from '../../__generated__/graphql';
import SelectFieldElement from '../../components/SelectFieldElement';
import MyTable from '../../components/my-table/MyTable';
import { AllFilter } from '../../components/my-table/TableFilters';
import settings from '../../settings';
import { useManyRemote } from '../../shared/use-many-remote';
import { formatPhoneNumberForDisplay } from '../../shared/utils';
import { allVendorsQuery, extractAllVendors } from './constants';
import useVendorFilterOptions from './use-vendor-filter-options';

const initialOrder: SortBy = {
  key: 'scCallDate',
  order: SortByDirection.Desc,
};

function VendorsTab() {
  const filters = useRef({});
  const sortBy = useRef<SortBy[]>([initialOrder]);

  const {
    loading,
    data: rows,
    search,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemote<AllVendorsQueryQuery, VendorNode>({
    query: allVendorsQuery,
    extract: extractAllVendors,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: 'vendors',
  });
  const { locationOptions, tradeOptions } = useVendorFilterOptions();

  const allFilters: AllFilter[] = useMemo(
    () => [
      {
        key: 'trade',
        label: 'Trade',
        Component: ({ control }: any) => (
          <SelectFieldElement
            control={control}
            name="trade"
            options={tradeOptions}
            label="Filter by trade"
            placeholder="Select a trade"
          />
        ),
        finish: (v) => (v === '__undefined__' ? null : v),
        display: (v) => `Trade (${v})`,
        defaultValue: null,
      },
      {
        key: 'location',
        label: 'Location',
        Component: ({ control }: any) => (
          <SelectFieldElement
            control={control}
            name="location"
            options={locationOptions}
            label="Filter by location"
            placeholder="Select a location"
          />
        ),
        finish: (v) => (v === '__undefined__' ? null : v),
        display: (v) => `Location (${v})`,
        defaultValue: null,
      },
    ],
    [locationOptions, tradeOptions],
  );

  const allColumns = useMemo(
    () => [
      {
        key: 'go',
        title: '',
        component: (row: any) => (
          <IconButton
            component={Link}
            to={`/vendors/view/${row._id}/discussion`}
          >
            <VisibilityIcon />
          </IconButton>
        ),
        sortable: false,
        width: 60,
      },
      {
        key: 'name',
        title: 'Name',
        component: (row: any) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar src={row.profilePhotoThumbUrl || undefined} sx={{ mr: 1 }}>
              {row.name[0]}
            </Avatar>
            <Typography>{row.name}</Typography>
          </Box>
        ),
        sortable: true,
        width: 240,
      },
      {
        key: 'phone',
        title: 'Phone Number',
        component: (row: any) =>
          row.phone && formatPhoneNumberForDisplay(row.phone),
        sortable: false,
        width: 160,
      },
      {
        key: 'email',
        title: 'Email',
        component: (row: any) => <Typography noWrap>{row.email}</Typography>,
        sortable: true,
        width: 240,
      },
      {
        key: 'serviceTypeDesc',
        title: 'Services',
        component: (row: any) => row.serviceTypeDesc,
        sortable: false,
        width: 140,
      },
      {
        key: 'locations',
        title: 'Service Locations',
        component: (row: any) => row.locations?.join(', '),
        sortable: false,
        width: 180,
      },
    ],
    [],
  );

  return (
    <MyTable
      initialOrder={initialOrder}
      rows={rows}
      sortBy={sortBy}
      refetch={refetch}
      filters={filters}
      allFilters={allFilters}
      search={search}
      allColumns={allColumns}
      hasNextPage={!!hasNextPage}
      next={next}
      loading={loading}
      hasFullText={true}
    />
  );
}

export default VendorsTab;
