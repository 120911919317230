import { Box, Button, Typography } from '@mui/joy';
import { useForm } from 'react-hook-form';
import TextFieldElement from '../TextFieldElement';
import NumberFieldElement from '../NumberFieldElement';

interface Props {
  defaultValues: any;
  title: string;
  name: string;
  label: string;
  caption?: string;
  placeholder?: string;
  rules?: any;
  onSubmit?: any;
  saving?: boolean;
  onCancel?: () => void;
}

function EditMoneyInner({
  defaultValues,
  onSubmit,
  name,
  label,
  caption,
  placeholder,
  rules,
  saving,
  onCancel,
  title,
}: Props) {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<any>({
    defaultValues,
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography level="h3" sx={{ mb: 2, textAlign: 'center' }}>
        {title}
      </Typography>
      <NumberFieldElement
        control={control}
        name={name}
        label={label}
        caption={caption}
        rules={rules}
        placeholder={placeholder}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
        <Button
          variant="outlined"
          color="neutral"
          size="lg"
          onClick={onCancel}
          sx={{ mr: 4, width: 100 }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          loading={saving}
          variant="solid"
          size="lg"
          sx={{ width: 100 }}
        >
          Save
        </Button>
      </Box>
    </form>
  );
}

export default EditMoneyInner;
