import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showMessage } from '../../store/snackbar-slice';
import { Maybe } from 'graphql/jsutils/Maybe';

const vendorRequestDetailsQuery = gql`
  query VendorRequestDetails($token: String!) {
    vendorRequestDetails(token: $token) {
      _id
      scId
      scNumber
      locationId
      location {
        _id
        scName
        scAddress {
          address1
          address2
          city
          state
          county
          country
          zipCode
        }
        scContact
        scPhone
      }
      vendorId
      vendor {
        _id
        name
        phone
        email
      }
      vendorRequestEmail {
        sentAt
        sentTo
      }
      vendorConfirmation {
        status
        confirmedAt
        confirmedBy
      }
      scCaller
      scCreatedBy
      scPriority
      scTrade
      scCallDate
      scScheduledDate
      scExpirationDate
      scDesc
      scCategory
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

function useVendorRequestDetails(token: Maybe<string>) {
  const { data, loading, error } = useQuery(vendorRequestDetailsQuery, {
    fetchPolicy: 'cache-first',
    skip: !token,
    variables: {
      token,
    },
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (error) {
      console.error(error.message);
      dispatch(
        showMessage({
          _id: 'vendor-request-details',
          severity: 'danger',
          message: 'There was an fetching your service request.',
        }),
      );
    }
  }, [error, dispatch]);
  return {
    workOrder: data?.vendorRequestDetails,
    loading,
  };
}

export default useVendorRequestDetails;
