import { MutableRefObject, useCallback, useRef, useState } from 'react';

type SafeState<T> = [
  data: T,
  setData: (t: T) => void,
  _data: MutableRefObject<T>,
];

function useSafeState<T>(initial: T): SafeState<T> {
  const _data = useRef<T>(initial);
  const [data, _setData] = useState<T>(_data.current);
  const setData = useCallback((value: T) => {
    _data.current = value;
    _setData(value);
  }, []);
  return [data, setData, _data];
}

export default useSafeState;
