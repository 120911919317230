import { gql } from '@apollo/client';
import { AllDiscussionsQueryQuery } from '../../__generated__/graphql';

export const allDiscussionsQuery = gql`
  query AllDiscussionsQuery(
    $first: Int
    $after: String
    $filters: DiscussionFilters
    $sortBy: [SortBy]
  ) {
    allDiscussions(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      edges {
        cursor
        node {
          _id
          text
          userId
          user {
            _id
            username
            name
            profilePhotoThumbUrl
          }
          attachmentIds
          attachments {
            _id
            filename
            desc
            uploaded
            processed
            size
            contentType
            parentId
            thumbnailUrl
            downloadUrl
          }
          workOrderId
          locationId
          customerId
          vendorId
          fromVendorId
          fromVendor {
            _id
            name
            phone
            email
          }
          createdAt
          updatedAt
          deletedAt
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const extractAllDiscussions = (data: AllDiscussionsQueryQuery) =>
  data.allDiscussions;

export const createDiscussionMutation = gql`
  mutation CreateDiscussion($discussion: CreateDiscussionInput!) {
    createDiscussion(discussion: $discussion)
  }
`;
