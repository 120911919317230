import {
  Avatar,
  Box,
  Chip,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
  tabClasses,
} from '@mui/joy';
import { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { VendorQueryQuery } from '../../__generated__/graphql';
import EditTextInner from '../../components/edit-inner/EditTextInner';
import MainPageTemplate from '../../components/MainPageTemplate';
import OpenButton from '../../components/OpenButton';
import useOne from '../../shared/use-one';
import { updateVendor } from '../../store/vendor-slice';
import ViewDiscussion from '../discussion/ViewDiscussion';
import ViewHistory from '../history/ViewHistory';
import MondayWorkOrdersAll from '../work-orders/MondayWorkOrdersAll';
import { extractVendor, vendorQuery } from './constants';
import EditSomeVendorLine from './EditSomeVendorLine';
import EditTradesInner from './EditTradesInner';
import UploadVendorPhotoButton from './UploadVendorPhotoButton';
import useVendor from './use-vendor';
import useVendorUpdates from './use-vendor-updates';
import VendorDetailsCard from './VendorDetailsCard';

const queryId = 'default';

function ViewVendor() {
  const { _id, tab } = useParams();
  const { loading } = useOne<VendorQueryQuery>({
    extract: extractVendor,
    fetchPolicy: 'network-only',
    variables: {
      _id,
    },
    query: vendorQuery,
    queryId,
    updateAction: updateVendor,
  });
  useVendorUpdates(_id, queryId);
  const vendor = useVendor(_id, queryId);
  const title = useMemo(() => {
    if (vendor) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flex: 1,
          }}
        >
          <Avatar
            src={vendor.profilePhotoThumbUrl || undefined}
            sx={{ mr: 2, height: 80, width: 80 }}
          >
            {vendor.name[0]}
          </Avatar>
          <Box sx={{ flex: 1 }}>
            <Box sx={{ display: 'flex', mb: 1 }}>
              <Typography level="h1" sx={{ flex: 1 }}>
                {vendor.name}
              </Typography>
              <UploadVendorPhotoButton vendorId={vendor._id} />
              <Box sx={{ mr: 1 }} />
              <EditSomeVendorLine
                _id={vendor._id}
                EditForm={
                  <EditTextInner
                    defaultValues={{ name: vendor.name || '' }}
                    title="Change Vendor Name"
                    name="name"
                    label="Name"
                    rules={{ required: 'This field is required' }}
                  />
                }
                OpenButton={<OpenButton title="Change Vendor Name" />}
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ mr: 1 }}>
                {vendor.trades && vendor.trades.length > 0 ? (
                  vendor.trades.map((trade) => (
                    <Chip key={trade} sx={{ mr: 1 }} variant="soft" size="md">
                      {trade}
                    </Chip>
                  ))
                ) : (
                  <Typography level="body-sm">Add a trade ...</Typography>
                )}
              </Box>
              <EditSomeVendorLine
                _id={vendor._id}
                EditForm={
                  <EditTradesInner
                    defaultValues={{ trades: vendor.trades || [] }}
                    title="Edit Trades"
                    name="trades"
                    label="Trades"
                    rules={{ required: 'This field is required' }}
                  />
                }
                OpenButton={<OpenButton size="sm" title="Edit Trades" />}
              />
            </Box>
          </Box>
        </Box>
      );
    }
    return <Typography level="h1">Vendor</Typography>;
  }, [vendor]);

  return (
    <MainPageTemplate title="Vendor">
      <Box sx={{ pt: 2, pb: 2, pl: 2, pr: 2 }}>
        <Box sx={{ pl: 2, pr: 2 }}>
          <Box sx={{ display: 'flex' }}>
            <Typography
              color="primary"
              fontWeight={500}
              level="body-md"
              component={Link}
              to="/vendors"
              sx={{ mr: 1, textDecoration: 'none' }}
            >
              Vendors
            </Typography>
            <Typography
              color="primary"
              fontWeight={500}
              level="body-md"
              sx={{ mr: 1 }}
            >
              /
            </Typography>
            <Typography color="primary" fontWeight={500} level="body-md">
              View Vendor
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              pt: 2,
              justifyContent: 'space-between',
              mb: 4,
            }}
          >
            {title}
          </Box>
          <VendorDetailsCard vendor={vendor} loading={loading} />
        </Box>
        <Tabs
          value={tab}
          sx={{
            bgcolor: 'transparent',
          }}
        >
          <TabList
            tabFlex={1}
            size="sm"
            sx={{
              pl: 0,
              justifyContent: 'left',
              [`&& .${tabClasses.root}`]: {
                fontWeight: '600',
                flex: 'initial',
                color: 'text.tertiary',
                [`&.${tabClasses.selected}`]: {
                  bgcolor: 'transparent',
                  color: 'text.primary',
                  '&::after': {
                    height: '2px',
                    bgcolor: 'primary.500',
                  },
                },
              },
            }}
          >
            <Tab
              sx={{ borderRadius: '6px 6px 0 0' }}
              indicatorInset
              value="discussion"
              component={Link}
              to={`/vendors/view/${_id}/discussion`}
            >
              Discussion
            </Tab>
            <Tab
              sx={{ borderRadius: '6px 6px 0 0' }}
              indicatorInset
              value="work-orders"
              component={Link}
              to={`/vendors/view/${_id}/work-orders`}
            >
              Work Orders
            </Tab>
            <Tab
              sx={{ borderRadius: '6px 6px 0 0' }}
              indicatorInset
              value="history"
              component={Link}
              to={`/vendors/view/${_id}/history`}
            >
              History
            </Tab>
          </TabList>
          <TabPanel value="discussion">
            <ViewDiscussion filters={{ vendorId: _id }} />
          </TabPanel>
          <TabPanel value="work-orders">
            <MondayWorkOrdersAll filters={{ vendorId: _id }} />
          </TabPanel>
          <TabPanel value="history">
            <ViewHistory filters={{ vendorId: _id }} />
          </TabPanel>
        </Tabs>
      </Box>
    </MainPageTemplate>
  );
}

export default ViewVendor;
