import {
  Box,
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from '@mui/joy';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useDispatch } from 'react-redux';
import { showMessage } from '../../store/snackbar-slice';
import CircularProgressWithLabel from '../CircularProgressWithLabel';

const aspect = 1;
const scale = 1;
const rotate = 0;

function centerAspectCrop(mediaWidth: any, mediaHeight: any, _aspect: any) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      _aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

function getCroppedImg(image: any, pixelCrop: any) {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  canvas.width = pixelCrop.width * scaleX;
  canvas.height = pixelCrop.height * scaleY;

  const ctx = canvas.getContext('2d');
  if (ctx === null) {
    throw new Error('Failed to get 2d canvas');
  }

  ctx.drawImage(
    image,
    pixelCrop.x * scaleX,
    pixelCrop.y * scaleY,
    pixelCrop.width * scaleX,
    pixelCrop.height * scaleY,
    0,
    0,
    pixelCrop.width * scaleX,
    pixelCrop.height * scaleY,
  );

  // As Base64 string
  // const base64Image = canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob((file: any) => {
      resolve(file);
    }, 'image/jpeg');
  });
}

interface Props {
  uploaded: string;
  setUploaded: (x: string | undefined) => void;
  onGo: any;
  onDone: any;
}

function CropPhotoModal({ uploaded, setUploaded, onGo, onDone }: Props) {
  const [crop, setCrop] = useState<any>();
  const [saving, setSaving] = useState<any>();
  const [completedCrop, setCompletedCrop] = useState<any>();
  const [progress, setProgress] = useState<number>(0);
  const imgRef = useRef<any>();

  function onImageLoad(e: any) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useEffect(() => {
    setSaving(false);
  }, [uploaded]);

  const dispatch = useDispatch();
  const handleSave = useCallback(async () => {
    setSaving(true);
    setProgress(0);
    try {
      const file = await getCroppedImg(imgRef.current, completedCrop);
      await onGo({
        file: file,
        onProgress: (p: number) => setProgress(p),
      });

      dispatch(
        showMessage({
          _id: 'crop-image',
          severity: 'success',
          message: 'Successfully uploaded your image',
        }),
      );
      onDone();
    } catch (err) {
      console.error(err);
      dispatch(
        showMessage({
          _id: 'crop-image',
          severity: 'danger',
          message: 'There was an error saving your image',
        }),
      );
      setSaving(false);
    }
  }, [completedCrop, onDone, dispatch, onGo]);

  return (
    <Modal
      open={!!uploaded}
      onClose={() => setUploaded(undefined)}
      sx={{ zIndex: 1001 }}
    >
      <ModalDialog
        sx={{
          overflow: 'scroll',
          p: 4,
        }}
      >
        {!saving && <ModalClose />}
        <Typography level="h3" sx={{ mb: 3, textAlign: 'center' }}>
          {saving ? 'Uploading' : 'Crop Your Image'}
        </Typography>
        {!saving && !!uploaded && (
          <>
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={uploaded}
                style={{
                  maxHeight: '90vh',
                  maxWidth: '90vw',
                  transform: `scale(${scale}) rotate(${rotate}deg)`,
                  objectFit: 'contain',
                }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
            <Box sx={{ mt: 2, justifyContent: 'center', display: 'flex' }}>
              <Button variant="solid" onClick={handleSave}>
                Done
              </Button>
            </Box>
          </>
        )}
        {saving && (
          <div>
            <CircularProgressWithLabel value={progress} size={80} />
          </div>
        )}
      </ModalDialog>
    </Modal>
  );
}

export default CropPhotoModal;
