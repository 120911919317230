import {
  FormControl,
  FormHelperText,
  FormLabel,
  Select,
  Option,
} from '@mui/joy';
import { Control, Controller } from 'react-hook-form';

export interface SelectFieldOption {
  key: any;
  label: string;
}

interface Props {
  control: Control<any>;
  name: string;
  options: SelectFieldOption[];
  label?: string;
  caption?: string;
  placeholder?: string;
  rules?: any;
  sx?: any;
  disabled?: boolean;
}

function SelectFieldElement({
  control,
  name,
  options,
  label,
  caption,
  rules,
  placeholder,
  sx,
  disabled,
}: Props) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl error={!!error} sx={{ mb: 2, ...sx }}>
          {label && <FormLabel>{label}</FormLabel>}
          <Select
            value={value}
            onChange={(e, value) => {
              onChange(value);
            }}
            placeholder={placeholder}
          >
            {options.map((option) => (
              <Option key={option.key} value={option.key}>
                {option.label}
              </Option>
            ))}
          </Select>
          {(error || caption) && (
            <FormHelperText>{error?.message || caption}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}

export default SelectFieldElement;
