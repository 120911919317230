import { Box, Card, Typography } from '@mui/joy';
import { useParams } from 'react-router-dom';
import logo from '../../assets/long-logo.svg';
import PublicPageTemplate from '../../components/PublicPageTemplate';
import WorkOrderDetailsTable from './WorkOrderDetailsTable';
import useVendorRequestDetails from './use-vendor-request-details';

function StatusUpdateReceived() {
  const { token } = useParams();
  const { workOrder, loading } = useVendorRequestDetails(token);

  return (
    <PublicPageTemplate title="Sign In">
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          minHeight: '100%',
          flexDirection: 'column',
        }}
      >
        <img
          src={logo}
          alt="Mahathey Contracting"
          style={{
            marginTop: 80,
            width: 400,
            maxWidth: '90vw',
            marginBottom: 40,
          }}
        />
        <Card sx={{ width: 600, maxWidth: '90vw', p: 4, mb: 8 }}>
          <Typography
            level="h1"
            style={{ textAlign: 'center' }}
            color="success"
          >
            Status Update Received
          </Typography>
          <Typography level="h3" style={{ textAlign: 'center' }}>
            {workOrder?.vendor?.name}
          </Typography>
          <Typography style={{ textAlign: 'center' }}>
            Thank you for the update!
          </Typography>
          <WorkOrderDetailsTable workOrder={workOrder} loading={loading} />
        </Card>
      </Box>
    </PublicPageTemplate>
  );
}

export default StatusUpdateReceived;
