import { Box, Divider, Typography } from '@mui/joy';
import MainPageTemplate from '../components/MainPageTemplate';

function DashboardPage() {
  return (
    <MainPageTemplate title="Dashboard">
      <Box sx={{ pt: 2, pb: 2, pl: 2, pr: 2 }}>
        <Box sx={{ pl: 2, pr: 2 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              pt: 2,
              pb: 2,
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography level="h1">Dashboard</Typography>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ mb: 2 }} />
        <Box sx={{ pl: 2, pr: 2 }}>
          <Typography>Coming soon ... check out the other pages.</Typography>
        </Box>
      </Box>
    </MainPageTemplate>
  );
}

export default DashboardPage;
