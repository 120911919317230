import { Route, Routes } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import SignInPage from '../pages/auth/SignInPage';
import AcceptVendorRequest from '../pages/vendor-confirmation/AcceptVendorRequest';
import RejectVendorRequest from '../pages/vendor-confirmation/RejectVendorRequest';
import VendorRequestAccepted from '../pages/vendor-confirmation/VendorRequestAccepted';
import VendorRequestRejected from '../pages/vendor-confirmation/VendorRequestRejected';
import VendorStatusUpdate from '../pages/vendor-confirmation/VendorStatusUpdate';
import StatusUpdateReceived from '../pages/vendor-confirmation/StatusUpdateReceived';
import AcceptUserInvite from '../pages/user-invites/AcceptUserInvite';
import UserInviteAccepted from '../pages/user-invites/UserInviteAccepted';
import PasswordHelpPage from '../pages/auth/PasswordHelpPage';
import PasswordResetSent from '../pages/auth/PasswordResetSent';
import PasswordResetPage from '../pages/auth/PasswordResetPage';
import PasswordResetSuccessPage from '../pages/auth/PasswordResetSuccessPage';

function PublicRouter() {
  return (
    <Routes>
      <Route path="/sign-in" element={<SignInPage />} />
      <Route path="/password-help" element={<PasswordHelpPage />} />
      <Route
        path="/password-reset-sent/:username"
        element={<PasswordResetSent />}
      />
      <Route
        path="/password-reset/success/:token"
        element={<PasswordResetSuccessPage />}
      />
      <Route path="/password-reset/:token" element={<PasswordResetPage />} />
      <Route
        path="/service-request/accept/:token"
        element={<AcceptVendorRequest />}
      />
      <Route
        path="/service-request/accepted/:token"
        element={<VendorRequestAccepted />}
      />
      <Route path="/invites/:token" element={<AcceptUserInvite />} />
      <Route path="/invite/accepted/:token" element={<UserInviteAccepted />} />
      <Route
        path="/service-request/reject/:token"
        element={<RejectVendorRequest />}
      />
      <Route
        path="/service-request/rejected/:token"
        element={<VendorRequestRejected />}
      />
      <Route
        path="/service-request/update/:token"
        element={<VendorStatusUpdate />}
      />
      <Route
        path="/service-request/updated/:token"
        element={<StatusUpdateReceived />}
      />
      <Route path="*" element={<HomePage />} />
    </Routes>
  );
}

export default PublicRouter;
