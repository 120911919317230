import {
  Box,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
  tabClasses,
} from '@mui/joy';
import { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { LocationQueryQuery } from '../../__generated__/graphql';
import MainPageTemplate from '../../components/MainPageTemplate';
import useOne from '../../shared/use-one';
import { updateLocation } from '../../store/location-slice';
import ViewDiscussion from '../discussion/ViewDiscussion';
import ViewHistory from '../history/ViewHistory';
import LocationDetailsCard from './LocationDetailsCard';
import { extractLocation, locationQuery } from './constants';
import useLocation from './use-location';
import useLocationUpdates from './use-location-updates';
import RefreshLocFromSCButton from './RefreshLocFromSCButton';
import MondayWorkOrdersAll from '../work-orders/MondayWorkOrdersAll';

const queryId = 'default';

function ViewLocation() {
  const { _id, tab } = useParams();
  const { loading } = useOne<LocationQueryQuery>({
    extract: extractLocation,
    fetchPolicy: 'network-only',
    variables: {
      _id,
    },
    query: locationQuery,
    queryId,
    updateAction: updateLocation,
  });
  useLocationUpdates(_id, queryId);
  const location = useLocation(_id, queryId);
  const title = useMemo(() => {
    if (location) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flex: 1,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Box sx={{ display: 'flex', mb: 1 }}>
              <Typography level="h1" sx={{ flex: 1 }}>
                {location.storeId
                  ? `${location.scName} (${location.storeId})`
                  : `${location.scName}`}
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }
    return <Typography level="h1">Location</Typography>;
  }, [location]);

  return (
    <MainPageTemplate title="Location">
      <Box sx={{ pt: 2, pb: 2, pl: 2, pr: 2 }}>
        <Box sx={{ pl: 2, pr: 2 }}>
          <Box sx={{ display: 'flex' }}>
            <Typography
              color="primary"
              fontWeight={500}
              level="body-md"
              component={Link}
              to="/locations"
              sx={{ mr: 1, textDecoration: 'none' }}
            >
              Locations
            </Typography>
            <Typography
              color="primary"
              fontWeight={500}
              level="body-md"
              sx={{ mr: 1 }}
            >
              /
            </Typography>
            <Typography color="primary" fontWeight={500} level="body-md">
              View Location
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              pt: 2,
              justifyContent: 'space-between',
              mb: 4,
            }}
          >
            {title}
            {_id && <RefreshLocFromSCButton locationId={_id} />}
          </Box>
          <LocationDetailsCard location={location} loading={loading} />
        </Box>
        <Tabs
          value={tab}
          sx={{
            bgcolor: 'transparent',
          }}
        >
          <TabList
            tabFlex={1}
            size="sm"
            sx={{
              pl: 0,
              justifyContent: 'left',
              [`&& .${tabClasses.root}`]: {
                fontWeight: '600',
                flex: 'initial',
                color: 'text.tertiary',
                [`&.${tabClasses.selected}`]: {
                  bgcolor: 'transparent',
                  color: 'text.primary',
                  '&::after': {
                    height: '2px',
                    bgcolor: 'primary.500',
                  },
                },
              },
            }}
          >
            <Tab
              sx={{ borderRadius: '6px 6px 0 0' }}
              indicatorInset
              value="discussion"
              component={Link}
              to={`/locations/view/${_id}/discussion`}
            >
              Discussion
            </Tab>
            <Tab
              sx={{ borderRadius: '6px 6px 0 0' }}
              indicatorInset
              value="work-orders"
              component={Link}
              to={`/locations/view/${_id}/work-orders`}
            >
              Work Orders
            </Tab>
            <Tab
              sx={{ borderRadius: '6px 6px 0 0' }}
              indicatorInset
              value="history"
              component={Link}
              to={`/locations/view/${_id}/history`}
            >
              History
            </Tab>
          </TabList>
          <TabPanel value="discussion">
            <ViewDiscussion filters={{ locationId: _id }} />
          </TabPanel>
          <TabPanel value="work-orders">
            <MondayWorkOrdersAll filters={{ locationId: _id }} />
          </TabPanel>
          <TabPanel value="history">
            <ViewHistory filters={{ locationId: _id }} />
          </TabPanel>
        </Tabs>
      </Box>
    </MainPageTemplate>
  );
}

export default ViewLocation;
