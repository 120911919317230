import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from './auth-slice';
import snackbarReducer from './snackbar-slice';
import queryReducer from './query-slice';
import workOrderReducer from './work-order-slice';
import workOrderStatusReducer from './work-order-statuses-slice';
import woVendorStatusReducer from './wo-vendor-statuses-slice';
import locationReducer from './location-slice';
import userReducer from './user-slice';
import vendorReducer from './vendor-slice';
import discussionReducer from './discussion-slice';
import vendorInvoiceReducer from './vendor-invoice-slice';
import historyReducer from './history-slice';
import { useDispatch } from 'react-redux';
import { thunk } from 'redux-thunk';
import logger from 'redux-logger';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const rootReducer = combineReducers({
  auth: authReducer,
  discussions: discussionReducer,
  history: historyReducer,
  queries: queryReducer,
  locations: locationReducer,
  snackbar: snackbarReducer,
  users: userReducer,
  vendors: vendorReducer,
  vendorInvoices: vendorInvoiceReducer,
  workOrders: workOrderReducer,
  workOrderStatuses: workOrderStatusReducer,
  woVendorStatuses: woVendorStatusReducer,
});

const persistConfig = {
  key: 'redux',
  storage,
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const options = {
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    };
    if (process.env.NODE_ENV === 'development') {
      return getDefaultMiddleware(options).concat(thunk).concat(logger);
    }
    return getDefaultMiddleware(options).concat(thunk);
  },
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
