import { gql } from '@apollo/client';

export const paymentMethodsQuery = gql`
  query PaymentMethods {
    paymentMethods {
      _id
      label
    }
  }
`;
