import { gql } from '@apollo/client';
import {
  AllVendorsQueryQuery,
  VendorQueryQuery,
} from '../../__generated__/graphql';

export const allVendorsQuery = gql`
  query AllVendorsQuery(
    $first: Int
    $after: String
    $filters: VendorFilters
    $sortBy: [SortBy]
  ) {
    allVendors(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      edges {
        cursor
        node {
          _id
          name
          phone
          email
          profilePhotoId
          profilePhotoThumbUrl
          serviceTypeDesc
          serviceLocation {
            type
            coordinates
          }
          serviceRadius
          serviceArea {
            type
            coordinates
          }
          serviceAreaName
          address {
            address1
            address2
            city
            state
            county
            country
            zipCode
          }
          locations
          states
          cities
          trades
          website
          notes
          createdAt
          updatedAt
          deletedAt
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const extractAllVendors = (data: AllVendorsQueryQuery) =>
  data.allVendors;

export const vendorQuery = gql`
  query VendorQuery($_id: ID!) {
    vendor(_id: $_id) {
      _id
      name
      phone
      email
      profilePhotoId
      profilePhotoThumbUrl
      serviceTypeDesc
      serviceLocation {
        type
        coordinates
      }
      serviceRadius
      serviceArea {
        type
        coordinates
      }
      serviceAreaName
      address {
        address1
        address2
        city
        state
        county
        country
        zipCode
      }
      locations
      states
      cities
      trades
      website
      notes
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const extractVendor = (data: VendorQueryQuery) => data.vendor;
