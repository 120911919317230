import { useMemo } from 'react';
import { AllFilter } from '../../components/my-table/TableFilters';
import useCustomerFilter from '../customers/useCustomerFilter';
import useLocationFilter from '../locations/useLocationFilter';
import SelectFieldElement from '../../components/SelectFieldElement';
import useWorkOrderFilterOptions from './use-wo-filter-options';

function useWoFilters(): AllFilter[] {
  const {
    categoryOptions,
    primaryStatusOptions,
    extendedStatusOptions,
    priorityOptions,
  } = useWorkOrderFilterOptions();

  const customerFilter = useCustomerFilter();
  const locationFilter = useLocationFilter();

  const allFilters: AllFilter[] = useMemo(
    () => [
      customerFilter,
      locationFilter,
      {
        key: 'scCategory',
        label: 'Category',
        Component: ({ control }: any) => (
          <SelectFieldElement
            control={control}
            name="scCategory"
            options={categoryOptions}
            label="Filter by category"
            placeholder="Select a category"
          />
        ),
        finish: (v) => (v === '__undefined__' ? null : v),
        display: (v) => `Category (${v})`,
        defaultValue: null,
      },
      {
        key: 'scPrimaryStatus',
        label: 'Primary Status',
        Component: ({ control }: any) => (
          <SelectFieldElement
            control={control}
            name="scPrimaryStatus"
            options={primaryStatusOptions}
            label="Filter by primary status"
            placeholder="Select a status"
          />
        ),
        finish: (v) => (v === '__undefined__' ? null : v),
        display: (v) => `Primary Status (${v})`,
        defaultValue: null,
      },
      {
        key: 'scExtendedStatus',
        label: 'Extended Status',
        Component: ({ control }: any) => (
          <SelectFieldElement
            control={control}
            name="scExtendedStatus"
            options={extendedStatusOptions}
            label="Filter by extended status"
            placeholder="Select a status"
          />
        ),
        finish: (v) => (v === '__undefined__' ? null : v),
        display: (v) => `Extended Status (${v})`,
        defaultValue: null,
      },
      {
        key: 'scPriority',
        label: 'Priority',
        Component: ({ control }: any) => (
          <SelectFieldElement
            control={control}
            name="scPriority"
            options={priorityOptions}
            label="Filter by priority"
            placeholder="Select a priority"
          />
        ),
        finish: (v) => (v === '__undefined__' ? null : v),
        display: (v) => `Extended Status (${v})`,
        defaultValue: null,
      },
    ],
    [
      categoryOptions,
      customerFilter,
      locationFilter,
      extendedStatusOptions,
      primaryStatusOptions,
      priorityOptions,
    ],
  );

  return allFilters;
}

export default useWoFilters;
