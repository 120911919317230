import { useQuery } from '@apollo/client';
import AutocompleteFieldElement from '../../components/AutocompleteFieldElement';
import { useMemo } from 'react';
import { WorkOrderStatusNode } from '../../__generated__/graphql';
import { Typography } from '@mui/joy';
import { Link } from 'react-router-dom';
import { Control } from 'react-hook-form';
import { workOrderStatusesQuery } from './constants';

interface Props {
  control: Control<any>;
  name: string;
  label?: string;
  caption?: string;
  placeholder?: string;
  rules?: any;
  sx?: any;
  minRows?: number;
  disabled?: boolean;
}

function SelectWoStatusFieldElement({
  control,
  name,
  label,
  placeholder,
  rules,
  sx,
  disabled,
}: Props) {
  const result = useQuery(workOrderStatusesQuery, {
    fetchPolicy: 'network-only',
  });
  const options = useMemo(() => {
    if (result.data?.workOrderStatuses) {
      return result.data.workOrderStatuses.map((pm: WorkOrderStatusNode) => ({
        key: pm._id,
        label: pm.label,
      }));
    }
    return [];
  }, [result]);
  return (
    <AutocompleteFieldElement
      control={control}
      name={name}
      options={options}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      loading={result.loading}
      rules={rules}
      sx={sx}
      caption={
        <Typography
          component={Link}
          color="primary"
          level="body-sm"
          to="/work-order-statuses"
          sx={{
            textDecoration: 'none',
          }}
        >
          Manage Statuses
        </Typography>
      }
    />
  );
}

export default SelectWoStatusFieldElement;
