import { Box, Button, Typography } from '@mui/joy';
import { createPrimaryPalette } from '@yosulramp/material-color-palette-js';
import { useEffect } from 'react';
import ColorPickerFieldElement from '../../components/ColorPickerFieldElement';
import TextFieldElement from '../../components/TextFieldElement';
import SelectTextColorFieldElement from './SelectTextColorFieldElement';

interface Props {
  title: string;
  handleSubmit: any;
  onSubmit: any;
  control: any;
  saving: any;
  watch: any;
  colorPalette: any;
  setColorPalette: any;
  onCancel: any;
}

function SomeStatusForm({
  title,
  handleSubmit,
  onSubmit,
  control,
  saving,
  watch,
  colorPalette,
  setColorPalette,
  onCancel,
}: Props) {
  const color = watch('color');
  const textColor = watch('textColor');
  const label = watch('label');

  useEffect(() => {
    if (color) {
      const customPrimaryPalette = createPrimaryPalette(color.slice(1))?.map(
        (rgbColor) => `#${rgbColor.rgbHex}`,
      );
      setColorPalette(customPrimaryPalette);
    } else {
      setColorPalette(undefined);
    }
  }, [color, setColorPalette]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography level="h3" sx={{ mb: 2, textAlign: 'center' }}>
        {title}
      </Typography>
      <TextFieldElement
        control={control}
        name="label"
        label="Status Label"
        placeholder="Status Label"
        sx={{ mb: 2 }}
        loading={saving}
        rules={{ required: 'This field is required' }}
      />
      <TextFieldElement
        control={control}
        name="desc"
        label="Description"
        placeholder="Some description..."
        sx={{ mb: 2 }}
        loading={saving}
        rules={{ required: 'This field is required' }}
      />
      <ColorPickerFieldElement
        control={control}
        name="color"
        label="Color"
        sx={{ mb: 2 }}
        disabled={saving}
        rules={{ required: 'This field is required' }}
      />
      <SelectTextColorFieldElement
        control={control}
        name="textColor"
        label="Text Color"
        sx={{ mb: 3 }}
        disabled={saving}
        rules={{ required: 'This field is required' }}
      />
      {color && label && textColor && (
        <Box
          sx={{
            backgroundColor: color,
            borderRadius: 8,
            padding: '4px 12px',
            mb: 2,
            height: '2.5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              color: textColor,
              fontWeight: 600,
              textAlign: 'center',
            }}
          >
            {label}
          </Typography>
        </Box>
      )}
      {color && (
        <Box sx={{ display: 'flex', mb: 2 }}>
          {colorPalette?.map((c: string) => (
            <Box
              key={c}
              sx={{
                backgroundColor: c,
                height: '2.5rem',
                width: '2.5rem',
              }}
            />
          ))}
        </Box>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
        <Button
          variant="outlined"
          color="neutral"
          disabled={saving}
          size="lg"
          onClick={onCancel}
          sx={{ mr: 4, width: 100 }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          loading={saving}
          variant="solid"
          size="lg"
          sx={{ width: 100 }}
        >
          Save
        </Button>
      </Box>
    </form>
  );
}

export default SomeStatusForm;
