import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../store/root';

function useDiscussions(objectId: string, queryId: string = 'default') {
  const discussions = useSelector(
    (store: RootState) => store.discussions[queryId]?.[objectId],
    shallowEqual,
  );
  return discussions;
}

export default useDiscussions;
