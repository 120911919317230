import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../store/root';

function useVendorInvoices(objectId: string, queryId: string = 'default') {
  const vendorInvoices = useSelector(
    (store: RootState) => store.vendorInvoices[queryId]?.[objectId],
    shallowEqual,
  );
  return vendorInvoices;
}

export default useVendorInvoices;
