import {
  Box,
  FormControl,
  FormLabel,
  IconButton,
  Option,
  Select,
  Typography,
} from '@mui/joy';
import { useState } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface Pagination {
  page: number;
  rowsPerPage: number;
}

interface Props {
  numRows: number;
  pagination: Pagination;
  setPagination: (p: Pagination) => void;
}

function labelDisplayedRows({
  from,
  to,
  count,
}: {
  from: number;
  to: number;
  count: number;
}) {
  return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
}

function MyTablePagination({ numRows, pagination, setPagination }: Props) {
  const handleChangePage = (newPage: number) => {
    setPagination({ ...pagination, page: newPage });
  };

  const handleChangeRowsPerPage = (event: any, newValue: number | null) => {
    setPagination({ page: 0, rowsPerPage: parseInt(newValue!.toString(), 10) });
  };

  const getLabelDisplayedRowsTo = () => {
    if (numRows === -1) {
      return (pagination.page + 1) * pagination.rowsPerPage;
    }
    return pagination.rowsPerPage === -1
      ? numRows
      : Math.min(numRows, (pagination.page + 1) * pagination.rowsPerPage);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        justifyContent: 'center',
        pt: 2,
      }}
    >
      <FormControl orientation="horizontal" size="sm">
        <FormLabel>Rows per page:</FormLabel>
        <Select
          onChange={handleChangeRowsPerPage}
          value={pagination.rowsPerPage}
        >
          <Option value={25}>25</Option>
          <Option value={50}>50</Option>
          <Option value={100}>100</Option>
          <Option value={200}>200</Option>
          <Option value={400}>400</Option>
        </Select>
      </FormControl>
      <Typography textAlign="center" sx={{ minWidth: 80 }}>
        {labelDisplayedRows({
          from:
            numRows === 0 ? 0 : pagination.page * pagination.rowsPerPage + 1,
          to: getLabelDisplayedRowsTo(),
          count: numRows === -1 ? -1 : numRows,
        })}
      </Typography>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <IconButton
          size="sm"
          color="neutral"
          variant="outlined"
          disabled={pagination.page === 0}
          onClick={() => handleChangePage(pagination.page - 1)}
          sx={{ bgcolor: 'background.surface' }}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
        <IconButton
          size="sm"
          color="neutral"
          variant="outlined"
          disabled={
            numRows !== -1
              ? pagination.page >=
                Math.ceil(numRows / pagination.rowsPerPage) - 1
              : false
          }
          onClick={() => handleChangePage(pagination.page + 1)}
          sx={{ bgcolor: 'background.surface' }}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

export default MyTablePagination;
