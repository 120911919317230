import {
  Avatar,
  Box,
  Button,
  Card,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/joy';
import { Profile } from '../../store/auth-slice';
import { formatAddress, formatPhoneNumberForDisplay } from '../../shared/utils';
import EditIcon from '@mui/icons-material/Edit';
import EditSomeProfileLine from './EditSomeProfileLine';
import EditTextInner from '../../components/edit-inner/EditTextInner';
import { ReactElement } from 'react';
import EditPhoneInner from '../../components/edit-inner/EditPhoneInner';
import EditAddressInner from '../../components/edit-inner/EditAddressInner';
import UploadProfilePhotoButton from './UploadProfilePhotoButton';
import ChangeMyPasswordBtn from './ChangeMyPasswordBtn';

interface OpenButtonProps {
  onOpen?: () => void;
}

function OpenButton({ onOpen }: OpenButtonProps) {
  return (
    <Tooltip title="Edit">
      <IconButton onClick={onOpen}>
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
}

interface LineProps {
  label: string;
  value?: string | null | undefined;
  component?: any;
  noLabel?: string;
  EditForm: ReactElement;
}

function ProfileLine({
  label,
  value,
  component,
  noLabel,
  EditForm,
}: LineProps) {
  return (
    <Box
      sx={{
        mb: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box>
        <Typography level="body-sm">{label}</Typography>
        {!!value && !component && (
          <Typography level="body-md">{value}</Typography>
        )}
        {component}
        {!value && !component && (
          <Typography level="body-md">{noLabel}</Typography>
        )}
      </Box>
      <EditSomeProfileLine EditForm={EditForm} OpenButton={<OpenButton />} />
    </Box>
  );
}

interface Props {
  profile: Profile;
}

function DisplayProfileCard({ profile }: Props) {
  return (
    <Card
      sx={{
        width: 400,
        maxWidth: '90vw',
        p: 2,
        pt: 4,
        pb: 4,
        mr: 'auto',
        ml: 'auto',
        mt: 2,
      }}
    >
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Avatar
          src={profile.profilePhotoThumbUrl || undefined}
          sx={{ height: 200, width: 200, mr: 2, ml: 2, mb: 2 }}
        />
        <Box sx={{ justifyContent: 'center', mb: 2 }}>
          <UploadProfilePhotoButton />
        </Box>
        <Box sx={{ pr: 2, pl: 2, flex: 1, alignSelf: 'stretch' }}>
          <ProfileLine
            label="Name"
            value={profile.name}
            EditForm={
              <EditTextInner
                defaultValues={{ name: profile.name }}
                title="Update Name"
                name="name"
                label="Name"
                placeholder="Your Name"
                rules={{ required: 'This field is required' }}
              />
            }
          />
          <ProfileLine
            label="Username"
            value={profile.username}
            EditForm={
              <EditTextInner
                defaultValues={{ username: profile.username }}
                title="Update Username"
                name="username"
                label="Username"
                placeholder="Email... First Name..."
                rules={{ required: 'This field is required' }}
              />
            }
          />
          <ProfileLine
            label="Email"
            value={profile.email}
            EditForm={
              <EditTextInner
                defaultValues={{ email: profile.email }}
                title="Update Email"
                name="email"
                label="Email"
                placeholder="Your Email"
                rules={{
                  required: 'This field is required',
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Please enter a valid email',
                  },
                }}
              />
            }
          />
          <ProfileLine
            label="Phone"
            value={formatPhoneNumberForDisplay(profile.phone)}
            EditForm={
              <EditPhoneInner
                defaultValues={{ phone: profile.phone }}
                title="Update Phone Number"
                name="phone"
                label="Phone Number"
              />
            }
          />
          <ProfileLine
            label="Address"
            component={
              <>
                {formatAddress(profile.address)
                  .split('\n')
                  .map((line, index) => (
                    <div key={index}>{line}</div>
                  ))}
              </>
            }
            EditForm={
              <EditAddressInner
                defaultValues={{
                  address1: profile.address?.address1 || '',
                  address2: profile.address?.address2 || '',
                  city: profile.address?.city || '',
                  state: profile.address?.state || '',
                  county: profile.address?.county || '',
                  country: profile.address?.country || '',
                  zipCode: profile.address?.zipCode || '',
                }}
                title="Update Address"
                name="address"
              />
            }
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <ChangeMyPasswordBtn />
        </Box>
      </Box>
    </Card>
  );
}

export default DisplayProfileCard;
