import { gql, useMutation } from '@apollo/client';
import { Button } from '@mui/joy';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteToken } from '../../store/auth-slice';
import { showMessage } from '../../store/snackbar-slice';

const signOutMutation = gql`
  mutation SignOut {
    webSignOut
  }
`;

function SignOutButton() {
  const [signOut] = useMutation(signOutMutation);
  const [loading, setLoading] = useState<boolean>();
  const dispatch = useDispatch();
  const onSignOut = useCallback(async () => {
    setLoading(true);
    try {
      await signOut({
        variables: {},
      });
      dispatch(deleteToken({}));
      dispatch(
        showMessage({
          _id: 'sign-out',
          severity: 'success',
          message: 'Success',
        }),
      );
    } catch (err) {
      console.error(err);
      dispatch(
        showMessage({
          _id: 'sign-out',
          severity: 'danger',
          message: 'There was an error signing out.',
        }),
      );
    }
    setLoading(false);
  }, [signOut, dispatch]);
  return (
    <Button
      color="neutral"
      size="lg"
      variant="outlined"
      onClick={onSignOut}
      loading={loading}
    >
      Sign Out
    </Button>
  );
}

export default SignOutButton;
