import { gql, useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  LocationNode,
  LocationUpdatesSubscription,
} from '../../__generated__/graphql';
import { updateLocation } from '../../store/location-slice';

const locationUpdatesSubscription = gql`
  subscription LocationUpdates($_id: ID!) {
    locationUpdates(_id: $_id) {
      _id
      crud
      new {
        _id
        scId
        scName
        customerId
        customer {
          _id
          scName
        }
        storeId
        scLat
        scLon
        scEmail
        scPhone
        scFax
        scAddress {
          address1
          address2
          city
          state
          county
          country
          zipCode
        }
        scContact
        notes
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

function useLocationUpdates(_id: string | undefined, queryId = 'default') {
  const dispatch = useDispatch();
  useSubscription<LocationUpdatesSubscription>(locationUpdatesSubscription, {
    skip: !_id,
    variables: {
      _id,
    },
    fetchPolicy: 'no-cache',
    onSubscriptionData: ({ client, subscriptionData: result }) => {
      try {
        const update = result?.data?.locationUpdates;
        if (update?.new) {
          switch (update.crud) {
            case 'update':
              dispatch(updateLocation(update.new as LocationNode, queryId));
              break;
            default:
              break;
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
}

export default useLocationUpdates;
