import { Box, Button, List, ListItem, Typography } from '@mui/joy';
import { useForm } from 'react-hook-form';
import { gql, useQuery } from '@apollo/client';
import { AllScTradesQuery } from '../../__generated__/graphql';
import { useCallback, useState } from 'react';

export const allScTradesQuery = gql`
  query AllScTrades {
    allScTrades
  }
`;

export const extractAllLocations = (data: AllScTradesQuery) => data.allScTrades;

const instructions = [
  'Open Google Earth.',
  `Use the "Add Path or Polygon" feature to draw this vendor's service area(s).  Every polygon must be closed (the first and last point must be the same).  Be sure to name your polygons (West Texas, Alabama, COS, etc.)`,
  'Once finished, click "File" > "Export as KML" file.',
  'Then upload the exported KML here.',
];

interface Props {
  defaultValues: any;
  title: string;
  name: string;
  label: string;
  caption?: string;
  placeholder?: string;
  rules?: any;
  onSubmit?: any;
  saving?: boolean;
  onCancel?: () => void;
}

const myId = 'editServiceAreaInner';

function EditServiceAreaInner({
  defaultValues,
  onSubmit,
  name,
  label,
  caption,
  placeholder,
  rules,
  saving,
  onCancel,
  title,
}: Props) {
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm<any>({
    defaultValues,
  });

  const [location, setLocation] = useState<any>();
  const [error, setError] = useState<any>();

  const handleUploadClick = useCallback(
    (event: any) => {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onloadend = function (e) {
        try {
          if (reader.result) {
            const xmlDoc = new DOMParser().parseFromString(
              reader.result as string,
              'application/xml',
            );
            const placemarks = xmlDoc.querySelectorAll('Placemark');
            const names: any = [];
            const locs: any = [];
            placemarks.forEach((p: any) => {
              const name = p.querySelector('name')?.textContent;
              const visibility = p.querySelector('visibility')?.textContent;
              if (visibility === '0') {
                return;
              }
              const coordString = p.querySelector(
                'Polygon coordinates',
              )?.textContent;
              const coords = coordString
                .trim()
                .split(' ')
                .map((c: any) => c.trim().split(',').slice(0, 2));
              names.push(name);
              console.log('coords', coords);
              if (
                coords[0][0] !== coords[coords.length - 1][0] &&
                coords[0][1] !== coords[coords.length - 1][1]
              ) {
                throw new Error(
                  'First and last coordinate must be the same for every polygon.',
                );
              }
              locs.push(coords);
            });
            const serviceAreaName = `${names.join(', ')}`;
            const serviceArea = locs.map((loc: any) => [loc]);
            setValue('serviceAreaName', serviceAreaName);
            setValue('serviceArea', {
              type: 'MultiPolygon',
              coordinates: serviceArea,
            });
            setLocation({
              serviceArea,
              serviceAreaName,
            });
          }
        } catch (err: any) {
          console.error(err);
          setError(err.message);
        }
        event.target.value = null;
      };
      reader.readAsText(file);
    },
    [setValue],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography level="h3" sx={{ mb: 4, textAlign: 'center' }}>
        {title}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {location ? (
          <Box sx={{ textAlign: 'center' }}>
            <Typography sx={{ mb: 1 }}>Does this look right?</Typography>
            <Typography>{location.serviceAreaName}</Typography>
            <Typography>{`${location.serviceArea.length} Polygon${location.serviceArea.length > 1 ? 's' : ''}`}</Typography>
          </Box>
        ) : (
          <Box sx={{ textAlign: 'center' }}>
            <Typography level="h4">Instructions</Typography>
            <List component="ol" marker="decimal" sx={{ textAlign: 'left' }}>
              {instructions.map((instruction: any, index: number) => (
                <ListItem key={index}>{instruction}</ListItem>
              ))}
            </List>
            <Typography
              sx={{ mb: 2, mt: 2 }}
            >{`Notes: If you make a polygon invisible, it will not be imported.`}</Typography>
            <Button component="label" htmlFor={myId} variant="outlined">
              Upload KML File
              <input
                id={myId}
                accept="application/vnd.google-earth.kml+xml"
                type="file"
                onChange={handleUploadClick}
                style={{ display: 'none' }}
              />
            </Button>
            {error && (
              <Typography sx={{ mt: 2 }} color="danger">
                {error}
              </Typography>
            )}
          </Box>
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
        <Button
          variant="outlined"
          color="neutral"
          size="lg"
          onClick={onCancel}
          sx={{ mr: 4, width: 100 }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          loading={saving}
          variant="solid"
          size="lg"
          sx={{ width: 100 }}
        >
          Save
        </Button>
      </Box>
    </form>
  );
}

export default EditServiceAreaInner;
