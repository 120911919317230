import { AllColumn } from './MyTable';

interface Props {
  allColumns: AllColumn[];
  row: any;
}

function MyTableRow({ allColumns, row }: Props) {
  return (
    <tr key={row._id}>
      {allColumns.map((col: any) => (
        <td key={col.key} style={col.style?.(row)}>
          {col.component(row)}
        </td>
      ))}
    </tr>
  );
}

export default MyTableRow;
