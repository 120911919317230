import { useMemo, useRef } from 'react';
import {
  AllLocationsQueryQuery,
  LocationNode,
  SortBy,
  SortByDirection,
} from '../../__generated__/graphql';
import { useManyRemote } from '../../shared/use-many-remote';
import { allLocationsQuery, extractAllLocations } from './constants';
import AutocompleteFieldElement from '../../components/AutocompleteFieldElement';
import { AllFilter } from '../../components/my-table/TableFilters';

const initialOrder: SortBy = {
  key: 'updatedAt',
  order: SortByDirection.Desc,
};

function useLocationFilter(): AllFilter {
  const filters = useRef({});
  const sortBy = useRef<SortBy[]>([initialOrder]);

  const {
    loading,
    data: rows,
    search,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemote<AllLocationsQueryQuery, LocationNode>({
    query: allLocationsQuery,
    extract: extractAllLocations,
    first: 10000,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'cache-first',
    queryId: 'location-filter',
  });

  const options = useMemo(() => {
    return rows.map((row: LocationNode) => ({
      key: row._id,
      label: `${row.scName} (${row.storeId})` || '',
    }));
  }, [rows]);

  return {
    key: 'locationId',
    label: 'Location',
    Component: ({ control }: any) => (
      <AutocompleteFieldElement
        control={control}
        name="locationId"
        options={options}
        label="Filter by location"
        placeholder="Select a location"
      />
    ),
    finish: (v: any) => (v === '__undefined__' ? null : v),
    display: (v: any) => {
      const row = rows.find((r) => r._id === v);
      const name = row && `${row.scName} (${row.storeId})`;
      return `Location (${name || v})`;
    },
    defaultValue: null,
  };
}

export default useLocationFilter;
