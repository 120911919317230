import { gql } from '@apollo/client';
import { Box, Button, Checkbox, Chip, Typography } from '@mui/joy';
import { useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import {
  AllScTradesQuery,
  AllVendorsQueryQuery,
  SortBy,
  SortByDirection,
  VendorNode,
  WorkOrderNode,
} from '../../__generated__/graphql';
import AutocompleteFieldElement from '../../components/AutocompleteFieldElement';
import settings from '../../settings';
import { useManyRemote } from '../../shared/use-many-remote';
import { allVendorsQuery, extractAllVendors } from '../vendors/constants';
import useSafeState from '../../shared/use-safe-state';
import { formatAddress } from '../../shared/utils';
import CheckboxFieldElement from '../../components/CheckboxFieldElement';

export const allScTradesQuery = gql`
  query AllScTrades {
    allScTrades
  }
`;

export const extractAllLocations = (data: AllScTradesQuery) => data.allScTrades;

interface Props {
  defaultValues: any;
  title: string;
  name: string;
  label: string;
  caption?: string;
  placeholder?: string;
  workOrder?: WorkOrderNode;
  rules?: any;
  onSubmit?: any;
  saving?: boolean;
  onCancel?: () => void;
}

const initialOrder: SortBy = {
  key: 'scCallDate',
  order: SortByDirection.Desc,
};

const PossibleFilters: any = {
  locationId: {
    label: (wo: WorkOrderNode) =>
      wo.location?.scAddress &&
      `Location (${formatAddress(wo.location.scAddress)}`,
    workOrderKey: 'locationId',
    filterKey: 'locationId',
  },
  trade: {
    label: (wo: WorkOrderNode) => wo.scTrade && `Trade (${wo.scTrade})`,
    workOrderKey: 'scTrade',
    filterKey: 'trade',
  },
};

function SelectVendorInner({
  defaultValues,
  onSubmit,
  name,
  label,
  caption,
  placeholder,
  workOrder,
  rules,
  saving,
  onCancel,
  title,
}: Props) {
  const [filters, setFilters, _filters] = useSafeState<any>({
    locationId: workOrder?.locationId,
    trade: workOrder?.scTrade,
  });
  const sortBy = useRef<SortBy[]>([initialOrder]);

  const {
    loading,
    data: rows,
    search,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemote<AllVendorsQueryQuery, VendorNode>({
    query: allVendorsQuery,
    extract: extractAllVendors,
    first: settings.querySize,
    filters: _filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: 'vendors',
  });

  const options = useMemo(() => {
    return rows.map((row: VendorNode) => ({
      key: row._id,
      label: `${row.name} (${row.serviceAreaName || 'no service area'})` || '',
    }));
  }, [rows]);

  const displayFilters: any = useMemo(() => {
    return Object.entries(PossibleFilters).map(([key, value]: any) => ({
      filterKey: value.filterKey,
      workOrderKey: value.workOrderKey,
      label: PossibleFilters[value.filterKey].label(workOrder),
    }));
  }, [workOrder]);

  const onFilterChange =
    (filterKey: any, workOrderKey: any) => (event: any) => {
      console.log('checked', event.target.checked);
      const checked = event.target.checked;
      if (checked) {
        setFilters({
          ..._filters.current,
          [filterKey]: (workOrder as any)[workOrderKey],
        });
      } else {
        setFilters({
          ..._filters.current,
          [filterKey]: undefined,
        });
      }
    };

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<any>({
    defaultValues,
  });

  const doSubmit = async (e: any) => {
    await handleSubmit(onSubmit)(e);
  };
  return (
    <form onSubmit={doSubmit}>
      <Typography level="h3" sx={{ mb: 2, textAlign: 'center' }}>
        {title}
      </Typography>
      <Box sx={{ mb: 1 }}>
        <Typography
          level="title-sm"
          color="primary"
          sx={{ fontWeight: 600, mb: 1 }}
        >
          Filters
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {displayFilters.map((d: any) => (
            <Checkbox
              key={d.filterKey}
              onChange={onFilterChange(d.filterKey, d.workOrderKey)}
              checked={!!filters[d.filterKey]}
              sx={{ alignItems: 'center', mb: 1 }}
              label={
                <Chip key={d.filterKey} sx={{ mr: 1 }} variant="solid">
                  {d.label}
                </Chip>
              }
            />
          ))}
        </Box>
      </Box>
      <AutocompleteFieldElement
        control={control}
        name={name}
        options={options}
        label={label}
        placeholder={placeholder}
        loading={loading}
        disabled={saving}
        rules={{ required: 'This field is required' }}
      />
      <CheckboxFieldElement
        control={control}
        name="sendVendorRequestEmail"
        label="Send Vendor Request Email"
        loading={loading}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
        <Button
          variant="outlined"
          color="neutral"
          disabled={saving}
          size="lg"
          onClick={onCancel}
          sx={{ mr: 4, width: 100 }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          loading={saving}
          variant="solid"
          size="lg"
          sx={{ width: 100 }}
        >
          Save
        </Button>
      </Box>
    </form>
  );
}

export default SelectVendorInner;
