import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { VendorInvoiceNode } from '../__generated__/graphql';

interface InitialState {
  [key: string]: {
    [key: string]: VendorInvoiceNode[];
  };
}

interface ReadVendorInvoicesPayload {
  queryId: string;
  objectId: string;
  vendorInvoices: VendorInvoiceNode[];
}

interface UpdateVendorInvoicePayload {
  queryId: string;
  objectId: string;
  vendorInvoice: VendorInvoiceNode;
}

interface DeleteVendorInvoicePayload {
  queryId: string;
  objectId: string;
  _id: string;
}

const initialState: InitialState = {
  default: {},
};

const vendorInvoicesSlice = createSlice({
  name: 'vendorInvoices',
  initialState,
  reducers: {
    readVendorInvoices: {
      reducer(state, action: PayloadAction<ReadVendorInvoicesPayload>) {
        state[action.payload.queryId][action.payload.objectId] = [
          ...action.payload.vendorInvoices,
        ].sort((a, b) => a.createdAt.localeCompare(b.createdAt, 'en-US'));
      },
      prepare(
        vendorInvoices: VendorInvoiceNode[],
        objectId: string,
        queryId = 'default',
      ) {
        return { payload: { vendorInvoices, objectId, queryId } };
      },
    },
    updateVendorInvoice: {
      reducer(state, action: PayloadAction<UpdateVendorInvoicePayload>) {
        state[action.payload.queryId] = {
          ...state[action.payload.queryId],
          [action.payload.objectId]: [
            ...state[action.payload.queryId][action.payload.objectId].filter(
              (o: any) => o._id !== action.payload.vendorInvoice._id,
            ),
            action.payload.vendorInvoice,
          ].sort((a, b) => a.createdAt.localeCompare(b.createdAt, 'en-US')),
        };
      },
      prepare(
        vendorInvoice: VendorInvoiceNode,
        objectId: string,
        queryId = 'default',
      ) {
        return { payload: { vendorInvoice, objectId, queryId } };
      },
    },
    deleteVendorInvoice: {
      reducer(state, action: PayloadAction<DeleteVendorInvoicePayload>) {
        state[action.payload.queryId] = {
          ...state[action.payload.queryId],
          [action.payload.objectId]: [
            ...state[action.payload.queryId][action.payload.objectId].filter(
              (o: any) => o._id !== action.payload._id,
            ),
          ].sort((a, b) => a.createdAt.localeCompare(b.createdAt, 'en-US')),
        };
      },
      prepare(_id: string, objectId: string, queryId = 'default') {
        return { payload: { _id, objectId, queryId } };
      },
    },
  },
});

export const { readVendorInvoices, updateVendorInvoice, deleteVendorInvoice } =
  vendorInvoicesSlice.actions;
export default vendorInvoicesSlice.reducer;
