import { gql, useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { UserNode, UserUpdatesSubscription } from '../../__generated__/graphql';
import { updateUser } from '../../store/user-slice';

const userUpdatesSubscription = gql`
  subscription UserUpdates($_id: ID!) {
    userUpdates(_id: $_id) {
      _id
      crud
      new {
        _id
        username
        name
        roleIds
        email
        emailVerified
        phone
        phoneVerified
        address {
          address1
          address2
          city
          state
          county
          country
          zipCode
        }
        profilePhotoId
        profilePhotoThumbUrl
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

function useUserUpdates(_id: string | undefined, queryId = 'default') {
  const dispatch = useDispatch();
  useSubscription<UserUpdatesSubscription>(userUpdatesSubscription, {
    skip: !_id,
    variables: {
      _id,
    },
    fetchPolicy: 'no-cache',
    onSubscriptionData: ({ client, subscriptionData: result }) => {
      try {
        const update = result?.data?.userUpdates;
        if (update?.new) {
          switch (update.crud) {
            case 'update':
              dispatch(updateUser(update.new as UserNode, queryId));
              break;
            default:
              break;
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
}

export default useUserUpdates;
