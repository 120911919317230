import { gql } from '@apollo/client';
import { Box, Card, Typography } from '@mui/joy';
import logo from '../../assets/square-logo.svg';
import PublicPageTemplate from '../../components/PublicPageTemplate';

function ErrorPage() {
  return (
    <PublicPageTemplate title="Error">
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          minHeight: '100%',
          flexDirection: 'column',
        }}
      >
        <img
          src={logo}
          alt="Mahathey Contracting"
          style={{
            marginTop: 120,
            width: 160,
            maxWidth: '90vw',
            marginBottom: 24,
          }}
        />
        <Card style={{ width: 500, maxWidth: '90vw', padding: 32 }}>
          <Typography level="h1" sx={{ textAlign: 'center' }}>
            An Unknown Error Occurred
          </Typography>
          <Typography sx={{ textAlign: 'center' }}>
            We apologize. Something went wrong.
          </Typography>
        </Card>
      </Box>
    </PublicPageTemplate>
  );
}

export default ErrorPage;
