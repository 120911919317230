import { gql, useMutation } from '@apollo/client';
import { Button } from '@mui/joy';
import { useCallback, useState } from 'react';
import { generateUuid } from '../../shared/utils';
import UploadProgressModal from '../discussion/UploadProgressModal';

const uploadVendorInvoiceAttachmentMutation = gql`
  mutation UploadVendorInvoiceAttachment(
    $attachment: VendorInvoiceAttachmentInput!
  ) {
    uploadVendorInvoiceAttachment(attachment: $attachment)
  }
`;

interface Props {
  vendorInvoiceId: string;
  filters?: any;
  refetch?: any;
  myId: any;
}

function UploadVendorInvoiceAttachmentButton({
  vendorInvoiceId,
  filters,
  refetch,
  myId,
}: Props) {
  const [uploaded, setUploaded] = useState<any>();
  const [uploadAttachment] = useMutation(
    uploadVendorInvoiceAttachmentMutation,
  ) as any;

  const handleUploadClick = useCallback((event: any) => {
    const file = event.target.files[0];
    setUploaded(file);
  }, []);

  const onGo = useCallback(
    async ({ file, onProgress, desc }: any) => {
      let _id = vendorInvoiceId;
      const fileId = generateUuid();
      await uploadAttachment({
        variables: {
          attachment: {
            vendorInvoiceId: _id,
            fileId: fileId,
            desc,
            filename: `${file.name}`,
            contentType: file.type,
            size: file.size,
            tags: ['VendorInvoice Attachment'],
            file: file,
          },
        },
        context: {
          fetchOptions: {
            onProgress: (percent: number) => onProgress(percent),
          },
        },
      });
      // refetch();
    },
    [uploadAttachment, vendorInvoiceId],
  );
  const onDone = useCallback(() => {
    setUploaded(undefined);
  }, []);

  return (
    <>
      <Button component="label" htmlFor={myId} variant="outlined">
        Upload Attachment
        <input
          id={myId}
          accept="*/*"
          type="file"
          onChange={handleUploadClick}
          style={{ display: 'none' }}
        />
      </Button>
      <UploadProgressModal
        formId={vendorInvoiceId}
        uploaded={uploaded}
        setUploaded={setUploaded}
        onGo={onGo}
        onDone={onDone}
      />
    </>
  );
}

export default UploadVendorInvoiceAttachmentButton;
