import { gql, useMutation } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from '@mui/joy';
import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import TextFieldElement from '../../components/TextFieldElement';
import { generateUuid } from '../../shared/utils';
import { showMessage } from '../../store/snackbar-slice';
import SelectRolesFieldElement from '../users/SelectRolesFieldElement';

const inviteUserMutation = gql`
  mutation InviteUser($invite: InviteUserInput!) {
    inviteUser(invite: $invite)
  }
`;

interface Props {}

function InviteUserBtn() {
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [inviteUser] = useMutation(inviteUserMutation);

  const dispatch = useDispatch();
  const onSubmit: SubmitHandler<any> = useCallback(
    async (_values) => {
      setSaving(true);
      const { ...values } = _values;
      try {
        await inviteUser({
          variables: {
            invite: {
              _id: generateUuid(),
              ...values,
            },
          },
        });
        setOpen(false);
        dispatch(
          showMessage({
            _id: 'create-user',
            severity: 'success',
            message: 'Success',
          }),
        );
      } catch (err: any) {
        console.error(err);
        dispatch(
          showMessage({
            _id: 'create-user',
            severity: 'danger',
            message: 'There was an error creating invite',
          }),
        );
      }
      setSaving(false);
    },
    [inviteUser, dispatch],
  );

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    reset,
  } = useForm<any>({
    defaultValues: {
      name: '',
      email: '',
      roleIds: [],
    },
  });

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [reset, open]);

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog sx={{ p: 4, width: 500, maxWidth: '90uvw' }}>
          <ModalClose />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography level="h3" sx={{ mb: 2, textAlign: 'center' }}>
              Invite a New User
            </Typography>
            <Box
              sx={{
                overflow: 'scroll',
                maxHeight: '80vh',
              }}
            >
              <TextFieldElement
                control={control}
                name="name"
                label="Name"
                rules={{ required: 'This field is required' }}
                placeholder="User Name"
                sx={{ mb: 2 }}
              />
              <TextFieldElement
                control={control}
                name="email"
                label="Email"
                placeholder="Email"
                sx={{ mb: 2 }}
                rules={{
                  required: 'This field is required',
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Please enter a valid email',
                  },
                }}
              />
              <SelectRolesFieldElement
                control={control}
                name="roleIds"
                label="Roles"
                sx={{ mb: 2 }}
                caption={`This determines a users permissions within this application.`}
              />
              <Box
                sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}
              >
                <Button
                  variant="outlined"
                  color="neutral"
                  size="lg"
                  onClick={() => setOpen(false)}
                  sx={{ mr: 4, width: 100 }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  loading={saving}
                  variant="solid"
                  size="lg"
                  sx={{ width: 100 }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        </ModalDialog>
      </Modal>
      <Button onClick={() => setOpen(true)} startDecorator={<AddIcon />}>
        Invite New User
      </Button>
    </>
  );
}

export default InviteUserBtn;
