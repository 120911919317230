import { FormControl, FormHelperText, FormLabel, Input } from '@mui/joy';
import { Control, Controller } from 'react-hook-form';

interface Props {
  control: Control<any>;
  name: string;
  label?: string;
  caption?: string;
  placeholder?: string;
  rules?: any;
  sx?: any;
  loading?: boolean;
}

function TextFieldElement({
  control,
  name,
  label,
  caption,
  rules,
  placeholder,
  sx,
  loading,
}: Props) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <FormControl error={!!error} sx={{ mb: 4, ...sx }}>
          {label && <FormLabel>{label}</FormLabel>}
          <Input placeholder={placeholder} {...field} disabled={loading} />
          {(error || caption) && (
            <FormHelperText>{error?.message || caption}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}

export default TextFieldElement;
