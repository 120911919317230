import { gql, useMutation } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import { Box, Chip, IconButton, Tooltip, Typography } from '@mui/joy';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AllUserInvitesQueryQuery,
  SortBy,
  SortByDirection,
  UserInviteNode,
} from '../../__generated__/graphql';
import MyTable from '../../components/my-table/MyTable';
import { AllFilter } from '../../components/my-table/TableFilters';
import settings from '../../settings';
import { useManyRemote } from '../../shared/use-many-remote';
import { showMessage } from '../../store/snackbar-slice';
import useAllRoles from '../users/use-all-roles';
import DeleteUserInviteModal from './DeleteUserInviteModal';
import { allUserInvitesQuery, extractAllUserInvites } from './constants';

const resendUserInviteMutation = gql`
  mutation ResendUserInvite($_id: ID!) {
    resendUserInvite(_id: $_id)
  }
`;

const dateFormat = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'short',
  timeStyle: 'short',
});

const initialOrder: SortBy = {
  key: 'updatedAt',
  order: SortByDirection.Desc,
};

function UserInvitesTab() {
  const filters = useRef({});
  const sortBy = useRef<SortBy[]>([initialOrder]);

  const {
    loading,
    data: userInvites,
    search,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemote<AllUserInvitesQueryQuery, UserInviteNode>({
    query: allUserInvitesQuery,
    extract: extractAllUserInvites,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: 'userInvites',
  });

  const roles = useAllRoles();
  const rows: (UserInviteNode & { userRoles?: string[] })[] = useMemo(() => {
    if (userInvites && roles) {
      return userInvites.map((user) => {
        const userInviteRoles = user.roleIds?.map((r: any) => roles[r]?.name);
        return {
          ...user,
          userInviteRoles,
        };
      });
    }
    if (userInvites) {
      return userInvites;
    }
    return [];
  }, [userInvites, roles]);

  const allFilters: AllFilter[] = useMemo(() => [], []);

  const [resendUserInvite] = useMutation(resendUserInviteMutation);
  const [resending, setResending] = useState<any>();
  const dispatch = useDispatch();
  const onSend = useCallback(
    async (row: UserInviteNode) => {
      setResending(true);
      try {
        await resendUserInvite({
          variables: {
            _id: row._id,
          },
        });
        dispatch(
          showMessage({
            _id: 'resend-invite',
            severity: 'success',
            message: 'Success',
          }),
        );
      } catch (err: any) {
        console.error(err);
        dispatch(
          showMessage({
            _id: 'resend-invite',
            severity: 'danger',
            message: 'There was an error resending your invite',
          }),
        );
      }
      setResending(false);
    },
    [dispatch, resendUserInvite],
  );

  const [deleting, setDeleting] = useState<any>();
  const onDelete = (row: UserInviteNode) => setDeleting({ userInvite: row });
  const onFinishDelete = () => {
    setDeleting(undefined);
    refetch();
  };
  const onCancelDelete = () => setDeleting(undefined);

  const allColumns = useMemo(
    () => [
      {
        key: 'name',
        title: 'Name',
        component: (row: any) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography>{row.name}</Typography>
          </Box>
        ),
        sortable: true,
        width: 200,
      },
      {
        key: 'roles',
        title: 'Roles',
        component: (row: any) =>
          row.userInviteRoles?.map((userInviteRole: any, index: number) => (
            <Chip
              key={index}
              color="primary"
              sx={{ mr: 1, mb: 1 }}
              variant="soft"
            >
              {userInviteRole}
            </Chip>
          )),
        sortable: false,
        width: 200,
      },
      {
        key: 'email',
        title: 'Email',
        component: (row: any) => row.email,
        sortable: true,
        width: 200,
      },
      {
        key: 'createdAt',
        title: 'Created At',
        component: (row: any) => dateFormat.format(new Date(row.updatedAt)),
        sortable: true,
        width: 140,
      },
      {
        key: 'go',
        title: '',
        component: (row: any) => (
          <Box sx={{ display: 'flex' }}>
            <Tooltip title="Resend Invite">
              <IconButton
                onClick={() => onSend(row)}
                sx={{ mr: 0.5 }}
                loading={resending}
              >
                <SendIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Invite">
              <IconButton onClick={() => onDelete(row)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ),
        sortable: false,
        width: 100,
      },
    ],
    [],
  );

  return (
    <>
      <MyTable
        initialOrder={initialOrder}
        rows={rows}
        sortBy={sortBy}
        refetch={refetch}
        filters={filters}
        allFilters={allFilters}
        search={search}
        allColumns={allColumns}
        hasNextPage={!!hasNextPage}
        next={next}
        loading={loading}
      />
      <DeleteUserInviteModal
        visible={deleting}
        onCancel={onCancelDelete}
        onFinish={onFinishDelete}
      />
    </>
  );
}

export default UserInvitesTab;
