import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { WorkOrderStatusNode } from '../__generated__/graphql';

interface InitialState {
  [key: string]: {
    [key: string]: WorkOrderStatusNode;
  };
}

interface ReadWorkOrderStatusPayload {
  queryId: string;
  workOrderStatuses: WorkOrderStatusNode[];
}

interface UpdateWorkOrderStatusPayload {
  queryId: string;
  workOrderStatus: WorkOrderStatusNode;
}

interface DeleteWorkOrderStatusPayload {
  queryId: string;
  _id: string;
}

const initialState: InitialState = {
  default: {},
};

const workOrderStatusesSlice = createSlice({
  name: 'workOrderStatuses',
  initialState,
  reducers: {
    readWorkOrderStatuses: {
      reducer(state, action: PayloadAction<ReadWorkOrderStatusPayload>) {
        state[action.payload.queryId] = {};
        action.payload.workOrderStatuses.forEach((status) => {
          state[action.payload.queryId][status._id] = status;
        });
      },
      prepare(workOrderStatuses: WorkOrderStatusNode[], queryId = 'default') {
        return { payload: { workOrderStatuses, queryId } };
      },
    },
    updateWorkOrderStatus: {
      reducer(state, action: PayloadAction<UpdateWorkOrderStatusPayload>) {
        state[action.payload.queryId] = {
          ...state[action.payload.queryId],
          [action.payload.workOrderStatus._id]: action.payload.workOrderStatus,
        };
      },
      prepare(workOrderStatus: WorkOrderStatusNode, queryId = 'default') {
        return { payload: { workOrderStatus, queryId } };
      },
    },
    deleteWorkOrderStatus: {
      reducer(state, action: PayloadAction<DeleteWorkOrderStatusPayload>) {
        const { [action.payload._id]: removing, ...remaining } =
          state[action.payload.queryId];
        state[action.payload.queryId] = remaining;
      },
      prepare(_id: string, queryId = 'default') {
        return { payload: { _id, queryId } };
      },
    },
  },
});

export const {
  updateWorkOrderStatus,
  deleteWorkOrderStatus,
  readWorkOrderStatuses,
} = workOrderStatusesSlice.actions;
export default workOrderStatusesSlice.reducer;
