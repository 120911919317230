import { gql, useMutation } from '@apollo/client';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { IconButton, Tooltip } from '@mui/joy';
import { useCallback, useState } from 'react';
import CropPhotoModal from '../../components/photo/CropPhotoModal';
import { generateUuid } from '../../shared/utils';

const uploadVendorImageMutation = gql`
  mutation UploadVendorImage($image: VendorImageInput!) {
    uploadVendorImage(image: $image)
  }
`;

const myId = 'upload-vendor-photo';

interface Props {
  vendorId: string;
}

function UploadVendorPhotoButton({ vendorId }: Props) {
  const [uploaded, setUploaded] = useState<any>();
  const [uploadVendorImage] = useMutation(uploadVendorImageMutation) as any;

  const handleUploadClick = useCallback((event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = function (e) {
      setUploaded(reader.result);
    };
    reader.readAsDataURL(file);
  }, []);

  const onGo = useCallback(
    async ({ file, onProgress }: any) => {
      const photoId = generateUuid();
      await uploadVendorImage({
        variables: {
          image: {
            vendorId,
            fileId: photoId,
            filename: `vendor-${photoId}.jpg`,
            contentType: file.type,
            size: file.size,
            tags: ['Vendor Photo'],
            file: file,
          },
        },
        context: {
          fetchOptions: {
            onProgress: (percent: number) => onProgress(percent),
          },
        },
      });
    },
    [uploadVendorImage, vendorId],
  );
  const onDone = useCallback(() => {
    setUploaded(undefined);
  }, []);

  return (
    <>
      <Tooltip title="Upload Vendor Photo or Logo">
        <IconButton component="label" htmlFor={myId}>
          <CameraAltIcon />
          <input
            accept="image/*"
            id={myId}
            type="file"
            onChange={handleUploadClick}
            style={{ display: 'none' }}
          />
        </IconButton>
      </Tooltip>
      <CropPhotoModal
        uploaded={uploaded}
        setUploaded={setUploaded}
        onGo={onGo}
        onDone={onDone}
      />
    </>
  );
}

export default UploadVendorPhotoButton;
