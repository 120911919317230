import { Box, Button, Typography } from '@mui/joy';
import { useForm } from 'react-hook-form';
import { gql, useQuery } from '@apollo/client';
import { AllScTradesQuery } from '../../__generated__/graphql';
import { useManyRemote } from '../../shared/use-many-remote';
import { useEffect, useMemo } from 'react';
import AutocompleteFieldElement from '../../components/AutocompleteFieldElement';

export const allScTradesQuery = gql`
  query AllScTrades {
    allScTrades
  }
`;

export const extractAllLocations = (data: AllScTradesQuery) => data.allScTrades;

interface Props {
  defaultValues: any;
  title: string;
  name: string;
  label: string;
  caption?: string;
  placeholder?: string;
  rules?: any;
  onSubmit?: any;
  saving?: boolean;
  onCancel?: () => void;
}

function EditTradesInner({
  defaultValues,
  onSubmit,
  name,
  label,
  caption,
  placeholder,
  rules,
  saving,
  onCancel,
  title,
}: Props) {
  const { data, loading, error } = useQuery(allScTradesQuery, {
    fetchPolicy: 'cache-first',
  });
  useEffect(() => {
    if (error) {
      console.error(error.message);
    }
  }, [error]);
  const rows = useMemo(() => {
    return data?.allScTrades || [];
  }, [data]);

  const options = useMemo(() => {
    return rows.map((row: string) => ({
      key: row,
      label: row,
    }));
  }, [rows]);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<any>({
    defaultValues,
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography level="h3" sx={{ mb: 2, textAlign: 'center' }}>
        {title}
      </Typography>
      <AutocompleteFieldElement
        control={control}
        name={name}
        options={options}
        label={label}
        placeholder={placeholder}
        loading={loading}
        multiple={true}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
        <Button
          variant="outlined"
          color="neutral"
          size="lg"
          onClick={onCancel}
          sx={{ mr: 4, width: 100 }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          loading={saving}
          variant="solid"
          size="lg"
          sx={{ width: 100 }}
        >
          Save
        </Button>
      </Box>
    </form>
  );
}

export default EditTradesInner;
