import {
  Box,
  Dropdown,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Menu,
  MenuButton,
  MenuItem,
} from '@mui/joy';
import { Control, Controller } from 'react-hook-form';
import { ChromePicker } from 'react-color';
import React, { useCallback } from 'react';

interface InnerProps {
  value: any;
  onChange: any;
  disabled?: boolean;
}

const ColorPickerInner = React.forwardRef<HTMLElement, InnerProps>(
  function ColorPickerInner({
    value,
    onChange: parentOnChange,
    disabled,
  }: InnerProps) {
    const onChange = useCallback(
      (v: any) => {
        parentOnChange(v.hex);
      },
      [parentOnChange],
    );
    return (
      <Dropdown>
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={{
              height: '2.25rem',
              width: '2.25rem',
              mr: 1,
              borderRadius: 8,
              backgroundColor: value,
              border: '1px solid var(--joy-palette-neutral-outlinedBorder)',
            }}
          />
          <MenuButton sx={{ flex: 1 }} disabled={disabled}>
            Select Color
          </MenuButton>
        </Box>
        <Menu
          placement="top"
          sx={(theme) => {
            return {
              padding: 0,
              zIndex: theme.vars.zIndex.modal,
            };
          }}
        >
          {/* <MenuItem> */}
          <ChromePicker color={value} onChange={onChange} disableAlpha />
          {/* </MenuItem> */}
        </Menu>
      </Dropdown>
    );
  },
);

interface Props {
  control: Control<any>;
  name: string;
  label?: string;
  caption?: string;
  placeholder?: string;
  rules?: any;
  sx?: any;
  disabled?: boolean;
}

function ColorPickerFieldElement({
  control,
  name,
  label,
  caption,
  rules,
  placeholder,
  sx,
  disabled,
}: Props) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <FormControl error={!!error} sx={{ mb: 4, ...sx }}>
          {label && <FormLabel>{label}</FormLabel>}
          {/* <Input placeholder={placeholder} {...field} disabled={loading} /> */}
          <ColorPickerInner {...field} disabled={disabled} />
          {(error || caption) && (
            <FormHelperText>{error?.message || caption}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}

export default ColorPickerFieldElement;
