import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../store/root';

function useWorkOrder(_id: string | undefined, queryId: string) {
  const workOrder = useSelector((store: RootState) => {
    if (_id) {
      return store.workOrders[queryId]?.[_id];
    }
    return undefined;
  }, shallowEqual);
  return workOrder;
}

export default useWorkOrder;
