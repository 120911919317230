import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../store/root';

function useHistory(objectId: string, queryId: string = 'default') {
  const history = useSelector(
    (store: RootState) => store.history[queryId]?.[objectId],
    shallowEqual,
  );
  return history;
}

export default useHistory;
