import { useMemo } from 'react';
import { VendorInvoiceNode } from '../../__generated__/graphql';
import MyGridItem, { MyDetailItem } from '../../components/MyGridItem';
import { Box, Card, Chip, Grid, Link, Tooltip, Typography } from '@mui/joy';
import { VendorInvoiceStatuses } from './constants';
import EditSomeVendorInvoiceLine from './EditSomeVendorInvoiceLine';
import OpenButton, { OpenButtonProps } from '../../components/OpenButton';
import EditSelectInner from '../../components/edit-inner/EditSelectInner';
import EditTextInner from '../../components/edit-inner/EditTextInner';
import EditPaymentMethodInner from '../payment-methods/EditPaymentMethodInner';
import { notNullOrUndefined } from '../../shared/utils';
import EditMoneyInner from '../../components/edit-inner/EditMoneyInner';
import EditTextAreaInner from '../../components/edit-inner/EditTextAreaInner';
import EditDateInner from '../../components/edit-inner/EditDateInner';
import UploadVendorInvoiceAttachmentButton from './UploadVendorInvoiceAttachmentButton';
import DisplayAttachments from '../discussion/DisplayAttachments';
import { viVN } from '@mui/x-date-pickers/locales';

const moneyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const dateFormat = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'short',
  timeStyle: 'short',
});

interface Props {
  vendorInvoice: VendorInvoiceNode;
}

function MyOpenButton({ onOpen }: OpenButtonProps) {
  return (
    <OpenButton
      onOpen={onOpen}
      size="sm"
      sx={{
        '--IconButton-size': '1rem',
        '& .MuiSvgIcon-root': { height: 20, width: 20 },
        padding: 0.5,
        mr: 1,
      }}
    />
  );
}

function VendorInvoiceCard({ vendorInvoice }: Props) {
  const details = useMemo<MyDetailItem[] | undefined>(() => {
    if (vendorInvoice) {
      return [
        {
          key: 'usdCents',
          label: 'Amount',
          width: 4,
          lgWidth: 3,
          value: vendorInvoice.usdCents,
          component: (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <Box sx={{ flex: 1 }}>
                {vendorInvoice.usdCents && (
                  <Typography level="body-md" sx={{ mr: 2, pt: 0.5 }}>
                    {notNullOrUndefined(vendorInvoice.usdCents) &&
                      moneyFormat.format(vendorInvoice.usdCents / 100)}
                  </Typography>
                )}
              </Box>
              {vendorInvoice._id && (
                <EditSomeVendorInvoiceLine
                  _id={vendorInvoice._id}
                  onSaveCallback={(values: any) => ({
                    usdCents: Math.round(values.amount * 100),
                  })}
                  EditForm={
                    <EditMoneyInner
                      defaultValues={{
                        amount: notNullOrUndefined(vendorInvoice.usdCents)
                          ? vendorInvoice.usdCents / 100
                          : null,
                      }}
                      title="Update Amount"
                      name="amount"
                      label="Amount"
                      rules={{ required: 'This field is required' }}
                    />
                  }
                  OpenButton={<MyOpenButton />}
                />
              )}
            </Box>
          ),
        },

        {
          key: 'submittedAt',
          label: 'Submitted Date',
          width: 4,
          lgWidth: 3,
          component: (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <Box sx={{ flex: 1 }}>
                {vendorInvoice.submittedAt ? (
                  <Typography level="body-md" sx={{ mr: 2, pt: 0.5 }}>
                    {dateFormat.format(new Date(vendorInvoice.submittedAt))}
                  </Typography>
                ) : (
                  <Typography
                    color="neutral"
                    level="body-sm"
                    sx={{ mr: 2, pt: 0.5 }}
                  >
                    Not Specified
                  </Typography>
                )}
              </Box>
              {vendorInvoice._id && (
                <EditSomeVendorInvoiceLine
                  _id={vendorInvoice._id}
                  EditForm={
                    <EditDateInner
                      defaultValues={{
                        submittedAt: vendorInvoice.submittedAt || null,
                      }}
                      title="Change Submitted Date"
                      name="submittedAt"
                      label="Submitted Date"
                      rules={{ required: 'This field is required' }}
                    />
                  }
                  OpenButton={<MyOpenButton />}
                />
              )}
            </Box>
          ),
        },
        {
          key: 'dueAt',
          label: 'Due Date',
          width: 4,
          lgWidth: 3,
          component: (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <Box sx={{ flex: 1 }}>
                {vendorInvoice.dueAt ? (
                  <Typography level="body-md" sx={{ mr: 2, pt: 0.5 }}>
                    {dateFormat.format(new Date(vendorInvoice.dueAt))}
                  </Typography>
                ) : (
                  <Typography
                    color="neutral"
                    level="body-sm"
                    sx={{ mr: 2, pt: 0.5 }}
                  >
                    Not Specified
                  </Typography>
                )}
              </Box>
              {vendorInvoice._id && (
                <EditSomeVendorInvoiceLine
                  _id={vendorInvoice._id}
                  EditForm={
                    <EditDateInner
                      defaultValues={{
                        dueAt: vendorInvoice.dueAt || null,
                      }}
                      title="Change Due Date"
                      name="dueAt"
                      label="Due Date"
                      rules={{ required: 'This field is required' }}
                      caption="Vendor specified due date"
                    />
                  }
                  OpenButton={<MyOpenButton />}
                />
              )}
            </Box>
          ),
        },
        {
          key: 'status',
          label: 'Payment Status',
          value: vendorInvoice.status && vendorInvoice.status,
          width: 4,
          lgWidth: 3,
          component: (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <Box sx={{ flex: 1 }}>
                {vendorInvoice.status &&
                  VendorInvoiceStatuses[vendorInvoice.status] && (
                    <Chip
                      color={VendorInvoiceStatuses[vendorInvoice.status].color}
                      variant="solid"
                      sx={{ fontWeight: 600, mr: 2, mt: 0.5 }}
                    >
                      {VendorInvoiceStatuses[vendorInvoice.status].label}
                    </Chip>
                  )}
              </Box>
              {vendorInvoice._id && (
                <EditSomeVendorInvoiceLine
                  _id={vendorInvoice._id}
                  EditForm={
                    <EditSelectInner
                      defaultValues={{ status: vendorInvoice.status || null }}
                      title="Change Payment Status"
                      name="status"
                      label="Payment Status"
                      rules={{ required: 'This field is required' }}
                      options={Object.values(VendorInvoiceStatuses)}
                    />
                  }
                  OpenButton={<MyOpenButton />}
                />
              )}
            </Box>
          ),
        },
        {
          key: 'paymentLink',
          label: 'Payment Link',
          width: 4,
          lgWidth: 3,
          value: vendorInvoice.paymentLink,
          component: (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <Box sx={{ flex: 1, width: 'calc(100% - 40px)' }}>
                {vendorInvoice.paymentLink ? (
                  <Tooltip title={vendorInvoice.paymentLink}>
                    <Typography
                      level="body-md"
                      sx={{
                        mr: 2,
                        pt: 0.5,
                        width: '100%',
                      }}
                      component={Link}
                      href={vendorInvoice.paymentLink}
                      target="_blank"
                      noWrap
                    >
                      {vendorInvoice.paymentLink}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography
                    color="neutral"
                    level="body-sm"
                    sx={{ mr: 2, pt: 0.5 }}
                  >
                    Not Specified
                  </Typography>
                )}
              </Box>
              {vendorInvoice._id && (
                <EditSomeVendorInvoiceLine
                  _id={vendorInvoice._id}
                  EditForm={
                    <EditTextInner
                      defaultValues={{
                        paymentLink: vendorInvoice.paymentLink || '',
                      }}
                      title="Update Payment Link"
                      name="paymentLink"
                      label="Payment Link"
                      rules={{ required: 'This field is required' }}
                    />
                  }
                  OpenButton={<MyOpenButton />}
                />
              )}
            </Box>
          ),
        },
        {
          key: 'paymentMethod',
          label: 'Payment Method',
          width: 4,
          lgWidth: 3,
          value: vendorInvoice.paymentMethod,
          component: (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <Box sx={{ flex: 1 }}>
                {vendorInvoice.paymentMethod ? (
                  <Typography level="body-md" sx={{ mr: 2, pt: 0.5 }}>
                    {vendorInvoice.paymentMethod}
                  </Typography>
                ) : (
                  <Typography
                    color="neutral"
                    level="body-sm"
                    sx={{ mr: 2, pt: 0.5 }}
                  >
                    Not Specified
                  </Typography>
                )}
              </Box>
              {vendorInvoice._id && (
                <EditSomeVendorInvoiceLine
                  _id={vendorInvoice._id}
                  EditForm={
                    <EditPaymentMethodInner
                      defaultValues={{
                        paymentMethod: vendorInvoice.paymentMethod || '',
                      }}
                      title="Update Payment Method"
                      name="paymentMethod"
                      label="Payment Method"
                      rules={{ required: 'This field is required' }}
                    />
                  }
                  OpenButton={<MyOpenButton />}
                />
              )}
            </Box>
          ),
        },
        {
          key: 'paidAt',
          label: 'Paid Date',
          width: 4,
          lgWidth: 3,
          component: (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <Box sx={{ flex: 1 }}>
                {vendorInvoice.paidAt ? (
                  <Typography level="body-md" sx={{ mr: 2, pt: 0.5 }}>
                    {dateFormat.format(new Date(vendorInvoice.paidAt))}
                  </Typography>
                ) : (
                  <Typography
                    color="neutral"
                    level="body-sm"
                    sx={{ mr: 2, pt: 0.5 }}
                  >
                    Not Specified
                  </Typography>
                )}
              </Box>
              {vendorInvoice._id && (
                <EditSomeVendorInvoiceLine
                  _id={vendorInvoice._id}
                  EditForm={
                    <EditDateInner
                      defaultValues={{
                        paidAt: vendorInvoice.paidAt || null,
                      }}
                      title="Change Paid Date"
                      name="paidAt"
                      label="Paid Date"
                      rules={{ required: 'This field is required' }}
                    />
                  }
                  OpenButton={<MyOpenButton />}
                />
              )}
            </Box>
          ),
        },
        {
          key: 'notes',
          label: 'Notes',
          width: 12,
          lgWidth: 12,
          value: vendorInvoice.notes,
          component: (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <Box sx={{ flex: 1 }}>
                {(vendorInvoice.notes || 'Add some invoice notes...')
                  .split('\n')
                  .map((line, index) => (
                    <Typography key={index} level="body-md">
                      {line}
                    </Typography>
                  ))}
              </Box>
              {vendorInvoice._id && (
                <EditSomeVendorInvoiceLine
                  _id={vendorInvoice._id}
                  EditForm={
                    <EditTextAreaInner
                      defaultValues={{ notes: vendorInvoice.notes || '' }}
                      title="Edit Notes"
                      name="notes"
                      label="Notes"
                      placeholder="Add some invoice notes..."
                      rules={{ required: 'This field is required' }}
                    />
                  }
                  OpenButton={<MyOpenButton />}
                />
              )}
            </Box>
          ),
        },
      ];
    }
    return undefined;
  }, [vendorInvoice]);
  return (
    <Card
      sx={{
        p: 2,
        pt: 2,
        pb: 2,
        mr: 'auto',
        ml: 'auto',
        mb: 2,
      }}
    >
      {details ? (
        <Box>
          <Grid container spacing={1} sx={{ flexGrow: 1 }}>
            {details.map((item) => (
              <Grid key={item.key} xs={item.width} lg={item.lgWidth}>
                <MyGridItem item={item} />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 6,
            }}
          >
            <Typography sx={{ textAlign: 'center' }}>
              Nothing to see here.
            </Typography>
          </Box>
        </>
      )}
      {vendorInvoice.attachments && (
        <DisplayAttachments attachments={vendorInvoice.attachments} isRow />
      )}
      <Box sx={{ display: 'flex' }}>
        <UploadVendorInvoiceAttachmentButton
          vendorInvoiceId={vendorInvoice._id}
          myId="vendor-invoice"
        />
      </Box>
    </Card>
  );
}

export default VendorInvoiceCard;
