import {
  Avatar,
  Box,
  Card,
  Dropdown,
  IconButton,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/joy';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AllDiscussionsQueryQuery,
  AttachmentNode,
  DiscussionNode,
  SortBy,
  SortByDirection,
} from '../../__generated__/graphql';
import { useManyRemote } from '../../shared/use-many-remote';
import { readDiscussions } from '../../store/discussion-slice';
import CreateDiscussionForm from './CreateDiscussionForm';
import { allDiscussionsQuery, extractAllDiscussions } from './constants';
import useDiscussionObjectId from './use-discussion-obj-id';
import useDiscussionUpdates from './use-discussion-updates';
import useDiscussions from './use-discussions';
import EditDiscussionModal, {
  OpenDiscussionModelType,
} from './EditDiscussionModal';
import EditIcon from '@mui/icons-material/Edit';
import useProfile from '../../shared/use-profile';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteDiscussionModal, {
  DeleteDiscussionModelType,
} from './DeleteDiscussionModal';
import AttachmentCard from './AttachmentCard';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DisplayAttachments from './DisplayAttachments';

const initialOrder: SortBy = {
  key: 'createdAt',
  order: SortByDirection.Asc,
};

const dateFormat = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'short',
  timeStyle: 'short',
});

interface Props {
  filters: any;
}

const queryId = 'default';

interface OpenButtonProps {
  onOpen?: () => void;
}

function OpenButton({ onOpen }: OpenButtonProps) {
  return (
    <Tooltip title="Edit">
      <IconButton onClick={onOpen}>
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
}

interface DeleteButtonProps {
  onDelete?: () => void;
}

function DeleteButton({ onDelete }: DeleteButtonProps) {
  return (
    <Dropdown>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: 'plain', color: 'neutral' } }}
      >
        <MoreVertIcon />
      </MenuButton>
      <Menu placement="bottom-end">
        <MenuItem onClick={onDelete}>
          <ListItemDecorator>
            <DeleteIcon />
          </ListItemDecorator>{' '}
          Delete Comment
        </MenuItem>
      </Menu>
    </Dropdown>
  );
}

function ViewDiscussion({ filters }: Props) {
  const objectId = useDiscussionObjectId(filters);
  const sortBy = useRef<SortBy[]>([initialOrder]);
  const [editing, setEditing] = useState<OpenDiscussionModelType>(undefined);
  const [deleting, setDeleting] =
    useState<DeleteDiscussionModelType>(undefined);

  const {
    loading,
    data: rows,
    search,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemote<AllDiscussionsQueryQuery, DiscussionNode>({
    query: allDiscussionsQuery,
    extract: extractAllDiscussions,
    first: 10000,
    filters: filters,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });
  useDiscussionUpdates(filters);

  const dispatch = useDispatch();
  useEffect(() => {
    if (rows) {
      dispatch(readDiscussions(rows, objectId, queryId));
    }
  }, [rows, objectId, dispatch]);
  const discussions = useDiscussions(objectId, queryId);
  const profile = useProfile();

  return (
    <>
      {discussions?.map((row: DiscussionNode) => (
        <Card
          sx={{
            p: 2,
            mb: 2,
          }}
          key={row._id}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {row.fromVendor && (
              <>
                <Avatar sx={{ mr: 1 }}>{row.fromVendor.name[0]}</Avatar>
                <Typography>{row.fromVendor.name}</Typography>
              </>
            )}
            {!row.fromVendor && row.user && (
              <>
                <Avatar
                  src={row.user.profilePhotoThumbUrl || undefined}
                  sx={{ mr: 1 }}
                >
                  {row.user.name[0]}
                </Avatar>
                <Typography>{row.user.name}</Typography>
              </>
            )}
            <Box sx={{ flex: 1 }} />
            <Typography level="body-sm" sx={{ mr: 1 }}>
              {dateFormat.format(new Date(row.updatedAt))}
            </Typography>
            {row.userId === profile?._id && (
              <>
                <OpenButton
                  onOpen={() => setEditing({ _id: row._id, objectId, filters })}
                />
                <DeleteButton
                  onDelete={() => setDeleting({ discussion: row })}
                />
              </>
            )}
          </Box>

          <Box>
            {row.text
              ?.split('\n')
              .map((line, index) => (
                <Typography key={index}>{line}</Typography>
              ))}
          </Box>
          {row.attachments && (
            <DisplayAttachments attachments={row.attachments} />
          )}
        </Card>
      ))}
      <Card
        sx={{
          p: 2,
          mb: 2,
          backgroundColor: 'var(--joy-palette-background-body)',
        }}
        variant="outlined"
      >
        <CreateDiscussionForm filters={filters} refetch={refetch} />
      </Card>
      <EditDiscussionModal
        open={editing}
        setOpen={setEditing}
        refetch={refetch}
      />
      <DeleteDiscussionModal
        open={deleting}
        setOpen={setDeleting}
        refetch={refetch}
      />
    </>
  );
}

export default ViewDiscussion;
