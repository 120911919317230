import { gql, useMutation } from '@apollo/client';
import { Modal, ModalClose, ModalDialog } from '@mui/joy';
import React, { ReactElement, useCallback, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showMessage } from '../../store/snackbar-slice';

const updateProfileMutation = gql`
  mutation UpdateProfile($profile: ProfileInput!) {
    updateMyProfile(profile: $profile)
  }
`;

interface Props {
  EditForm: ReactElement;
  OpenButton: ReactElement;
}

function EditSomeProfileLine({ EditForm, OpenButton }: Props) {
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [updateProfile] = useMutation(updateProfileMutation);

  const dispatch = useDispatch();
  const onSubmit: SubmitHandler<any> = useCallback(
    async (values) => {
      setSaving(true);
      try {
        await updateProfile({
          variables: {
            profile: {
              ...values,
            },
          },
        });
        setOpen(false);
        dispatch(
          showMessage({
            _id: 'sign-in',
            severity: 'success',
            message: 'Success',
          }),
        );
      } catch (err: any) {
        console.error(err);
        dispatch(
          showMessage({
            _id: 'sign-in',
            severity: 'danger',
            message: 'There was an error saving your profile',
          }),
        );
      }
      setSaving(false);
    },
    [updateProfile, dispatch],
  );

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)} sx={{ zIndex: 10001 }}>
        <ModalDialog sx={{ p: 4, width: 400, maxWidth: '90uvw' }}>
          <ModalClose />
          {React.cloneElement(EditForm, {
            saving,
            onCancel: () => setOpen(false),
            onSubmit,
          })}
        </ModalDialog>
      </Modal>
      {React.cloneElement(OpenButton, {
        onOpen: () => setOpen(true),
      })}
    </>
  );
}

export default EditSomeProfileLine;
