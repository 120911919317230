import { gql } from '@apollo/client';

export const woVendorStatusesQuery = gql`
  query woVendorStatuses {
    woVendorStatuses {
      _id
      label
      desc
      color
      textColor
      colorPalette
      colorNames
      builtIn
      sort
    }
  }
`;
