import { Box, Divider, Typography } from '@mui/joy';
import MainPageTemplate from '../../components/MainPageTemplate';
import MondayWorkOrdersAll from './MondayWorkOrdersAll';

function AllWorkOrdersPage() {
  return (
    <MainPageTemplate title="Service Requests">
      <Box sx={{ pt: 2, pb: 2, pl: 2, pr: 2 }}>
        <Box sx={{ pl: 2, pr: 2 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              pt: 2,
              pb: 2,
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography level="h1">Service Requests</Typography>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ mb: 2 }} />
        <Box sx={{ pl: 2, pr: 2 }}>
          <MondayWorkOrdersAll />
        </Box>
      </Box>
    </MainPageTemplate>
  );
}

export default AllWorkOrdersPage;
