import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UserNode } from '../__generated__/graphql';

interface InitialState {
  [key: string]: {
    [key: string]: UserNode;
  };
}

interface UpdateUserPayload {
  queryId: string;
  user: UserNode;
}

interface DeleteUserPayload {
  queryId: string;
  _id: string;
}

const initialState: InitialState = {
  default: {},
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    updateUser: {
      reducer(state, action: PayloadAction<UpdateUserPayload>) {
        state[action.payload.queryId] = {
          ...state[action.payload.queryId],
          [action.payload.user._id]: action.payload.user,
        };
      },
      prepare(user: UserNode, queryId = 'default') {
        return { payload: { user, queryId } };
      },
    },
    deleteUser: {
      reducer(state, action: PayloadAction<DeleteUserPayload>) {
        const { [action.payload._id]: removing, ...remaining } =
          state[action.payload.queryId];
        state[action.payload.queryId] = remaining;
      },
      prepare(_id: string, queryId = 'default') {
        return { payload: { _id, queryId } };
      },
    },
  },
});

export const { updateUser, deleteUser } = usersSlice.actions;
export default usersSlice.reducer;
