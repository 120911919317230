import { gql, useQuery, useSubscription } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  WoVendorStatusNode,
  WoVendorStatusUpdatesSubscription,
} from '../../__generated__/graphql';
import { updateWoVendorStatusPalette } from '../../store/auth-slice';
import { RootState } from '../../store/root';
import {
  deleteWoVendorStatus,
  readWoVendorStatuses,
} from '../../store/wo-vendor-statuses-slice';
import { woVendorStatusesQuery } from './constants';

const woVendorStatusUpdatesSubscription = gql`
  subscription WoVendorStatusUpdates {
    woVendorStatusUpdates {
      _id
      crud
      new {
        _id
        label
        desc
        color
        textColor
        colorPalette
        colorNames
        builtIn
        sort
      }
    }
  }
`;

const colorPaletteNames = [
  '50',
  '100',
  '200',
  '300',
  '400',
  '500',
  '600',
  '700',
  '800',
  '900',
];

function useWoVendorStatusUpdates(queryId: string = 'default') {
  const result = useQuery(woVendorStatusesQuery, {
    fetchPolicy: 'network-only',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (result?.data?.woVendorStatuses) {
      dispatch(readWoVendorStatuses(result.data.woVendorStatuses), queryId);
    }
  }, [dispatch, queryId, result]);

  useSubscription<WoVendorStatusUpdatesSubscription>(
    woVendorStatusUpdatesSubscription,
    {
      fetchPolicy: 'no-cache',
      onSubscriptionData: ({ client, subscriptionData: result }) => {
        try {
          const update = result?.data?.woVendorStatusUpdates;
          if (update?.new) {
            switch (update.crud) {
              case 'insert':
                dispatch(readWoVendorStatuses(update.new, queryId));
                break;
              case 'update':
                dispatch(readWoVendorStatuses(update.new, queryId));
                break;
              case 'delete':
                dispatch(deleteWoVendorStatus(update._id, queryId));
                break;
              default:
                break;
            }
          }
        } catch (err) {
          console.error(err);
        }
      },
    },
  );

  const woVendorStatuses = useSelector(
    (store: RootState) => store.woVendorStatuses?.[queryId],
  );

  useEffect(() => {
    if (woVendorStatuses) {
      const statuses = Object.values(woVendorStatuses);
      dispatch(
        updateWoVendorStatusPalette({
          woVendorStatusPalette: {
            ...statuses.reduce((prev: any, curr: WoVendorStatusNode) => {
              prev[curr._id] = {};
              curr.colorPalette.forEach((color, index) => {
                prev[curr._id][colorPaletteNames[index]] = color;
              });
              Object.entries(curr.colorNames).forEach(([name, color]) => {
                prev[curr._id][name] = color;
              });
              return prev;
            }, {}),
          },
        }),
      );
    }
  }, [woVendorStatuses, dispatch]);
}

export default useWoVendorStatusUpdates;
