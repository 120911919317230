import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../store/root';

function useProfile() {
  const profile = useSelector(
    (store: RootState) => store.auth.profile,
    shallowEqual,
  );
  return profile;
}

export default useProfile;
