import VisibilityIcon from '@mui/icons-material/Visibility';
import { Avatar, Box, Chip, IconButton, Typography } from '@mui/joy';
import { useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  AllUsersQueryQuery,
  RoleNode,
  SortBy,
  SortByDirection,
  UserNode,
} from '../../__generated__/graphql';
import SelectFieldElement, {
  SelectFieldOption,
} from '../../components/SelectFieldElement';
import TextFieldElement from '../../components/TextFieldElement';
import settings from '../../settings';
import { useManyRemote } from '../../shared/use-many-remote';
import MyTable from '../../components/my-table/MyTable';
import { AllFilter } from '../../components/my-table/TableFilters';
import { allUsersQuery, extractAllUsers } from './constants';
import useAllRoles from './use-all-roles';

const dateFormat = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'short',
  timeStyle: 'short',
});

const initialOrder: SortBy = {
  key: 'updatedAt',
  order: SortByDirection.Desc,
};

function UsersTab() {
  const filters = useRef({});
  const sortBy = useRef<SortBy[]>([initialOrder]);

  const {
    loading,
    data: users,
    search,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemote<AllUsersQueryQuery, UserNode>({
    query: allUsersQuery,
    extract: extractAllUsers,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: 'users',
  });

  const roles = useAllRoles();
  const rows: (UserNode & { userRoles?: string[] })[] = useMemo(() => {
    if (users && roles) {
      return users.map((user) => {
        const userRoles = user.roleIds?.map((r: any) => roles[r]?.name);
        return {
          ...user,
          userRoles,
        };
      });
    }
    if (users) {
      return users;
    }
    return [];
  }, [users, roles]);

  const roleOptions: SelectFieldOption[] = useMemo(() => {
    if (roles) {
      return [
        { key: '__undefined__', label: 'Any' },
        ...Object.values(roles).map((role: RoleNode) => ({
          key: role._id,
          label: role.name,
        })),
      ];
    }
    return [];
  }, [roles]);
  const allFilters: AllFilter[] = useMemo(
    () => [
      {
        key: 'roleId',
        label: 'Role',
        Component: ({ control }: any) => (
          <SelectFieldElement
            control={control}
            name="roleId"
            options={roleOptions}
            label="Filter by role"
            placeholder="Select a role"
          />
        ),
        finish: (v) => (v === '__undefined__' ? null : v),
        display: (v) => `Role (${roles?.[v]?.name})`,
        defaultValue: null,
      },
      {
        key: 'username',
        label: 'Username',
        Component: ({ control }: any) => (
          <TextFieldElement
            control={control}
            name="username"
            label="Filter by username"
            placeholder="Enter a username"
          />
        ),
        finish: (v) => v || null,
        display: (v) => `Username (${v})`,
        defaultValue: null,
      },
    ],
    [roleOptions, roles],
  );

  const allColumns = useMemo(
    () => [
      {
        key: 'go',
        title: '',
        component: (row: any) => (
          <IconButton component={Link} to={`/users/view/${row._id}/history`}>
            <VisibilityIcon />
          </IconButton>
        ),
        sortable: false,
        width: 60,
      },
      {
        key: 'name',
        title: 'Name',
        component: (row: any) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar src={row.profilePhotoThumbUrl || undefined} sx={{ mr: 1 }}>
              {row.name[0]}
            </Avatar>
            <Typography>{row.name}</Typography>
          </Box>
        ),
        sortable: true,
        width: 200,
      },
      {
        key: 'roles',
        title: 'Roles',
        component: (row: any) =>
          row.userRoles?.map((userRole: any, index: number) => (
            <Chip
              key={index}
              color="primary"
              sx={{ mr: 1, mb: 1 }}
              variant="soft"
            >
              {userRole}
            </Chip>
          )),
        sortable: false,
        width: 200,
      },
      {
        key: 'email',
        title: 'Email',
        component: (row: any) => row.email,
        sortable: true,
        width: 200,
      },
      {
        key: 'phone',
        title: 'Phone',
        component: (row: any) => row.phone,
        sortable: false,
        width: 100,
      },
      {
        key: 'updatedAt',
        title: 'Updated At',
        component: (row: any) => dateFormat.format(new Date(row.updatedAt)),
        sortable: true,
        width: 140,
      },
    ],
    [],
  );

  return (
    <MyTable
      initialOrder={initialOrder}
      rows={rows}
      sortBy={sortBy}
      refetch={refetch}
      filters={filters}
      allFilters={allFilters}
      search={search}
      allColumns={allColumns}
      hasNextPage={!!hasNextPage}
      next={next}
    />
  );
}

export default UsersTab;
