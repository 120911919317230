import { Box } from '@mui/joy';
import { useRef } from 'react';
import {
  AllWorkOrdersQueryQuery,
  SortBy,
  SortByDirection,
  WorkOrderFilters,
  WorkOrderNode,
} from '../../__generated__/graphql';
import MyTable, { AllColumn } from '../../components/my-table/MyTable';
import { AllFilter } from '../../components/my-table/TableFilters';
import settings from '../../settings';
import { useManyRemote } from '../../shared/use-many-remote';
import { allWorkOrdersQuery, extractAllWorkOrders } from './constants';

const dateFormat = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'short',
  timeStyle: 'short',
});

const moneyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const initialOrder: SortBy = {
  key: 'scCallDate',
  order: SortByDirection.Desc,
};

interface Props {
  filters?: WorkOrderFilters;
  allColumns: AllColumn[];
  allFilters: AllFilter[];
  skip?: boolean;
}

function MondayWorkOrdersSingle({
  filters: parentFilters,
  allColumns,
  allFilters,
  skip,
}: Props) {
  const filters = useRef(parentFilters || {});
  const sortBy = useRef<SortBy[]>([initialOrder]);

  const {
    loading,
    data: rows,
    search,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemote<AllWorkOrdersQueryQuery, WorkOrderNode>({
    query: allWorkOrdersQuery,
    extract: extractAllWorkOrders,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: 'work-orders',
    skip,
  });

  return (
    <Box sx={{ pt: 1, pb: 1 }}>
      <MyTable
        initialOrder={initialOrder}
        rows={rows}
        sortBy={sortBy}
        refetch={refetch}
        filters={filters}
        parentFilters={parentFilters}
        allFilters={allFilters}
        search={search}
        allColumns={allColumns}
        hasNextPage={!!hasNextPage}
        next={next}
        loading={loading}
        hasFullText={true}
        height={window.innerHeight * 0.75}
        stickyHeader={true}
      />
    </Box>
  );
}

export default MondayWorkOrdersSingle;
