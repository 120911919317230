import { useSelector } from 'react-redux';
import { RootState } from '../../store/root';
import { WoVendorStatusNode } from '../../__generated__/graphql';

export function sortWoVendorStatuses(woStatuses: {
  [key: string]: WoVendorStatusNode;
}) {
  return Object.values(woStatuses).sort(
    (a, b) => (a.sort || -1) - (b.sort || -1),
  );
}

function useWoVendorStatuses(queryId: string = 'default') {
  const woVendorStatuses = useSelector(
    (store: RootState) => store.woVendorStatuses?.[queryId],
  );
  return woVendorStatuses;
}

export default useWoVendorStatuses;
