import {
  FormControl,
  FormHelperText,
  FormLabel,
  Select,
  Option,
  Autocomplete,
} from '@mui/joy';
import { useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';

export interface SelectFieldOption {
  key: any;
  label: string;
}

interface Props {
  control: Control<any>;
  name: string;
  options: SelectFieldOption[];
  label?: string;
  caption?: any;
  placeholder?: string;
  rules?: any;
  sx?: any;
  multiple?: any;
  loading?: any;
  disabled?: boolean;
  freeSolo?: boolean;
}

function AutoCompleteInner({
  options,
  value,
  onChange,
  placeholder,
  multiple,
  loading,
  disabled,
  freeSolo,
}: any) {
  const myOptions = useMemo(() => {
    return options.map((option: any) => ({
      label: option.label,
      id: option.key,
    }));
  }, [options]);
  const myValue = useMemo(() => {
    if (multiple) {
      return value
        ?.map((v: any) => {
          let r = myOptions.find((o: any) => o.id === v);
          if (v && !r && freeSolo) {
            r = {
              label: v,
              id: v,
            };
          }
          return r;
        })
        .filter((v: any) => v);
    }
    let r = myOptions.find((o: any) => o.id === value);
    if (value && !r && freeSolo) {
      r = {
        label: value,
        id: value,
      };
    }
    console.log('r', value, r);
    return r || null;
  }, [myOptions, value, multiple, freeSolo]);
  return (
    <Autocomplete
      value={myValue || null}
      onChange={(e, value) => {
        if (multiple) {
          onChange(
            value
              .map((v: any) => {
                if (v && freeSolo && !v.id) {
                  return v;
                }
                return v?.id;
              })
              .filter((v: any) => v),
          );
        } else {
          if (value && freeSolo && !value.id) {
            console.log('onChange', value);
            onChange(value);
          } else {
            onChange(value?.id);
          }
        }
        e.preventDefault();
      }}
      placeholder={placeholder}
      options={myOptions}
      multiple={multiple}
      loading={loading}
      disabled={disabled}
      freeSolo={freeSolo}
    />
  );
}

function AutocompleteFieldElement({
  control,
  name,
  options,
  label,
  caption,
  rules,
  placeholder,
  multiple,
  loading,
  sx,
  disabled,
  freeSolo,
}: Props) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl error={!!error} sx={{ mb: 2, ...sx }}>
          {label && <FormLabel>{label}</FormLabel>}
          <AutoCompleteInner
            options={options}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            multiple={multiple}
            loading={loading}
            disabled={disabled}
            freeSolo={freeSolo}
          />
          {(error || caption) && (
            <FormHelperText>{error?.message || caption}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}

export default AutocompleteFieldElement;
