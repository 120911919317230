// @ts-nocheck
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface RemoteQuery {
  _id: number;
  refetches: number;
  after: string | undefined;
}

export interface NextQuery {
  _id: number;
  after: string;
}

interface QueryPayload {
  queryId: string;
  query: RemoteQuery;
}

interface NextQueryPayload {
  queryId: string;
  query: NextQuery;
}

interface IncrementQueryPayload {
  queryId: string;
  _id: number;
}

interface QueryState {
  [key: string]: {
    [key: string]: RemoteQuery | undefined;
  };
}

const initialState: QueryState = {
  default: {},
};

const querySlice = createSlice({
  name: 'query',
  initialState,
  reducers: {
    updateRemoteQuery: {
      reducer(state, action: PayloadAction<QueryPayload>) {
        if (!state[action.payload.queryId]) {
          state[action.payload.queryId] = {};
        }
        state[action.payload.queryId][action.payload.query._id] = {
          ...action.payload.query,
        };
      },
      prepare(query: RemoteQuery, queryId = 'default') {
        return { payload: { query, queryId } };
      },
    },
    nextRemoteQuery: {
      reducer(state, action: PayloadAction<NextQueryPayload>) {
        if (!state[action.payload.queryId]) {
          state[action.payload.queryId] = {};
        }
        if (state[action.payload.queryId][action.payload.query._id]) {
          state[action.payload.queryId][action.payload.query._id].after =
            action.payload.query.after;
        }
      },
      prepare(query: NextQuery, queryId = 'default') {
        return { payload: { queryId, query } };
      },
    },
    incrementRemoteQuery: {
      reducer(state, action: PayloadAction<IncrementQueryPayload>) {
        if (!state[action.payload.queryId]) {
          state[action.payload.queryId] = {};
        }
        if (state[action.payload.queryId][action.payload._id]) {
          state[action.payload.queryId][action.payload._id].refetches += 1;
        }
      },
      prepare(_id: number, queryId = 'default') {
        return { payload: { queryId, _id } };
      },
    },
  },
});

export const { updateRemoteQuery, nextRemoteQuery, incrementRemoteQuery } =
  querySlice.actions;
export default querySlice.reducer;
