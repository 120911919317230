import { Button } from '@mui/joy';
import { useCallback, useState } from 'react';
import CropPhotoModal from '../../components/photo/CropPhotoModal';
import { gql, useMutation } from '@apollo/client';
import { generateUuid } from '../../shared/utils';

const uploadProfilePhotoMutation = gql`
  mutation UploadProfilePhoto($photo: ProfilePhotoInput!) {
    uploadProfilePhoto(photo: $photo)
  }
`;

function UploadProfilePhotoButton() {
  const [uploaded, setUploaded] = useState<any>();
  const [uploadProfilePhoto] = useMutation(uploadProfilePhotoMutation) as any;

  const handleUploadClick = useCallback((event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = function (e) {
      setUploaded(reader.result);
    };
    reader.readAsDataURL(file);
  }, []);

  const onGo = useCallback(
    async ({ file, onProgress }: any) => {
      const photoId = generateUuid();
      await uploadProfilePhoto({
        variables: {
          photo: {
            fileId: photoId,
            filename: `profile-${photoId}.jpg`,
            contentType: 'image/jpeg',
            size: file.size,
            tags: ['Profile Photo'],
            file: file,
          },
        },
        context: {
          fetchOptions: {
            onProgress: (percent: number) => onProgress(percent),
          },
        },
      });
    },
    [uploadProfilePhoto],
  );
  const onDone = useCallback(() => {
    setUploaded(undefined);
  }, []);

  return (
    <>
      <Button component="label" htmlFor="upload-input" variant="outlined">
        Upload Photo
        <input
          accept="image/*"
          id="upload-input"
          type="file"
          onChange={handleUploadClick}
          style={{ display: 'none' }}
        />
      </Button>
      <CropPhotoModal
        uploaded={uploaded}
        setUploaded={setUploaded}
        onGo={onGo}
        onDone={onDone}
      />
    </>
  );
}

export default UploadProfilePhotoButton;
