import { gql, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  ColorPaletteProp,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
  VariantProp,
} from '@mui/joy';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import DateFieldElement from '../../components/DateFieldElement';
import NumberFieldElement from '../../components/NumberFieldElement';
import SelectFieldElement from '../../components/SelectFieldElement';
import TextAreaFieldElement from '../../components/TextAreaFieldElement';
import TextFieldElement from '../../components/TextFieldElement';
import { generateUuid } from '../../shared/utils';
import { showMessage } from '../../store/snackbar-slice';
import SelectPaymentMethodFieldElement from '../payment-methods/SelectPaymentMethodFieldElement';
import { VendorInvoiceStatuses } from './constants';

const createVendorInvoiceMutation = gql`
  mutation CreateVendorInvoice($vendorInvoice: CreateVendorInvoiceInput!) {
    createVendorInvoice(vendorInvoice: $vendorInvoice)
  }
`;

interface Props {
  workOrderId: Maybe<string>;
  vendorId: Maybe<string>;
  btnText: string;
  btnColor?: ColorPaletteProp | undefined;
  btnVariant?: VariantProp | undefined;
}

function AddVendorInvoiceModal({
  workOrderId,
  vendorId,
  btnText,
  btnColor,
  btnVariant,
}: Props) {
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [createVendorInvoice] = useMutation(createVendorInvoiceMutation);

  const dispatch = useDispatch();
  const onSubmit: SubmitHandler<any> = useCallback(
    async (_values) => {
      setSaving(true);
      try {
        const { amount, ...values } = _values;
        await createVendorInvoice({
          variables: {
            vendorInvoice: {
              _id: generateUuid(),
              vendorId,
              workOrderId,
              usdCents: Math.round(amount * 100),
              ...values,
            },
          },
        });
        dispatch(
          showMessage({
            _id: 'create-vendor-invoice',
            severity: 'success',
            message: 'Success',
          }),
        );
        setOpen(false);
      } catch (err: any) {
        console.error(err);
        dispatch(
          showMessage({
            _id: 'create-vendor-invoice',
            severity: 'danger',
            message: 'There was an error saving your vendor invoice',
          }),
        );
      }
      setSaving(false);
    },
    [createVendorInvoice, dispatch, vendorId, workOrderId],
  );

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    reset,
  } = useForm<any>({
    defaultValues: {
      submittedAt: new Date().toISOString(),
      status: null,
    },
  });

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  const isPaid = watch('status') === 'PAID';

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          sx={{ p: 4, width: 600, maxWidth: '90uvw', overflow: 'scroll' }}
        >
          <ModalClose />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography level="h3" sx={{ mb: 2, textAlign: 'center' }}>
              Add Vendor Invoice
            </Typography>
            <DateFieldElement
              control={control}
              name="submittedAt"
              label="Submitted On"
              sx={{ mb: 2 }}
              loading={saving}
            />
            <DateFieldElement
              control={control}
              name="dueAt"
              label="Due On"
              caption="Vendor specified due date"
              sx={{ mb: 2 }}
              loading={saving}
            />
            <NumberFieldElement
              control={control}
              name="amount"
              label="Amount"
              placeholder="$1.00"
              sx={{ mb: 2 }}
              rules={{ required: 'This field is required' }}
              loading={saving}
            />
            <TextFieldElement
              control={control}
              name="paymentLink"
              label="Payment Link"
              placeholder="Add a payment link"
              sx={{ mb: 2 }}
              loading={saving}
            />
            <SelectPaymentMethodFieldElement
              control={control}
              name="paymentMethod"
              label="Payment Method"
              placeholder="Add a payment method"
              disabled={saving}
            />
            <SelectFieldElement
              control={control}
              name="status"
              options={Object.values(VendorInvoiceStatuses)}
              label="Payment Status"
              placeholder="Select a status"
              rules={{ required: 'This field is required' }}
              disabled={saving}
            />
            {isPaid && (
              <DateFieldElement
                control={control}
                name="paidAt"
                label="Paid On"
                sx={{ mb: 2 }}
                loading={saving}
              />
            )}
            <TextAreaFieldElement
              control={control}
              name="notes"
              label="Notes"
              placeholder="Add some notes... Vendor requested invoice be paid by..."
              sx={{ mb: 2 }}
              disabled={saving}
            />
            <Typography sx={{ textAlign: 'center' }}>
              You will be able to add attachments after saving.
            </Typography>
            <Box
              sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}
            >
              <Button
                variant="outlined"
                color="neutral"
                disabled={saving}
                size="lg"
                onClick={() => setOpen(false)}
                sx={{ mr: 4, width: 100 }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                loading={saving}
                variant="solid"
                size="lg"
                sx={{ width: 100 }}
              >
                Save
              </Button>
            </Box>
          </form>
        </ModalDialog>
      </Modal>
      {vendorId && (
        <Button
          color={btnColor}
          onClick={() => setOpen(true)}
          variant={btnVariant}
        >
          {btnText}
        </Button>
      )}
    </>
  );
}

export default AddVendorInvoiceModal;
