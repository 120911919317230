import { Box, Button, Divider, Typography } from '@mui/joy';
import ColorSchemeToggle from '../components/ColorSchemeToggle';
import MainPageTemplate from '../components/MainPageTemplate';
import useProfile from '../shared/use-profile';

function SettingsPage() {
  const profile = useProfile();
  return (
    <MainPageTemplate title="Settings">
      <Box sx={{ p: 2 }}>
        <Typography level="h1" sx={{ mb: 1 }}>
          Settings
        </Typography>
        <Divider />
        <Box
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'column',
            // background: 'linear-gradient(45deg, rgba(242,242,242,1) 40%, rgba(255,255,255,1) 60%, rgba(255,235,235,1) 100%)',
          }}
        >
          <Box sx={{ mb: 2 }}>
            <ColorSchemeToggle sx={{ ml: 'auto' }} />
          </Box>
          {profile?._id === 'QbYk7TyPZXkKmB5EKqSazF' && (
            <Box>
              <Button
                onClick={() => {
                  throw new Error('Ahh');
                }}
              >
                Error
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </MainPageTemplate>
  );
}

export default SettingsPage;
