import { gql, useSubscription } from '@apollo/client';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/root';
import { updateProfile } from '../store/auth-slice';

export const AddressAttributes = gql`
  {
    address1
    address2
    city
    state
    county
    country
    zipCode
  }
`;

export const ProfileAttributes = gql`
  {
    _id
    username
    name
    roleIds
    email
    emailVerified
    phone
    phoneVerified
    address ${AddressAttributes}
    profilePhotoId
    profilePhotoThumbUrl
    createdAt
    updatedAt
  }
`;

const profileUpdatesSubscription = gql`
  subscription ProfileUpdates {
    myProfileUpdates {
      _id
      crud
      new {
        _id
        username
        name
        roleIds
        email
        emailVerified
        phone
        phoneVerified
        address {
          address1
          address2
          city
          state
          county
          country
          zipCode
        }
        profilePhotoId
        profilePhotoThumbUrl
        createdAt
        updatedAt
      }
    }
  }
`;

function useProfileUpdates() {
  const token = useSelector(
    (store: RootState) => store.auth.token,
    shallowEqual,
  );
  const dispatch = useDispatch();
  useSubscription(profileUpdatesSubscription, {
    skip: !token,
    variables: {},
    fetchPolicy: 'no-cache',
    onSubscriptionData: ({ client, subscriptionData: result }) => {
      try {
        const update = result?.data?.myProfileUpdates;
        if (update) {
          switch (update.crud) {
            case 'update':
              dispatch(updateProfile({ profile: update.new }));
              break;
            default:
              break;
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
}

export default useProfileUpdates;
