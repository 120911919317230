import { gql, useMutation } from '@apollo/client';
import { Modal, ModalClose, ModalDialog } from '@mui/joy';
import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showMessage } from '../../store/snackbar-slice';
import SomeStatusForm from '../some-status/SomeStatusForm';
import { makeColorNames } from '../some-status/utils';

export const updateWoVendorStatusMutation = gql`
  mutation EditWoVendorStatus($woVendorStatus: UpdateWoVendorStatusInput!) {
    updateWoVendorStatus(woVendorStatus: $woVendorStatus)
  }
`;

interface Props {
  visible: any;
  onFinish: () => void;
  onCancel: () => void;
}

function EditWoVendorStatusModal({ visible, onFinish, onCancel }: Props) {
  const { woVendorStatus } = visible || {};
  const [saving, setSaving] = useState(false);
  const [updateWoVendorStatus] = useMutation(updateWoVendorStatusMutation);
  const [colorPalette, setColorPalette] = useState<string[]>();

  const dispatch = useDispatch();
  const onSubmit: SubmitHandler<any> = useCallback(
    async (values) => {
      setSaving(true);
      try {
        if (!colorPalette) {
          throw new Error('Color palette is undefined');
        }
        await updateWoVendorStatus({
          variables: {
            woVendorStatus: {
              _id: woVendorStatus._id,
              ...values,
              colorPalette,
              colorNames: makeColorNames(
                colorPalette,
                values.color,
                values.textColor,
              ),
              builtIn: woVendorStatus.builtIn,
              sort: woVendorStatus.sort,
            },
          },
        });
        dispatch(
          showMessage({
            _id: 'update-payment-method',
            severity: 'success',
            message: 'Success',
          }),
        );
        onFinish();
      } catch (err: any) {
        console.error(err);
        dispatch(
          showMessage({
            _id: 'update-payment-method',
            severity: 'danger',
            message: 'There was an error saving your status',
          }),
        );
      }
      setSaving(false);
    },
    [updateWoVendorStatus, woVendorStatus, onFinish, dispatch, colorPalette],
  );

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
  } = useForm<any>({
    defaultValues: {
      label: '',
    },
  });

  useEffect(() => {
    if (woVendorStatus) {
      setValue('label', woVendorStatus.label);
      setValue('desc', woVendorStatus.desc);
      setValue('color', woVendorStatus.color);
      setValue('textColor', woVendorStatus.textColor);
    }
  }, [woVendorStatus, setValue]);

  return (
    <>
      <Modal open={!!visible} onClose={onCancel}>
        <ModalDialog sx={{ p: 4, width: 400, maxWidth: '90uvw' }}>
          <ModalClose />
          <SomeStatusForm
            title="Edit Status"
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            control={control}
            saving={saving}
            watch={watch}
            colorPalette={colorPalette}
            setColorPalette={setColorPalette}
            onCancel={onCancel}
          />
        </ModalDialog>
      </Modal>
    </>
  );
}

export default EditWoVendorStatusModal;
