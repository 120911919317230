import { Box, Divider, Typography } from '@mui/joy';
import MainPageTemplate from '../../components/MainPageTemplate';
import VendorsTab from './VendorsTab';
import CreateVendorButton from './CreateVendorButton';

function AllVendorsPage() {
  return (
    <MainPageTemplate title="Vendors">
      <Box sx={{ pt: 2, pb: 2, pl: 2, pr: 2 }}>
        <Box sx={{ pl: 2, pr: 2 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              pt: 2,
              pb: 2,
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography level="h1">Vendors</Typography>
            </Box>
            <CreateVendorButton />
          </Box>
        </Box>
        <Divider sx={{ mb: 2 }} />
        <Box sx={{ pl: 2, pr: 2 }}>
          <VendorsTab />
        </Box>
      </Box>
    </MainPageTemplate>
  );
}

export default AllVendorsPage;
