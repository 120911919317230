import { useMemo } from 'react';
import { AllColumn } from '../../components/my-table/MyTable';
import { Box, Chip, IconButton, Tooltip, Typography } from '@mui/joy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DisplayLocationSimple } from './DisplayLocation';
import { Link } from 'react-router-dom';
import { DisplayVendorSimple } from './DisplayVendor';
import useWorkOrderStatuses from '../work-order-statuses/use-work-order-statuses';
import useWoVendorStatuses from '../wo-vendor-statuses/use-work-order-statuses';

const shortDateFormat = new Intl.DateTimeFormat('en-US', {
  month: 'short',
  day: 'numeric',
});

function useWoColumns(): AllColumn[] {
  const woStatuses = useWorkOrderStatuses();
  const woVendorStatuses = useWoVendorStatuses();
  const allColumns: AllColumn[] = useMemo(
    () => [
      {
        key: 'go',
        title: '',
        component: (row: any) => {
          return (
            <IconButton
              component={Link}
              to={`/work-orders/view/${row._id}/discussion`}
            >
              <VisibilityIcon />
            </IconButton>
          );
        },
        sortable: false,
        width: 60,
        style: (row: any) => {
          const color = woStatuses[row.status]?._id || 'neutral';
          return {
            borderLeft: `8px solid var(--joy-palette-${color}-outlinedBorder)`,
            zIndex: 1,
          };
        },
      },
      {
        key: 'scId',
        title: 'Number',
        component: (row: any) => row.scId,
        sortable: true,
        width: 100,
      },
      {
        key: 'city',
        title: 'City',
        component: (row: any) => {
          const loc = [
            row.location?.scAddress?.city,
            row.location?.scAddress?.state,
          ]
            .filter((a) => a)
            .join(', ');
          if (loc) {
            return (
              <Tooltip
                title={
                  <DisplayLocationSimple location={row.location} isTooltip />
                }
              >
                <Typography fontWeight={600}>{loc}</Typography>
              </Tooltip>
            );
          }
          return null;
        },
        sortable: true,
        width: 180,
      },
      {
        key: 'storeId',
        title: 'Store ID',
        component: (row: any) => (
          <Tooltip
            title={<DisplayLocationSimple location={row.location} isTooltip />}
          >
            <Typography fontWeight={600}>{row.location?.storeId}</Typography>
          </Tooltip>
        ),
        sortable: false,
        width: 100,
      },
      {
        key: 'scCaller',
        title: 'Caller',
        component: (row: any) => (
          <Typography fontWeight={400}>{row.scCaller}</Typography>
        ),
        sortable: false,
        width: 200,
      },
      {
        key: 'timeline',
        title: 'Timeline',
        component: (row: any) => {
          const text = [
            row.scCallDate && shortDateFormat.format(new Date(row.scCallDate)),
            row.scExpirationDate &&
              shortDateFormat.format(new Date(row.scExpirationDate)),
          ]
            .filter((a) => a)
            .join(' - ');
          const color = (woStatuses[row.status]?._id as any) || 'neutral';

          return (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Chip color={color} variant="solid" sx={{ mr: 0.5, mb: 0.5 }}>
                {text}
              </Chip>
            </Box>
          );
        },
        sortable: false,
        width: 180,
      },
      {
        key: 'scPriority',
        title: 'Priority',
        component: (row: any) => (
          <Typography
            fontWeight={600}
            textAlign="center"
            sx={{ color: 'white' }}
          >
            {row.scPriority}
          </Typography>
        ),
        sortable: true,
        width: 120,
        style: (row: any) => ({
          backgroundColor:
            row.scPriority?.toLowerCase().includes('low') ||
            row.scPriority?.toLowerCase().includes('normal')
              ? 'var(--joy-palette-invoiceSubmitted-600)'
              : 'var(--joy-palette-danger-700)',
        }),
      },
      {
        key: 'status',
        title: 'Progress Status',
        component: (row: any) => {
          return (
            row.status && (
              <Typography
                sx={{
                  textAlign: 'center',
                  fontWeight: 600,
                  color: `var(--joy-palette-${row.status}-solidColor)`,
                }}
              >
                {woStatuses[row.status]?.label}
              </Typography>
            )
          );
        },
        sortable: false,
        width: 200,
        style: (row: any) => {
          return {
            backgroundColor: `var(--joy-palette-${row.status}-solidBg`,
          };
        },
      },
      {
        key: 'tags',
        title: 'Tags',
        component: (row: any) => (
          <Box
            sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}
          >
            {row.tags &&
              row.tags.length > 0 &&
              row.tags.map((tag: any) => (
                <Chip
                  key={tag}
                  sx={{ mr: 0.5, mb: 0.5 }}
                  variant="solid"
                  color="primary"
                >
                  {tag}
                </Chip>
              ))}
          </Box>
        ),
        sortable: false,
        width: 200,
      },
      {
        key: 'scTrade',
        title: 'Trade',
        component: (row: any) => row.scTrade,
        sortable: true,
        width: 240,
      },
      {
        key: 'vendorStatus',
        title: 'Vendor Status',
        component: (row: any) => {
          return (
            row.vendorStatus && (
              <Typography
                sx={{
                  textAlign: 'center',
                  fontWeight: 600,
                  color: `var(--joy-palette-${row.vendorStatus}-solidColor)`,
                }}
              >
                {woVendorStatuses[row.vendorStatus]?.label}
              </Typography>
            )
          );
        },
        sortable: false,
        width: 200,
        style: (row: any) => {
          return {
            backgroundColor: `var(--joy-palette-${row.vendorStatus}-solidBg)`,
          };
        },
      },
      {
        key: 'vendorId',
        title: 'Vendor',
        component: (row: any) =>
          row.vendor && <DisplayVendorSimple vendor={row.vendor} />,
        sortable: false,
        width: 240,
      },
      {
        key: 'scDesc',
        title: 'Problem',
        component: (row: any) => {
          const slotProps: any = {
            root: (props: any) => ({
              ...props,
              sx: {
                ...props.sx,
                width: 400,
              },
            }),
          };
          return (
            <Tooltip title={row.scDesc} slotProps={slotProps}>
              <Typography noWrap>{row.scDesc}</Typography>
            </Tooltip>
          );
        },
        sortable: true,
        width: 400,
      },
      {
        key: 'notes',
        title: 'Notes',
        component: (row: any) => {
          const slotProps: any = {
            root: (props: any) => ({
              ...props,
              sx: {
                ...props.sx,
                width: 200,
              },
            }),
          };
          return (
            <Tooltip title={row.notes} slotProps={slotProps}>
              <Typography noWrap>{row.notes}</Typography>
            </Tooltip>
          );
        },
        sortable: true,
        width: 400,
      },
      {
        key: 'scResolution',
        title: 'Resolution',
        component: (row: any) => {
          const slotProps: any = {
            root: (props: any) => ({
              ...props,
              sx: {
                ...props.sx,
                width: 400,
              },
            }),
          };
          return (
            <Tooltip title={row.scResolution} slotProps={slotProps}>
              <Typography noWrap>{row.scResolution}</Typography>
            </Tooltip>
          );
        },
        sortable: true,
        width: 400,
      },
    ],
    [woStatuses, woVendorStatuses],
  );
  return allColumns;
}

export default useWoColumns;
