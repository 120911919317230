import { gql, useMutation } from '@apollo/client';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button } from '@mui/joy';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showMessage } from '../../store/snackbar-slice';

export const refreshWorkOrderFromServiceChannelMutation = gql`
  mutation RefreshWorkOrderFromServiceChannel($_id: ID!) {
    refreshWorkOrderFromServiceChannel(_id: $_id)
  }
`;

interface Props {
  workOrderId: string;
}

function RefreshFromSCButton({ workOrderId }: Props) {
  const [sending, setSending] = useState(false);
  const [refreshWorkOrderFromServiceChannel] = useMutation(
    refreshWorkOrderFromServiceChannelMutation,
  );

  const dispatch = useDispatch();
  const onSubmit = useCallback(async () => {
    setSending(true);
    try {
      await refreshWorkOrderFromServiceChannel({
        variables: {
          _id: workOrderId,
        },
      });
      dispatch(
        showMessage({
          _id: 'refresh-from-sc',
          severity: 'success',
          message: 'Success',
        }),
      );
    } catch (err: any) {
      console.error(err);
      dispatch(
        showMessage({
          _id: 'refresh-from-sc',
          severity: 'danger',
          message: 'There was an error sending your status request',
        }),
      );
    }
    setSending(false);
  }, [refreshWorkOrderFromServiceChannel, dispatch, workOrderId]);

  return (
    <Button
      startDecorator={<RefreshIcon />}
      onClick={onSubmit}
      loading={sending}
      variant="outlined"
    >
      Refresh from SC
    </Button>
  );
}

export default RefreshFromSCButton;
