import { gql, useMutation } from '@apollo/client';
import { Box, Button, Card, Typography } from '@mui/joy';
import { useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../assets/long-logo.svg';
import PublicPageTemplate from '../../components/PublicPageTemplate';
import { showMessage } from '../../store/snackbar-slice';
import WorkOrderDetailsTable from './WorkOrderDetailsTable';
import useVendorRequestDetails from './use-vendor-request-details';
import TextAreaFieldElement from '../../components/TextAreaFieldElement';

const rejectVendorRequestMutation = gql`
  mutation RejectVendorRequest($token: String!, $reason: String) {
    rejectVendorRequest(token: $token, reason: $reason)
  }
`;

function RejectVendorRequest() {
  const { token } = useParams();
  const [saving, setSaving] = useState(false);
  const [rejectVendorRequest] = useMutation(rejectVendorRequestMutation);
  const { workOrder, loading } = useVendorRequestDetails(token);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  // const onSubmit: SubmitHandler<Inputs> = (data) => console.log(data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<any> = useCallback(
    async (values) => {
      setSaving(true);
      try {
        await rejectVendorRequest({
          variables: {
            token,
            reason: values.reason,
          },
        });
        dispatch(
          showMessage({
            _id: 'reject-vendor-request',
            severity: 'success',
            message: 'Success',
          }),
        );
        navigate(`/service-request/rejected/${token}`);
      } catch (err: any) {
        console.error(err);
        if (err.message.includes('Not Authorized')) {
          dispatch(
            showMessage({
              _id: 'reject-vendor-request',
              severity: 'danger',
              message: err.message,
            }),
          );
        } else {
          dispatch(
            showMessage({
              _id: 'reject-vendor-request',
              severity: 'danger',
              message: 'There was an error rejecting your service request.',
            }),
          );
        }
      }
      setSaving(false);
    },
    [rejectVendorRequest, dispatch, navigate, token],
  );

  return (
    <PublicPageTemplate title="Reject Service Request">
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          minHeight: '100%',
          flexDirection: 'column',
        }}
      >
        <img
          src={logo}
          alt="Mahathey Contracting"
          style={{
            marginTop: 80,
            width: 400,
            maxWidth: '90vw',
            marginBottom: 40,
          }}
        />
        <Card sx={{ width: 600, maxWidth: '90vw', p: 4, mb: 8 }}>
          <Typography level="h1" style={{ textAlign: 'center' }}>
            Decline Service Request
          </Typography>
          <Typography level="h3" style={{ textAlign: 'center' }}>
            {workOrder?.vendor?.name}
          </Typography>
          <Typography style={{ textAlign: 'center' }}>
            Click "Decline" below to decline this service request.
          </Typography>
          <WorkOrderDetailsTable workOrder={workOrder} loading={loading} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography sx={{ textAlign: 'center' }}>
              Please let us know why your are rejecting this service request.
            </Typography>
            <Typography sx={{ textAlign: 'center', mb: 1 }}>
              Thank you.
            </Typography>
            <TextAreaFieldElement
              control={control}
              name="reason"
              placeholder="I'm traveling...  This is outside my service area..."
              minRows={3}
            />
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                type="submit"
                loading={loading || saving}
                color="danger"
                size="lg"
                sx={{ width: 200 }}
              >
                Decline
              </Button>
            </Box>
          </form>
        </Card>
      </Box>
    </PublicPageTemplate>
  );
}

export default RejectVendorRequest;
