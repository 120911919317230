import { Box, Skeleton, Table, Typography } from '@mui/joy';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useMemo } from 'react';
import { WorkOrderNode } from '../../__generated__/graphql';
import { DisplayLocationSimple } from '../work-orders/DisplayLocation';

const dateFormat = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'short',
  timeStyle: 'short',
});

interface Props {
  workOrder: Maybe<WorkOrderNode>;
  loading: boolean;
}

function WorkOrderDetailsTable({ workOrder, loading }: Props) {
  const allColumns = useMemo(
    () => [
      {
        key: 'label',
        component: (row: any) => <Typography>{row.label}</Typography>,
        sortable: false,
        width: 200,
      },
      {
        key: 'value',
        component: (row: any) => row.component,
        sortable: false,
      },
    ],
    [],
  );

  const rows = useMemo(() => {
    if (workOrder) {
      return [
        {
          key: '_id',
          label: 'Service Request No.',
          component: <Typography>{workOrder._id}</Typography>,
        },
        {
          key: 'desc',
          label: 'Description',
          component: <Typography>{workOrder.scDesc}</Typography>,
        },
        {
          key: 'notes',
          label: 'Notes',
          component: <Typography>{workOrder.notes}</Typography>,
        },
        {
          key: 'location',
          label: 'Location',
          component: workOrder.location && (
            <DisplayLocationSimple location={workOrder.location} />
          ),
        },
        {
          key: 'scCaller',
          label: 'Caller',
          component: <Typography>{workOrder.scCaller}</Typography>,
        },
        {
          key: 'scTrade',
          label: 'Trade',
          component: <Typography>{workOrder.scTrade}</Typography>,
        },
        {
          key: 'scCategory',
          label: 'Category',
          component: <Typography>{workOrder.scCategory}</Typography>,
        },
        {
          key: 'scPriority',
          label: 'Priority',
          component: <Typography>{workOrder.scPriority}</Typography>,
        },
        {
          key: 'scCallDate',
          label: 'Call Date',
          component: (
            <Typography>
              {dateFormat.format(new Date(workOrder.scCallDate))}
            </Typography>
          ),
        },
        {
          key: 'scScheduledDate',
          label: 'Scheduled Date',
          component: (
            <Typography>
              {dateFormat.format(new Date(workOrder.scScheduledDate))}
            </Typography>
          ),
        },
        {
          key: 'scExpirationDate',
          label: 'Expiration Date',
          component: (
            <Typography>
              {dateFormat.format(new Date(workOrder.scExpirationDate))}
            </Typography>
          ),
        },
      ];
    }
    return [];
  }, [workOrder]);

  return (
    <Table
      borderAxis="xBetween"
      color="neutral"
      variant="plain"
      sx={{ mb: 2 }}
      // noWrap
      // sx={{ minWidth: 1000, '& thead th:nth-child(2)': { width: '600px' } }}
      // sx={{ ...columnWidths }}
    >
      <tbody>
        {rows.length === 0 && (
          <>
            {loading ? (
              Array(10)
                .fill(0)
                .map((_, index) => (
                  <tr key={index}>
                    {allColumns.map((col: any, index: number) => (
                      <td key={index}>
                        <Skeleton
                          variant="rectangular"
                          height={24}
                          sx={{ mb: 1, mt: 1 }}
                        />
                      </td>
                    ))}
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan={allColumns.length}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      p: 6,
                    }}
                  >
                    <Typography sx={{ textAlign: 'center' }}>
                      Nothing to see here.
                    </Typography>
                  </Box>
                </td>
              </tr>
            )}
          </>
        )}
        {rows.map((row) => (
          <tr key={row.key}>
            {allColumns.map((col: any) => (
              <td style={{ width: col.width }} key={col.key}>
                {col.component(row)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default WorkOrderDetailsTable;
