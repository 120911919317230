const settings = {
  apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:8000/graphql/',
  wsUrl: process.env.REACT_APP_API_WS_URL || 'ws://localhost:8000/graphql/',
  querySize: 100,
  themeColors: {
    primary: {
      50: '#fdebee',
      100: '#fbcdd2',
      200: '#e9999b',
      300: '#dd7375',
      400: '#e75453',
      500: '#eb443b',
      600: '#dc3b39',
      700: '#ca3133',
      800: '#bd2b2d',
      900: '#ae2122',
    },
    success: {
      50: '#e6f6e8',
      100: '#c4e9c8',
      200: '#9ddba4',
      300: '#72ce7f',
      400: '#4fc362',
      500: '#23b845',
      600: '#17a83c',
      700: '#009630',
      800: '#008525',
      900: '#006511',
    },
    danger: {
      50: '#ffeaee',
      100: '#ffcbd2',
      200: '#f59599',
      300: '#ed6a71',
      400: '#f9424d',
      500: '#ff2731',
      600: '#f01831',
      700: '#de012b',
      800: '#d10023',
      900: '#c30015',
    },
    warning: {
      50: '#fffae6',
      100: '#fef2c0',
      200: '#fcec97',
      300: '#f9e46e',
      400: '#f5dd4d',
      500: '#f2d828',
      600: '#e2c624',
      700: '#ceb11e',
      800: '#ba9a19',
      900: '#99750f',
    },
    ALL: {
      50: '#e4f7ff',
      100: '#bae9ff',
      200: '#8edbff',
      300: '#66ccff',
      400: '#4fc0ff',
      500: '#44b5fe',
      600: '#40a6ee',
      700: '#3a93da',
      800: '#3681c6',
      900: '#2d61a3',
      solidBg: 'var(--joy-palette-ALL-400)',
      solidActiveBg: 'var(--joy-palette-ALL-500)',
      solidColor: '#ffffff',
      outlinedBorder: 'var(--joy-palette-ALL-500)',
      outlinedColor: 'var(--joy-palette-ALL-700)',
      outlinedActiveBg: 'var(--joy-palette-ALL-100)',
      softColor: 'var(--joy-palette-ALL-800)',
      softBg: 'var(--joy-palette-ALL-200)',
      softActiveBg: 'var(--joy-palette-ALL-300)',
      plainColor: 'var(--joy-palette-ALL-700)',
      plainActiveBg: 'var(--joy-palette-ALL-100)',
    },
  },
};

export default settings;
