import { Box, Button, Typography } from '@mui/joy';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useCallback } from 'react';
import { DisplayVendorSimple } from './DisplayVendor';
import { VendorNode } from '../../__generated__/graphql';

interface Props {
  vendor: Maybe<VendorNode>;
  onSubmit?: any;
  saving?: boolean;
  onCancel?: () => void;
}

function DeselectVendorInner({ vendor, onSubmit, saving, onCancel }: Props) {
  const handleSubmit: any = useCallback(async () => {
    await onSubmit({
      vendorId: null,
    });
  }, [onSubmit]);
  return (
    <Box>
      <Typography level="h3" sx={{ mb: 2, textAlign: 'center' }}>
        Deselect Vendor
      </Typography>
      <Typography level="body-md" sx={{ mb: 2, textAlign: 'center' }}>
        Are you sure you'd like to deselect this vendor?
      </Typography>
      {vendor && (
        <Typography level="body-lg" sx={{ mb: 3, textAlign: 'center' }}>
          {vendor.name}
        </Typography>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="outlined"
          color="neutral"
          size="lg"
          onClick={onCancel}
          sx={{ mr: 4, width: 100 }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          loading={saving}
          variant="solid"
          size="lg"
          sx={{ width: 100 }}
        >
          Deselect
        </Button>
      </Box>
    </Box>
  );
}

export default DeselectVendorInner;
