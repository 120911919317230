import { Box, Button, Card, Typography } from '@mui/joy';
import { Link, useParams } from 'react-router-dom';
import logo from '../../assets/long-logo.svg';
import PublicPageTemplate from '../../components/PublicPageTemplate';
import useUserInviteDetails from './use-user-invite-details';

function VendorRequestAccepted() {
  const { token } = useParams();
  const { userInvite, loading } = useUserInviteDetails(token);

  return (
    <PublicPageTemplate title="Success">
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          minHeight: '100%',
          flexDirection: 'column',
        }}
      >
        <img
          src={logo}
          alt="Mahathey Contracting"
          style={{
            marginTop: 80,
            width: 400,
            maxWidth: '90vw',
            marginBottom: 40,
          }}
        />
        <Card sx={{ width: 600, maxWidth: '90vw', p: 4, mb: 8 }}>
          <Typography
            level="h1"
            sx={{ textAlign: 'center', mb: 2 }}
            color="success"
          >
            Congratulations
          </Typography>
          <Typography level="h3" sx={{ textAlign: 'center' }}>
            {userInvite?.name}
          </Typography>
          <Typography sx={{ textAlign: 'center', mb: 3 }}>
            Your have successfully created an account. Click below to sign in.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button component={Link} to="/sign-in" size="lg">
              Sign In
            </Button>
          </Box>
        </Card>
      </Box>
    </PublicPageTemplate>
  );
}

export default VendorRequestAccepted;
