import CssBaseline from '@mui/joy/CssBaseline';
import { CssVarsProvider, extendTheme, PaletteRange } from '@mui/joy/styles';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import RootRouter from './routers/RootRouter';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import settings from './settings';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from './store/root';

const materialTheme = materialExtendTheme();

const router = createBrowserRouter([{ path: '*', Component: RootRouter }]);

interface Color {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
  solidBg: string;
  solidActiveBg: string;
  outlinedBorder: string;
  outlinedColor: string;
  outlinedActiveBg: string;
  softColor: string;
  softBg: string;
  softActiveBg: string;
  plainColor: string;
  plainActiveBg: string;
}

declare module '@mui/joy/styles' {
  interface ColorPalettePropOverrides {
    // apply to all Joy UI components that support `color` prop
    new: true;
    progress: true;
    updateRequired: true;
    lucy: true;
    createInvoice: true;
    awaitingApproval: true;
    invoiceSubmitted: true;
    invoicePaid: true;
    ALL: true;
  }

  interface Palette {
    // this will make the node `secondary` configurable in `extendTheme`
    // and add `secondary` to the theme's palette.
    new: PaletteRange;
    progress: PaletteRange;
    updateRequired: PaletteRange;
    lucy: PaletteRange;
    createInvoice: PaletteRange;
    awaitingApproval: PaletteRange;
    invoiceSubmitted: PaletteRange;
    invoicePaid: PaletteRange;
    ALL: PaletteRange;
  }
}

function App() {
  const woStatusPalette = useSelector(
    (store: RootState) => store.auth.woStatusPalette,
    shallowEqual,
  );
  const woVendorStatusPalette = useSelector(
    (store: RootState) => store.auth.woVendorStatusPalette,
    shallowEqual,
  );

  const theme = extendTheme({
    // fontFamily: {
    //   // display: 'Mohave Variable', // applies to `h1`–`h4`
    //   // body: 'Noto Sans', // applies to `title-*` and `body-*`
    // },
    colorSchemes: {
      light: {
        palette: {
          ...settings.themeColors,
          ...woStatusPalette,
          ...woVendorStatusPalette,
        },
      },
    },
  });
  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <CssVarsProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <RouterProvider router={router} />
        </LocalizationProvider>
      </CssVarsProvider>
    </MaterialCssVarsProvider>
  );
}

export default App;
