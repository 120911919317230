import { gql, useQuery } from '@apollo/client';
import { Typography } from '@mui/joy';

const versionQuery = gql`
  query Version {
    version
  }
`;

function Version() {
  const result = useQuery(versionQuery, {
    fetchPolicy: 'no-cache',
  });
  const version = result?.data?.version;
  if (version) {
    return <Typography>{`Version: ${version}`}</Typography>;
  }
  return null;
}

export default Version;
