import { Typography } from '@mui/joy';
import TextFieldElement from '../../components/TextFieldElement';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

function JumpToWorkOrderForm() {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      no: '',
    },
  });

  const navigate = useNavigate();
  const onSubmit = useCallback(
    (values: any) => {
      navigate(`/work-orders/view/${values.no}/discussion`);
    },
    [navigate],
  );
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography level="body-sm">Jump to Service Request</Typography>
      <TextFieldElement
        control={control}
        name="no"
        // label="Jump to Service Request"
        placeholder="Request #"
        sx={{ mb: 0 }}
      />
    </form>
  );
}

export default JumpToWorkOrderForm;
