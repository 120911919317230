import { gql } from '@apollo/client';

export const workOrderStatusesQuery = gql`
  query workOrderStatuses {
    workOrderStatuses {
      _id
      label
      desc
      color
      textColor
      colorPalette
      colorNames
      builtIn
      sort
    }
  }
`;
