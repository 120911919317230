import { gql } from '@apollo/client';
import { AllUserInvitesQueryQuery } from '../../__generated__/graphql';

export const allUserInvitesQuery = gql`
  query AllUserInvitesQuery(
    $first: Int
    $after: String
    $filters: UserInviteFilters
    $sortBy: [SortBy]
  ) {
    allUserInvites(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      edges {
        cursor
        node {
          _id
          name
          email
          roleIds
          invitedById
          invitedBy {
            _id
            name
            username
          }
          createdBy
          updatedBy
          createdAt
          updatedAt
          deletedAt
          deletedBy
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const extractAllUserInvites = (data: AllUserInvitesQueryQuery) =>
  data.allUserInvites;
