import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { VendorFilterOptionsQuery } from '../../__generated__/graphql';

export const vendorFilterOptionsQuery = gql`
  query VendorFilterOptions {
    vendorFilterOptions {
      locations
      cities
      states
      trades
    }
  }
`;

function useOptions(vendorFilterOptions: any, name: string) {
  const options = useMemo(() => {
    if (vendorFilterOptions?.[name]) {
      return [
        { key: '__undefined__', label: 'Any' },
        ...vendorFilterOptions[name].map((item: any) => ({
          key: item,
          label: item,
        })),
      ];
    }
    return [];
  }, [vendorFilterOptions, name]);
  return options;
}

function useVendorFilterOptions() {
  const result = useQuery<VendorFilterOptionsQuery>(vendorFilterOptionsQuery, {
    fetchPolicy: 'cache-first',
  });

  const vendorFilterOptions = result?.data?.vendorFilterOptions;
  const locationOptions = useOptions(vendorFilterOptions, 'locations');
  const tradeOptions = useOptions(vendorFilterOptions, 'trades');

  return {
    locationOptions,
    tradeOptions,
  };
}

export default useVendorFilterOptions;
