import { useMutation } from '@apollo/client';
import { Box, Button } from '@mui/joy';
import { useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import TextAreaFieldElement from '../../components/TextAreaFieldElement';
import { generateUuid } from '../../shared/utils';
import { showMessage } from '../../store/snackbar-slice';
import UploadDiscussionAttachmentButton from './UploadDiscussionAttachmentButton';
import { createDiscussionMutation } from './constants';

interface Props {
  filters: any;
  refetch: any;
}

function CreateDiscussionForm({ filters, refetch }: Props) {
  const [saving, setSaving] = useState(false);
  const [createDiscussion] = useMutation(createDiscussionMutation);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    reset,
  } = useForm<any>({
    defaultValues: { text: '' },
  });

  const dispatch = useDispatch();
  const onSubmit: SubmitHandler<any> = useCallback(
    async (values) => {
      setSaving(true);
      try {
        await createDiscussion({
          variables: {
            discussion: {
              _id: generateUuid(),
              ...filters,
              ...values,
            },
          },
        });
        reset();
        dispatch(
          showMessage({
            _id: 'create-discuss',
            severity: 'success',
            message: 'Success',
          }),
        );
      } catch (err: any) {
        console.error(err);
        dispatch(
          showMessage({
            _id: 'create-discuss',
            severity: 'danger',
            message: 'There was an error saving your service request',
          }),
        );
      }
      setSaving(false);
    },
    [createDiscussion, dispatch, filters, reset],
  );

  // return (
  //   <EditTextAreaInner
  //     defaultValues={}
  //     title="Discussion"
  //     name="text"
  //     label="Discussion"
  //     placeholder="Add a comment, record activity, add an attachment ..."
  //     rules={{ required: 'This field is required' }}
  //     onSubmit={onSubmit}
  //   />
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextAreaFieldElement
        control={control}
        label="Add Comment"
        name="text"
        rules={{ required: 'This field is required' }}
        placeholder="Add a comment, record activity, add an attachment ..."
        minRows={3}
        sx={{ mb: 2, backgroundColor: 'var(--joy-palette-background-body)' }}
      />
      <Box sx={{ display: 'flex' }}>
        <UploadDiscussionAttachmentButton
          filters={filters}
          refetch={refetch}
          myId="create"
        />
        <Box sx={{ flex: 1 }} />
        <Button
          type="submit"
          loading={saving}
          variant="solid"
          size="lg"
          sx={{ width: 100 }}
        >
          Save
        </Button>
      </Box>
    </form>
  );
}

export default CreateDiscussionForm;
