// @ts-nocheck
import { FormControl, FormHelperText, FormLabel, Input } from '@mui/joy';
import { useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { useIMask } from 'react-imask';
import {
  formatPhoneNumberForStorage,
  isPhoneNumberValid,
} from '../shared/utils';

function TextMaskCustom({ name, value: initialValue, onChange, ...other }) {
  const { ref, setValue, unmaskedValue } = useIMask({
    unmask: true,
    mask: [
      {
        mask: '(000) 000-0000',
        lazy: false,
      },
      {
        mask: '+0 (000) 000-0000',
        lazy: false,
      },
      {
        mask: '+00 (000) 000-0000',
        lazy: false,
      },
    ],
    overwrite: true,
  });

  const once = useRef(false);
  useEffect(() => {
    if (!once.current && initialValue && initialValue !== unmaskedValue) {
      once.current = true;
      setValue(initialValue);
    }
  }, [initialValue, setValue, unmaskedValue]);

  useEffect(() => {
    once.current = true;
    onChange({ target: { name, value: unmaskedValue } });
  }, [onChange, name, unmaskedValue]);

  return <Input slotProps={{ input: { ref } }} />;
}

interface Props {
  label: string;
  control: any;
  rules?: any;
  name: string;
  required?: boolean;
  errors?: any;
  caption?: any;
  inputProps?: any;
  sx?: any;
}

function PhoneNumberFieldElement({
  label,
  control,
  rules = {},
  name,
  required,
  errors = {},
  style,
  caption,
  sx,
  ...inputProps
}: Props) {
  return (
    <Controller
      control={control}
      rules={{
        ...rules,
        validate: (value) => {
          if (formatPhoneNumberForStorage(value)) {
            return true;
          }
          return 'Please enter a valid phone number';
        },
      }}
      name={name}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <FormControl
          variant="standard"
          error={!!error}
          required={required}
          sx={sx}
        >
          <FormLabel>{label}</FormLabel>
          <TextMaskCustom
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
          {(error || caption) && (
            <FormHelperText>{error.message || caption}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}

export default PhoneNumberFieldElement;
