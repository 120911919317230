import { useMemo, useRef } from 'react';
import {
  AllRolesQueryQuery,
  RoleNode,
  SortBy,
  SortByDirection,
} from '../../__generated__/graphql';
import { useManyRemote } from '../../shared/use-many-remote';
import { allRolesQuery, extractAllRoles } from './constants';
import settings from '../../settings';

function useAllRoles(): { [_id: string]: RoleNode } | undefined {
  const filters = useRef({});
  const sortBy = useRef<SortBy[]>([
    { key: 'updatedAt', order: SortByDirection.Desc },
  ]);
  const { loading, data, search, hasNextPage, next, refetch, reset } =
    useManyRemote<AllRolesQueryQuery, RoleNode>({
      query: allRolesQuery,
      extract: extractAllRoles,
      first: settings.querySize,
      filters: filters.current,
      sortBy: sortBy.current,
      fetchPolicy: 'network-only',
      queryId: 'use-all-roles',
    });

  const roles = useMemo(() => {
    if (data) {
      return data.reduce((prev: any, curr: RoleNode) => {
        prev[curr._id] = curr;
        return prev;
      }, {});
    }
    return undefined;
  }, [data]);
  return roles;
}

export default useAllRoles;
