import { useQuery } from '@apollo/client';
import { Delete, Edit } from '@mui/icons-material';
import { Box, Divider, IconButton, Typography } from '@mui/joy';
import { useCallback, useMemo, useRef, useState } from 'react';
import {
  PaymentMethodNode,
  SortBy,
  SortByDirection,
} from '../../__generated__/graphql';
import MainPageTemplate from '../../components/MainPageTemplate';
import MyTable from '../../components/my-table/MyTable';
import { AllFilter } from '../../components/my-table/TableFilters';
import AddPaymentMethodModal from './AddPaymentMethodModal';
import { paymentMethodsQuery } from './constants';
import EditPaymentMethodModal from './EditPaymentMethodModal';
import DeletePaymentMethodModal from './DeletePaymentMethodModal';

const initialOrder: SortBy = {
  key: 'label',
  order: SortByDirection.Desc,
};

function PaymentMethodsPage() {
  const result = useQuery(paymentMethodsQuery, {
    fetchPolicy: 'network-only',
  });

  const filters = useRef({});
  const sortBy = useRef<SortBy[]>([initialOrder]);
  const allFilters: AllFilter[] = useMemo(() => [], []);

  const [editing, setEditing] = useState<any>();
  const handleEdit = (paymentMethod: PaymentMethodNode) =>
    setEditing({ paymentMethod });
  const cancelEdit = () => setEditing(undefined);
  const finishEdit = () => {
    setEditing(undefined);
    result.refetch();
  };

  const [deleting, setDeleting] = useState<any>();
  const handleDelete = (paymentMethod: PaymentMethodNode) =>
    setDeleting({ paymentMethod });
  const cancelDelete = () => setDeleting(undefined);
  const finishDelete = () => {
    setDeleting(undefined);
    result.refetch();
  };

  const allColumns = useMemo(
    () => [
      {
        key: 'paymentMethod',
        title: 'Payment Method',
        component: (row: any) => <Typography>{row.label}</Typography>,
        sortable: true,
      },
      {
        key: 'actions',
        title: '',
        component: (row: any) => (
          <>
            <IconButton sx={{ mr: 1 }} onClick={() => handleEdit(row)}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => handleDelete(row)}>
              <Delete />
            </IconButton>
          </>
        ),
        sortable: false,
        width: 100,
      },
    ],
    [],
  );

  const [term, setTerm] = useState<string | undefined>();
  const search = (_term: string) => setTerm(_term.toLowerCase());

  const rows = useMemo(() => {
    let initial = result.data?.paymentMethods || [];
    if (term) {
      initial = initial.filter((pm: PaymentMethodNode) =>
        pm.label.toLowerCase().includes(term),
      );
    }
    return initial;
  }, [result, term]);

  return (
    <MainPageTemplate title="Users">
      <Box sx={{ pt: 2, pb: 2, pl: 2, pr: 2 }}>
        <Box sx={{ pl: 2, pr: 2 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              pt: 2,
              pb: 2,
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography level="h1">Payment Methods</Typography>
            </Box>
            <AddPaymentMethodModal refetch={result.refetch} />
          </Box>
        </Box>
        <Divider sx={{ mb: 2 }} />
        <Box sx={{ pl: 2, pr: 2 }}>
          <MyTable
            initialOrder={initialOrder}
            rows={rows}
            sortBy={sortBy}
            refetch={result.refetch}
            filters={filters}
            allFilters={allFilters}
            search={search}
            allColumns={allColumns}
            hasNextPage={false}
            next={() => {}}
          />
        </Box>
      </Box>
      <EditPaymentMethodModal
        visible={editing}
        onCancel={cancelEdit}
        onFinish={finishEdit}
      />
      <DeletePaymentMethodModal
        visible={deleting}
        onCancel={cancelDelete}
        onFinish={finishDelete}
      />
    </MainPageTemplate>
  );
}

export default PaymentMethodsPage;
