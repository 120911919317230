// @ts-nocheck
import { ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { createClient } from 'graphql-ws';
import queryString from 'query-string';
import { ajax } from 'rxjs/ajax';
import { catchError, tap } from 'rxjs/operators';
import settings from '../settings';
import { deleteProfile, deleteToken } from '../store/auth-slice';
import { store } from '../store/root';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (
        message.includes('Missing authorization header') ||
        message.includes('Unauthorized')
      ) {
        store.dispatch(deleteToken());
        store.dispatch(deleteProfile());
        const params = queryString.parse(window.location.search);
        const search = `?${queryString.stringify({
          p: window.location.pathname,
          s: JSON.stringify(params),
        })}`;
        window.location.href = `/sign-in${search}`;
      } else {
        console.error(`GraphQL error ... ${message}`);
      }
    });
  }
  if (networkError) {
    if (networkError.name === 'AbortError') {
      return false;
    }
    console.log(`Network error ... ${networkError}`);
  }
});

const httpLink = createUploadLink({
  uri: settings.apiUrl,
  fetch: async (url, options) => {
    if (options?.onProgress) {
      options.headers = { ...options.headers };
      const $result = ajax({
        url,
        ...options,
        includeUploadProgress: true,
      });
      return {
        text: () =>
          new Promise((resolve, reject) =>
            $result
              .pipe(
                tap((e) => {
                  if (e.type === 'upload_progress') {
                    options.onProgress((100 * e.loaded) / e.total);
                  } else if (e.type === 'download_load') {
                    resolve(JSON.stringify(e.response));
                  }
                }),
                catchError((err) => reject(err)),
              )
              .subscribe(),
          ),
      };
    }
    return fetch(url, options);
  },
});

const authHttpLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('authToken');
  // return the headers to the context so httpLink can read them
  if (token) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  }
  return {
    headers: {
      ...headers,
    },
  };
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: settings.wsUrl,
    connectionParams() {
      const token = localStorage.getItem('authToken');
      return {
        authorization: token ? `Bearer ${token}` : undefined,
      };
    },
  }),
);

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authHttpLink.concat(httpLink),
);

class ApolloClientWS extends ApolloClient<InMemoryCache> {
  constructor(props: any) {
    super(props);

    this.close = this.close.bind(this);
  }

  close() {
    return wsLink.client.terminate();
  }
}

export const client = new ApolloClientWS({
  link: ApolloLink.from([errorLink, splitLink]),
  cache: new InMemoryCache(),
});
