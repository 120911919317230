import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Textarea,
} from '@mui/joy';
import { Control, Controller } from 'react-hook-form';

interface Props {
  control: Control<any>;
  name: string;
  label?: string;
  caption?: string;
  placeholder?: string;
  rules?: any;
  sx?: any;
  minRows?: number;
  disabled?: boolean;
}

function TextAreaFieldElement({
  control,
  name,
  label,
  caption,
  rules,
  placeholder,
  sx,
  minRows = 3,
  disabled,
}: Props) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <FormControl error={!!error} sx={{ mb: 4, ...sx }}>
          {label && <FormLabel>{label}</FormLabel>}
          <Textarea
            minRows={minRows}
            placeholder={placeholder}
            {...field}
            variant="outlined"
            disabled={disabled}
          />
          {(error || caption) && (
            <FormHelperText>{error?.message || caption}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}

export default TextAreaFieldElement;
