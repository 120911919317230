import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface SnackbarMessage {
  _id: string;
  message: string;
  severity: string;
}

interface MessagePayload {
  queryId: string;
  message: SnackbarMessage;
}

interface HideMessagePayload {
  queryId: string;
  _id: string;
}

interface SnackbarState {
  [key: string]: {
    [key: string]: SnackbarMessage | undefined;
  };
}

const initialState: SnackbarState = {
  default: {},
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showMessage: {
      reducer(state, action: PayloadAction<MessagePayload>) {
        state[action.payload.queryId] = {
          ...state[action.payload.queryId],
          [action.payload.message._id]: action.payload.message,
        };
      },
      prepare(message: SnackbarMessage, queryId = 'default') {
        return { payload: { message, queryId } };
      },
    },
    hideMessage: {
      reducer(state, action: PayloadAction<HideMessagePayload>) {
        state[action.payload.queryId] = {
          ...state[action.payload.queryId],
          [action.payload._id]: undefined,
        };
      },
      prepare(_id: string, queryId = 'default') {
        return { payload: { _id, queryId } };
      },
    },
  },
});

export const { showMessage, hideMessage } = snackbarSlice.actions;
export default snackbarSlice.reducer;
