import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showMessage } from '../../store/snackbar-slice';
import { Maybe } from 'graphql/jsutils/Maybe';

const passwordResetDetailsQuery = gql`
  query PasswordResetDetails($token: String!) {
    passwordResetDetails(token: $token) {
      _id
      name
      username
    }
  }
`;

function usePasswordResetDetails(token: Maybe<string>) {
  const { data, loading, error } = useQuery(passwordResetDetailsQuery, {
    fetchPolicy: 'cache-first',
    skip: !token,
    variables: {
      token,
    },
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (error) {
      console.error(error.message);
      dispatch(
        showMessage({
          _id: 'password-reset-details',
          severity: 'danger',
          message: 'There was an fetching your user details.',
        }),
      );
    }
  }, [error, dispatch]);
  return {
    user: data?.passwordResetDetails,
    loading,
  };
}

export default usePasswordResetDetails;
