import { Alert, ColorPaletteProp, IconButton, Snackbar } from '@mui/joy';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/root';
import { SnackbarMessage, hideMessage } from '../store/snackbar-slice';
import CloseIcon from '@mui/icons-material/Close';

function MySnackbar() {
  const messages = useSelector(
    (store: RootState) =>
      Object.values(store.snackbar.default).filter((v) => v),
    shallowEqual,
  );
  const dispatch = useDispatch();
  const [msg, setMsg] = useState<SnackbarMessage | undefined>();
  useEffect(() => {
    if (!msg && messages.length > 0) {
      setMsg(messages[0]);
    }
  }, [msg, messages]);
  const onClose = () => {
    if (msg) {
      dispatch(hideMessage(msg._id));
      setMsg(undefined);
    }
  };
  return (
    <Snackbar
      open={!!msg}
      autoHideDuration={6000}
      onClose={onClose}
      color={msg?.severity as ColorPaletteProp}
      variant="soft"
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{ zIndex: 10003 }}
      endDecorator={
        <IconButton
          variant="soft"
          color={msg?.severity as ColorPaletteProp}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      }
    >
      {msg?.message}
    </Snackbar>
  );
}

export default MySnackbar;
