import { gql } from '@apollo/client';
import {
  AllLocationsQueryQuery,
  LocationQueryQuery,
} from '../../__generated__/graphql';

export const allLocationsQuery = gql`
  query AllLocationsQuery(
    $first: Int
    $after: String
    $filters: LocationFilters
    $sortBy: [SortBy]
  ) {
    allLocations(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      edges {
        cursor
        node {
          _id
          scId
          scName
          customerId
          customer {
            _id
            scName
          }
          storeId
          scLat
          scLon
          scEmail
          scPhone
          scFax
          scAddress {
            address1
            address2
            city
            state
            county
            country
            zipCode
          }
          scContact
          notes
          createdAt
          updatedAt
          deletedAt
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const extractAllLocations = (data: AllLocationsQueryQuery) =>
  data.allLocations;

export const locationQuery = gql`
  query LocationQuery($_id: ID!) {
    location(_id: $_id) {
      _id
      scId
      scName
      customerId
      customer {
        _id
        scName
      }
      storeId
      scLat
      scLon
      scEmail
      scPhone
      scFax
      scAddress {
        address1
        address2
        city
        state
        county
        country
        zipCode
      }
      scContact
      notes
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const extractLocation = (data: LocationQueryQuery) => data.location;
