import {
  Avatar,
  Box,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
  tabClasses,
} from '@mui/joy';
import { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { UserQueryQuery } from '../../__generated__/graphql';
import MainPageTemplate from '../../components/MainPageTemplate';
import useOne from '../../shared/use-one';
import { updateUser } from '../../store/user-slice';
import ViewHistory from '../history/ViewHistory';
import UserDetailsCard from './UserDetailsCard';
import { extractUser, userQuery } from './constants';
import useUser from './use-user';
import useUserUpdates from './use-user-updates';

const queryId = 'default';

function ViewUser() {
  const { _id, tab } = useParams();
  const { loading } = useOne<UserQueryQuery>({
    extract: extractUser,
    fetchPolicy: 'network-only',
    variables: {
      _id,
    },
    query: userQuery,
    queryId,
    updateAction: updateUser,
  });
  useUserUpdates(_id, queryId);
  const user = useUser(_id, queryId);
  const title = useMemo(() => {
    if (user) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flex: 1,
          }}
        >
          <Avatar
            src={user.profilePhotoThumbUrl || undefined}
            sx={{ mr: 2, height: 80, width: 80 }}
          >
            {user.name[0]}
          </Avatar>
          <Box sx={{ flex: 1 }}>
            <Box sx={{ display: 'flex' }}>
              <Typography level="h1" sx={{ flex: 1 }}>
                {user.name}
              </Typography>
            </Box>
            <Typography level="body-lg" sx={{ flex: 1 }} color="primary">
              {user.username}
            </Typography>
          </Box>
        </Box>
      );
    }
    return <Typography level="h1">User</Typography>;
  }, [user]);

  return (
    <MainPageTemplate title="User">
      <Box sx={{ pt: 2, pb: 2, pl: 2, pr: 2 }}>
        <Box sx={{ pl: 2, pr: 2 }}>
          <Box sx={{ display: 'flex' }}>
            <Typography
              color="primary"
              fontWeight={500}
              level="body-md"
              component={Link}
              to="/users/users"
              sx={{ mr: 1, textDecoration: 'none' }}
            >
              Users
            </Typography>
            <Typography
              color="primary"
              fontWeight={500}
              level="body-md"
              sx={{ mr: 1 }}
            >
              /
            </Typography>
            <Typography color="primary" fontWeight={500} level="body-md">
              View User
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              pt: 2,
              justifyContent: 'space-between',
              mb: 4,
            }}
          >
            {title}
          </Box>
          <UserDetailsCard user={user} loading={loading} />
        </Box>
        <Tabs
          value={tab}
          sx={{
            bgcolor: 'transparent',
          }}
        >
          <TabList
            tabFlex={1}
            size="sm"
            sx={{
              pl: 0,
              justifyContent: 'left',
              [`&& .${tabClasses.root}`]: {
                fontWeight: '600',
                flex: 'initial',
                color: 'text.tertiary',
                [`&.${tabClasses.selected}`]: {
                  bgcolor: 'transparent',
                  color: 'text.primary',
                  '&::after': {
                    height: '2px',
                    bgcolor: 'primary.500',
                  },
                },
              },
            }}
          >
            <Tab
              sx={{ borderRadius: '6px 6px 0 0' }}
              indicatorInset
              value="history"
              component={Link}
              to={`/users/view/${_id}/history`}
            >
              History
            </Tab>
          </TabList>
          <TabPanel value="history">
            <ViewHistory filters={{ userId: _id }} />
          </TabPanel>
        </Tabs>
      </Box>
    </MainPageTemplate>
  );
}

export default ViewUser;
