import { Box, Card, Chip, Grid, Link, Skeleton, Typography } from '@mui/joy';
import { useMemo } from 'react';
import { UserNode } from '../../__generated__/graphql';
import MyGridItem, {
  DisplayAddress,
  MyDetailItem,
} from '../../components/MyGridItem';
import OpenButton, { OpenButtonProps } from '../../components/OpenButton';
import EditTextAreaInner from '../../components/edit-inner/EditTextAreaInner';
import { formatPhoneNumberForDisplay } from '../../shared/utils';
import EditSomeUserLine from './EditSomeUserLine';
import useAllRoles from './use-all-roles';
import EditTextInner from '../../components/edit-inner/EditTextInner';
import EditPhoneInner from '../../components/edit-inner/EditPhoneInner';
import EditAddressInner from '../../components/edit-inner/EditAddressInner';

interface Props {
  user: UserNode | undefined;
  loading: boolean;
}

function MyOpenButton({ onOpen }: OpenButtonProps) {
  return (
    <OpenButton
      onOpen={onOpen}
      size="sm"
      sx={{
        '--IconButton-size': '1rem',
        '& .MuiSvgIcon-root': { height: 20, width: 20 },
        padding: 0.5,
        mr: 1,
      }}
    />
  );
}

function UserDetailsCard({ loading, user }: Props) {
  const roles = useAllRoles();
  const roleNames = useMemo(() => {
    if (roles && user?.roleIds) {
      return user.roleIds.map((roleId) => {
        return roleId ? roles[roleId]?.name : 'Unknown';
      });
    }
    return [];
  }, [user, roles]);

  const details = useMemo<MyDetailItem[] | undefined>(() => {
    if (user) {
      return [
        {
          key: 'username',
          label: 'Username',
          width: 4,
          lgWidth: 3,
          value: user.username,
          component: (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <Box sx={{ flex: 1 }}>
                {user.username ? (
                  <Typography level="body-md" sx={{ mr: 2, pt: 0.5 }}>
                    {user.username}
                  </Typography>
                ) : (
                  <Typography
                    color="neutral"
                    level="body-sm"
                    sx={{ mr: 2, pt: 0.5 }}
                  >
                    Not Specified
                  </Typography>
                )}
              </Box>
              {user._id && (
                <EditSomeUserLine
                  _id={user._id}
                  EditForm={
                    <EditTextInner
                      defaultValues={{
                        username: user.username || '',
                      }}
                      title="Change Username"
                      name="username"
                      label="Username"
                      rules={{
                        required: 'This field is required',
                      }}
                    />
                  }
                  OpenButton={<MyOpenButton />}
                />
              )}
            </Box>
          ),
        },
        {
          key: 'roles',
          label: 'Roles',
          width: 4,
          lgWidth: 3,
          value: user.roleIds?.join(', '),
          component: (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <Box sx={{ flex: 1, pt: 0.5 }}>
                {roleNames.map((userRole: any, index: number) => (
                  <Chip
                    key={index}
                    color="primary"
                    sx={{ mr: 1, mb: 1 }}
                    variant="soft"
                  >
                    {userRole}
                  </Chip>
                ))}
              </Box>
            </Box>
          ),
        },
        {
          key: 'email',
          label: 'Email',
          width: 4,
          lgWidth: 3,
          value: user.email,
          component: (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <Box sx={{ flex: 1 }}>
                {user.email ? (
                  <Typography level="body-md" sx={{ mr: 2, pt: 0.5 }}>
                    {user.email}
                  </Typography>
                ) : (
                  <Typography
                    color="neutral"
                    level="body-sm"
                    sx={{ mr: 2, pt: 0.5 }}
                  >
                    Not Specified
                  </Typography>
                )}
              </Box>
              {user._id && (
                <EditSomeUserLine
                  _id={user._id}
                  EditForm={
                    <EditTextInner
                      defaultValues={{
                        email: user.email || '',
                      }}
                      title="Update User Email"
                      name="email"
                      label="Email"
                      rules={{
                        required: 'This field is required',
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: 'Entered value does not match email format',
                        },
                      }}
                    />
                  }
                  OpenButton={<MyOpenButton />}
                />
              )}
            </Box>
          ),
        },
        {
          key: 'phone',
          label: 'Phone',
          value: user.phone && formatPhoneNumberForDisplay(user.phone),
          width: 4,
          lgWidth: 3,
          component: (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <Box sx={{ flex: 1 }}>
                {user.phone ? (
                  <Typography level="body-md" sx={{ mr: 2, pt: 0.5 }}>
                    {formatPhoneNumberForDisplay(user.phone)}
                  </Typography>
                ) : (
                  <Typography
                    color="neutral"
                    level="body-sm"
                    sx={{ mr: 2, pt: 0.5 }}
                  >
                    Not Specified
                  </Typography>
                )}
              </Box>
              {user._id && (
                <EditSomeUserLine
                  _id={user._id}
                  EditForm={
                    <EditPhoneInner
                      defaultValues={{ phone: user.phone || '' }}
                      title="Update Phone Number"
                      name="phone"
                      label="Phone Number"
                      rules={{ required: 'This field is required' }}
                    />
                  }
                  OpenButton={<MyOpenButton size="sm" />}
                />
              )}
            </Box>
          ),
        },
        {
          key: 'address',
          label: 'Address',
          width: 4,
          lgWidth: 3,
          component: (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <Box sx={{ flex: 1 }}>
                {user.address ? (
                  <DisplayAddress address={user.address} sx={{ pt: 0.5 }} />
                ) : (
                  <Typography
                    color="neutral"
                    level="body-sm"
                    sx={{ mr: 2, pt: 0.5 }}
                  >
                    Not Specified
                  </Typography>
                )}
              </Box>
              {user._id && (
                <EditSomeUserLine
                  _id={user._id}
                  EditForm={
                    <EditAddressInner
                      defaultValues={{ ...(user.address || undefined) }}
                      title="Update Address"
                      name="address"
                    />
                  }
                  OpenButton={<OpenButton size="sm" />}
                />
              )}
            </Box>
          ),
        },
      ];
    }
    return undefined;
  }, [user, roleNames]);
  return (
    <Card
      sx={{
        p: 2,
        pt: 4,
        pb: 4,
        mr: 'auto',
        ml: 'auto',
        mb: 4,
      }}
    >
      {details ? (
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          {details.map((item) => (
            <Grid key={item.key} xs={item.width} lg={item.lgWidth}>
              <MyGridItem item={item} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <>
          {loading ? (
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
              {Array(18)
                .fill(0)
                .map((_, index) => (
                  <Grid key={index} xs={4} lg={3}>
                    <Skeleton
                      variant="rectangular"
                      height={24}
                      sx={{ mb: 1, mt: 1 }}
                    />
                  </Grid>
                ))}
            </Grid>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                p: 6,
              }}
            >
              <Typography sx={{ textAlign: 'center' }}>
                Nothing to see here.
              </Typography>
            </Box>
          )}
        </>
      )}
    </Card>
  );
}

export default UserDetailsCard;
