import { gql, useMutation } from '@apollo/client';
import { Modal, ModalClose, ModalDialog } from '@mui/joy';
import React, { ReactElement, useCallback, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showMessage } from '../../store/snackbar-slice';
import { sendVendorRequestEmailMutation } from './constants';
import { Maybe } from '../../__generated__/graphql';

const updateWorkOrderMutation = gql`
  mutation UpdateWorkOrder($workOrder: UpdateWorkOrderInput!) {
    updateWorkOrder(workOrder: $workOrder)
  }
`;

interface Props {
  _id: string | undefined;
  EditForm: ReactElement;
  OpenButton: Maybe<ReactElement>;
}

function EditSomeWorkOrderLine({ _id, EditForm, OpenButton }: Props) {
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [updateWorkOrder] = useMutation(updateWorkOrderMutation);
  const [sendVendorRequestEmail] = useMutation(sendVendorRequestEmailMutation);

  const dispatch = useDispatch();
  const onSubmit: SubmitHandler<any> = useCallback(
    async (_values) => {
      setSaving(true);
      try {
        const { sendVendorRequestEmail: sendEmail, ...values } = _values;
        await updateWorkOrder({
          variables: {
            workOrder: {
              _id,
              ...values,
            },
          },
        });
        dispatch(
          showMessage({
            _id: 'edit-work-order',
            severity: 'success',
            message: 'Success',
          }),
        );
        if (sendEmail) {
          await sendVendorRequestEmail({
            variables: {
              workOrderId: _id,
            },
          });
          dispatch(
            showMessage({
              _id: 'send-vendor-request',
              severity: 'success',
              message: 'Request sent',
            }),
          );
        }
        setOpen(false);
      } catch (err: any) {
        console.error(err);
        dispatch(
          showMessage({
            _id: 'edit-work-order',
            severity: 'danger',
            message: 'There was an error saving your service request',
          }),
        );
      }
      setSaving(false);
    },
    [updateWorkOrder, dispatch, _id, sendVendorRequestEmail],
  );

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog sx={{ p: 4, width: 600, maxWidth: '90uvw' }}>
          <ModalClose />
          {React.cloneElement(EditForm, {
            saving,
            onCancel: () => setOpen(false),
            onSubmit,
          })}
        </ModalDialog>
      </Modal>
      {OpenButton &&
        React.cloneElement(OpenButton, {
          onOpen: () => setOpen(true),
        })}
    </>
  );
}

export default EditSomeWorkOrderLine;
