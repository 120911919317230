import { gql, useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  VendorInvoiceNode,
  VendorInvoiceUpdatesSubscription,
} from '../../__generated__/graphql';
import {
  deleteVendorInvoice,
  updateVendorInvoice,
} from '../../store/vendor-invoice-slice';
import useVendorInvoiceObjectId from './use-vendor-invoice-obj-id';

const vendorInvoiceUpdatesSubscription = gql`
  subscription VendorInvoiceUpdates($filters: VendorInvoiceFilters!) {
    vendorInvoiceUpdates(filters: $filters) {
      _id
      crud
      new {
        _id
        vendorId
        workOrderId
        status
        paymentLink
        paymentMethod
        usdCents
        notes
        attachmentIds
        attachments {
          _id
          filename
          uploaded
          processed
          size
          contentType
          parentId
          thumbnailUrl
          downloadUrl
        }
        submittedAt
        dueAt
        paidAt
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

function useVendorInvoiceUpdates(
  filters: any | undefined,
  queryId = 'default',
) {
  const objectId = useVendorInvoiceObjectId(filters);
  const dispatch = useDispatch();
  useSubscription<VendorInvoiceUpdatesSubscription>(
    vendorInvoiceUpdatesSubscription,
    {
      skip: !filters,
      variables: {
        filters,
      },
      fetchPolicy: 'no-cache',
      onSubscriptionData: ({ client, subscriptionData: result }) => {
        try {
          const update = result?.data?.vendorInvoiceUpdates;
          if (update?.new) {
            switch (update.crud) {
              case 'insert':
                dispatch(
                  updateVendorInvoice(
                    update.new as VendorInvoiceNode,
                    objectId,
                    queryId,
                  ),
                );
                break;
              case 'update':
                dispatch(
                  updateVendorInvoice(
                    update.new as VendorInvoiceNode,
                    objectId,
                    queryId,
                  ),
                );
                break;
              case 'delete':
                dispatch(deleteVendorInvoice(update._id, objectId, queryId));
                break;
              default:
                break;
            }
          }
        } catch (err) {
          console.error(err);
        }
      },
    },
  );
}

export default useVendorInvoiceUpdates;
