import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../store/root';

function useVendor(_id: string | undefined, queryId: string) {
  const vendor = useSelector((store: RootState) => {
    if (_id) {
      return store.vendors[queryId]?.[_id];
    }
    return undefined;
  }, shallowEqual);
  return vendor;
}

export default useVendor;
